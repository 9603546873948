<template>
	<div class="collapse-button" :style="sstyle">
		<!-- <el-icon class="expend-icon" :style="estyle"><ArrowLeftBold /></el-icon> -->
	</div>
</template>

<script setup>
	import {ref,reactive} from 'vue'
	//传入该控件的高度，宽高比为1：2
	const props=defineProps(["color","size"])
	var sstyle=reactive({
		height:props.size/100+'rem',
		width:props.size/100+'rem',
		borderWidth:props.size/600+'rem',
		borderRightWidth:props.size/300+'rem',
		borderRightColor:props.color,
		top:`calc(50% - ${props.size/100+'rem'})`,
		// left:-props.size/100+'rem',
		translate:-props.size/104+'rem'
	})
	
	var estyle=reactive({
		top:'50%',
	})
</script>

<style scoped>
	.collapse-button{
		background-color: transparent;
		border-top-color: transparent;
		border-left-color: transparent;
		border-bottom-color: transparent;
		position: absolute;
		border-right-color:black;
		z-index: 1000;
		border-style: solid;
		cursor: pointer;
	}
	
	.collapse-button:hover{
		
	}
	
	.expend-icon{
		color: white;
		position: absolute;
	}
</style>