<template>
    <div class="info-bar">
        <li @click="emitter.emit('showDataset','showDataset')" :class="{ 'active-li': menuChoiced == 'showDataset' }">数据集</li>
        <li @click="emitter.emit('showGenImgs','showGenImgs')" :class="{ 'active-li': menuChoiced == 'showGenImgs' }">生成图</li>
    </div>
</template>

<script setup>
import {
    emitter
} from '~bus/emitter.js'
import { ref, onUnmounted } from 'vue'
var menuChoiced = ref('showDataset')

function changeMenu(menu) {
    console.log(menu)
    if (menu == 'showDataset')
        menuChoiced.value = 'showDataset'
    else if (menu == 'showGenImgs')
        menuChoiced.value = 'showGenImgs'
}

emitter.on('showDataset', changeMenu)
emitter.on('showGenImgs',changeMenu)

onUnmounted(() => {
    emitter.off('showDataset')
})

</script>

<style scoped src='~assets/css/drawingBoard.css'></style>
<style scoped>
li {
    cursor: pointer
}

li:hover {
    color: #409EFF;
}

.active-li {
    border-bottom: 1px solid #409EFF;
}
</style>