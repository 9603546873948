<template>
	<div :class="{'canvas-warpper':GeneCaseJsonObj.length>=3,'canvas-warpper-center':GeneCaseJsonObj.length<3}">
		<canvas :height="cHeight" :width="cWidth" v-for="item in GeneCaseJsonObj" class="CvsGeneLib"></canvas>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue'
	import CvsStatus from '../entity/CvsStatus';
	import Gene from '../entity/Gene.js';

	var cHeight = ref(500)
	var cWidth = ref(500)

	var cvsStatusList = []
	
	var GeneCaseJsonObj=reactive([])
	
	var jsonstr = `{
	"gid": "ANZI0002",
	"profile": [{
		"size": "100",
		"part": [{
			"start": "80 15",
			"angle": "90",
			"ptype": "curve",
			"end": "91.54 15",
			"point": "85.77 17"
		}, {
			"ptype": "polygon",
			"point": ["80 15", "55 29.43"]
		}, {
			"start": "55 29.43",
			"angle": "270",
			"ptype": "curve",
			"end": "66.54 29.43",
			"point": "60.77 31.43"
		}],
		"cid": "01"
	}, {
		"size": "100",
		"part": [{
			"start": "80 15",
			"angle": "90",
			"ptype": "curve",
			"end": "91.54 15",
			"point": "85.77 17"
		}, {
			"ptype": "polygon",
			"point": ["80 15", "57 28.28"]
		}],
		"cid": "02"
	},{
		"size": "100",
		"part": [{
			"start": "80 15",
			"angle": "90",
			"ptype": "curve",
			"end": "91.54 15",
			"point": "85.77 17"
		}, {
			"ptype": "polygon",
			"point": ["80 15", "57 28.28"]
		}],
		"cid": "02"
	}],
	"info": {
		"date": "1800",
		"nation": "缁村�惧���锛�Uighur锛�",
		"usage": ["维锟斤拷锟斤拷锟斤拷锟斤拷毯", "维锟斤拷锟斤拷锟斤拷帽锟斤拷"],
		"sign": "锟斤拷锟斤拷"
	}
}
`
			var jsonObj=JSON.parse(jsonstr)
			jsonObj.profile.forEach((e)=>{
				GeneCaseJsonObj.push(e)
			})
	onMounted(() => {
		var cvss = document.getElementsByClassName('CvsGeneLib')
		for (let i = 0; i < cvss.length; i++) {
			cvsStatusList[i] = new CvsStatus(cvss[i])
			cvsStatusList[i].ctx.save()
			//cvsStatusList[i].ctx.translate(200,200)
			
			cvsStatusList[i].ctx.scale(5,5)
			cvsStatusList[i].ctx.rect(0,0,100,100)
			cvsStatusList[i].ctx.stroke()
		}
		var gene=new Gene(cvsStatusList,jsonObj)
		for(let i = 0; i < cvss.length; i++)
		{
			cvsStatusList[i].ctx.restore()
		}		
	})
</script>

<style scoped src='../assets/css/cvsGeneLib.css'>
</style>
