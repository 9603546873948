import $Math from '../YeahDrawer/$Math.js'
import CurveL from './CurveL.js';
import LineShape from "../YeahDrawer/LineShape.js";

export default class GeneCase extends LineShape {
	//draw方法效率低还可以优化 TO DO 2023/2/25
	constructor(gid, caseJsonObject, ctx) {
		super(ctx)
		this.gid = gid
		this.caseJsonObject = caseJsonObject
		var pathBody = 'var p=new Path2D();'
		var path = new Path2D()
		this.svgPathBody = ''
		this.$Math = $Math
		this.__curveLPoints__ = []
		this.caseJsonObject.parts.forEach((item, index) => {
			switch (item.ptype) {
				case 'polygon': {
					let childPath = new Path2D()
					childPath.moveTo(Number(item.points[0].x), Number(item.points[0].y));
					this.svgPathBody += `M ${item.points[0].x} ${item.points[0].y}`;
					for (let i = 1; i < item.points.length; i++) {
						this.svgPathBody += `L ${item.points[i].x} ${item.points[i].y}`
						childPath.lineTo(Number(item.points[i].x), Number(item.points[i].y))
					}
					path.addPath(childPath)
					break
				}
				case 'curveL': {
					var points = []
					points.push({
						x: Number(item.startPoint.x),
						y: Number(item.startPoint.y)
					})
					for (let i = 0; i < item.points.length; i++) {
						points.push({
							x: Number(item.points[i].x),
							y: Number(item.points[i].y)
						})
					}
					points.push({
						x: Number(item.endPoint.x),
						y: Number(item.endPoint.y)
					})
					this.__curveLPoints__.push(points)
					let i = this.__curveLPoints__.length - 1
					let rad = Number(item.deg) / 180 * Math.PI
					path.addPath($Math.drawLg(this.__curveLPoints__[i], rad))
					break
				}
				case 'circle': {
					let srad = Number(item.startDeg) * Math.PI / 180
					let erad = Number(item.endDeg) * Math.PI / 180
					let centre = {
						x: Number(item.centre.x),
						y: Number(item.centre.y),
					}
					let r = Number(item.radius)
					let childPath = new Path2D()
					childPath.arc(centre.x, centre.y, r, srad, erad, false)
					path.addPath(childPath)
					this.svgPathBody += $Math.canvasArcToSvgPath(centre.x, centre.y, r, srad, erad, false)
					break
				}
				case 'curve': {
					let p1 = {
						x: Number(item.startPoint.x),
						y: Number(item.startPoint.y)
					}
					let p2 = {
						x: Number(item.points[0].x),
						y: Number(item.points[0].y),
					}
					let p3 = {
						x: Number(item.points[1].x),
						y: Number(item.points[1].y),
					}
					if (item.points.length < 3) {
						let childPath=new Path2D()
						childPath.moveTo(p1.x,p1.y)
						childPath.quadraticCurveTo(p2.x,p2.y,p3.x,p3.y)
						path.addPath(childPath)
						this.svgPathBody += `M ${p1.x} ${p1.y} Q ${p2.x} ${p2.y} ${p3.x} ${p3.y}`
					} else {
						let p4 = {
							x: Number(item.points[2].x),
							y: Number(item.points[2].y),
						}
						let childPath=new Path2D()
						childPath.moveTo(p1.x,p1.y)
						childPath.bezierCurveTo(p2.x,p2.y,p3.x,p3.y,p4.x,p4.y)
						path.addPath(childPath)
						this.svgPathBody += `M ${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y}`
					}
					break
				}
				case 'curve5': {
					break
				}
			}
		});
		//pathBody += `this.strokePath=p;`;
		// this.$setStrokePath = new Function(pathBody);
		this.$setStrokePath=function(){
			this.strokePath=path
		}
		this.size = caseJsonObject.size; //引用传递
	}

	$isPointInPath = function(x, y) {
		return false //bug 会无法触发边界点事件
	}
	
	//TODO 请重写draw方法
	
	/*
	drawPolygon(item) {
		var ctx = this.ctx
		ctx.beginPath()
		ctx.moveTo(Number(item.points[0].x), Number(item.points[0].y))
		for (let i = 1; i < item.points.length; i++) {
			ctx.lineTo(Number(item.points[i].x), Number(item.points[i].y))
		}
		ctx.lineWidth = Number(item.lineWidth)
		ctx.stroke()
		ctx.lineWidth = this.strokeLineWidth
	}
	polygonPath(item, x, y) {
		var ctx = this.ctx
		ctx.beginPath();
		ctx.lineWidth = Number(item.lineWidth) + 2
		ctx.moveTo(Number(item.points[0].x), Number(item.points[0].x))
		for (let i = 1; i < item.points.length; i++) {
			ctx.lineTo(Number(item.points[i].x), Number(item.points[i].y))
		}
		if (ctx.isPointInStroke(x, y)) {
			ctx.lineWidth = this.strokeLineWidth
			return true
		} else {
			ctx.lineWidth = this.strokeLineWidth
			return false
		}
	}
	drawCurveL(item) {
		var ctx = this.ctx
		let arr = []
		arr[0] = {
			x: Number(item.startPoint.x),
			y: Number(item.startPoint.y)
		};
		var p = item.points
		p.forEach((e) => {
			arr.push({
				x: Number(e.x),
				y: Number(e.y)
			})
		});
		arr.push({
			x: Number(item.endPoint.x),
			y: Number(item.endPoint.y)
		})
		let rad = Number(item.deg) * Math.PI / 180
		ctx.lineWidth = Number(item.lineWidth)
		new CurveL(arr, rad, ctx).draw()
		ctx.lineWidth = this.strokeLineWidth
	}
	curveLPath(item, x, y) {
		var ctx = this.ctx
		let arr = []
		arr[0] = {
			x: Number(item.startPoint.x),
			y: Number(item.startPoint.y)
		};
		var p = item.points
		p.forEach((e) => {
			arr.push({
				x: Number(e.x),
				y: Number(e.y)
			})
		});
		arr.push({
			x: Number(item.endPoint.x),
			y: Number(item.endPoint.y)
		})
		let rad = Number(item.deg) * Math.PI / 180
		ctx.beginPath();
		ctx.lineWidth = Number(item.lineWidth) + 2
		new CurveL(arr, rad, ctx).drawLg()
		if (ctx.isPointInStroke(x, y)) {
			ctx.lineWidth = this.strokeLineWidth
			return true
		} else {
			ctx.lineWidth = this.strokeLineWidth
			return false
		}
	}
	drawCurve(item) {
		var ctx = this.ctx
		ctx.beginPath()
		ctx.moveTo(item.startPoint.x, item.startPoint.y)
		if (item.points.length == 3) {
			ctx.bezierCurveTo(item.points[0].x, item.points[0].y, item.points[1].x, item.points[1].y, item.points[2]
				.x,
				item.points[2].y)
		} else {
			ctx.quadraticCurveTo(item.points[0].x, item.points[0].y, item.points[1].x, item.points[1].y)
		}
		ctx.lineWidth = Number(item.lineWidth)
		ctx.stroke()
		ctx.lineWidth = this.strokeLineWidth
	}
	curvePath(item, x, y) {
		var ctx = this.ctx
		ctx.beginPath();
		ctx.lineWidth = Number(item.lineWidth) + 2
		ctx.moveTo(item.startPoint.x, item.startPoint.y)
		if (item.points.length == 3) {
			ctx.bezierCurveTo(item.points[0].x, item.points[0].y, item.points[1].x, item.points[1].y, item.points[2]
				.x,
				item.points[2].y)
		} else {
			ctx.quadraticCurveTo(item.points[0].x, item.points[0].y, item.points[1].x, item.points[1].y)
		}
		ctx.lineWidth = Number(item.lineWidth) + 2
		if (ctx.isPointInStroke(x, y)) {
			ctx.lineWidth = this.strokeLineWidth
			return true
		} else {
			ctx.lineWidth = this.strokeLineWidth
			return false
		}
	}
	drawCircle(item) {
		var ctx = this.ctx
		var {
			startDeg,
			endDeg
		} = item
		var startRad = Number(startDeg) / 180 * Math.PI
		var endRad = Number(endDeg) / 180 * Math.PI
		ctx.beginPath()
		ctx.arc(Number(item.centre.x), Number(item.centre.y), Number(item.radius), startRad, endRad, false)
		ctx.lineWidth = Number(item.lineWidth)
		ctx.stroke()
		ctx.lineWidth = this.strokeLineWidth
	}
	circlePath(item, x, y) {
		var ctx = this.ctx
		var {
			startDeg,
			endDeg
		} = item
		var startRad = Number(startDeg) / 180 * Math.PI
		var endRad = Number(endDeg) / 180 * Math.PI
		ctx.beginPath();
		ctx.lineWidth = Number(item.lineWidth) + 2
		ctx.arc(Number(item.centre.x), Number(item.centre.y), Number(item.radius), startRad, endRad, false)
		if (ctx.isPointInStroke(x, y)) {
			ctx.lineWidth = this.strokeLineWidth
			return true
		} else {
			ctx.lineWidth = this.strokeLineWidth
			return false
		}
	}
	*/
}
