<template>
	<article>
		<section>
			<h3>当前选中</h3>
			<div class="choose-warpper">
				<h4>基因信息</h4>
				<div>
					<label>基因出现年份：</label>
					<span>1800</span>
					<br />
					<label>基因所属民族：</label>
					<span>维吾尔族 (Uighur)</span>
					<br />
					<label>基因使用场景：</label>
					<ul>
						<li>维吾尔族地毯</li>
						<li>维吾尔族服饰</li>
					</ul>
					<label>基因标志：</label>
					<span>藤条与叶子</span>
				</div>
			</div>	
		</section>
		<section>
			<h3>基因库</h3>
			<div class="lib">
				<canvas v-for="i in 25"></canvas>
			</div>
			<div class="btn-warpper">
				<button><i class="iconfont icon-arrow-left"></i></button>
				<span>01</span>
				<button><i class="iconfont icon-arrow-right"></i></button>
			</div>
		</section>
	</article>

</template>

<script setup>
</script>

<style scoped src='../assets/css/gLib.css'>
</style>