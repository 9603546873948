import ProfilePainter from "../Painter/ProfilePainter.js";
import SShape from "../YeahDrawer/SShape.js";
import GeneCase from './GeneCase.js';
/*
Profile的图形与其它单一图形存在本质区别，
绘制过程必须使画布转换，不应该使用此对象，
直接使用CProfile
*/
export default class Profile extends SShape {
	constructor(profileJsonObject, geneCaseMap, ctx) {
		super(ctx);
		this.profileJsonObject = profileJsonObject;
		if (this.profileJsonObject.profileId) {
			this.profileId = this.profileJsonObject.profileId
		} else {
			this.profileId = '未命名' + Date.now()
		}
		this.geneCaseMap = geneCaseMap;
		this.img = null
		this.setImg()
		this.size = {
			x: Number(profileJsonObject.size.x),
			y: Number(profileJsonObject.size.y),
		}
	}

	$isPointInPath(x, y) {
		return false;
	}


	/**
	 * 获取图片
	 **/
	setImg() {
		var profilePainter = new ProfilePainter(this.ctx)
		profilePainter.buildProfile(this.profileJsonObject, this.geneCaseMap, this.ctx)
		profilePainter.generateContentImage(2, 2).then((imgdata) => {
			
			this.img = imgdata
			this.sizeXOri=Number(this.profileJsonObject.size.x)
			this.sizeYOri=Number(this.profileJsonObject.size.y)
			
			this.draw = function(initMatrix) {
				// var scaleX=this.fatherShape.ctxStatus.scaleX
				// var scaleY=this.fatherShape.ctxStatus.scaleY
				// debugger
				this.ctx.drawImage(this.img, 0, 0,this.sizeXOri,this.sizeYOri)
				//释放内存
			}
		})
	}
	/**
	 * 先绘制矢量图
	 **/
	drawSvg(initMatrix, ctx) {

	}

	draw() {}
	/**
	 * 不需要初始矩阵
	 **/
	TransfromNone(initMatrix, ctx, ...fns) {
		ctx = ctx || this.ctx
		var matrix1 = initMatrix
		ctx.setTransform(matrix1);
		ctx.save();
		fns.forEach((item) => {
			item.call(this, matrix1, ctx);
		})
		ctx.restore();
	}

	TransformRectangle(initMatrix, ctx, ...fns) {
		ctx = ctx || this.ctx
		var size = this.profileJsonObject.size
		var tx = Number(size.x) / 2;
		var ty = Number(size.y) / 2;
		var matrix1 = initMatrix.translate(tx, ty)
		//this.ctx.setTransform(matrix1);
		ctx.save();
		fns.forEach((item) => {
			item.call(this, matrix1.translate(-tx, -ty), ctx);
		})
		var matrix2 = matrix1.flipY();
		fns.forEach((item) => {
			item.call(this, matrix2.translate(-tx, -ty), ctx);
		})
		var matrix3 = matrix2.flipX();
		fns.forEach((item) => {
			item.call(this, matrix3.translate(-tx, -ty), ctx);
		})
		var matrix4 = matrix1.flipX();
		fns.forEach((item) => {
			item.call(this, matrix4.translate(-tx, -ty), ctx);
		})
		this.ctx.restore();
	}

	TransfromTriangle(initMatrix, ctx, ...fns) {
		ctx = ctx || this.ctx
		var size = this.profileJsonObject.size
		var tx = Number(size.x) / 2;
		var ty = Number(size.y) / 2;
		var matrix1 = initMatrix.translate(tx, ty)
		ctx.save();
		fns.forEach((item) => {
			item.call(this, matrix1.translate(-tx, -ty), ctx);
		})
		var matrix11 = matrix1.rotate(90);
		fns.forEach((item) => {
			item.call(this, matrix11.translate(-tx, -ty), ctx);
		})

		var matrix2 = matrix1.flipY();
		fns.forEach((item) => {
			item.call(this, matrix2.translate(-tx, -ty), ctx);
		})
		var matrix21 = matrix2.rotate(90);
		fns.forEach((item) => {
			item.call(this, matrix21.translate(-tx, -ty), ctx);
		})
		var matrix3 = matrix2.flipX();
		fns.forEach((item) => {
			item.call(this, matrix3.translate(-tx, -ty), ctx);
		})
		var matrix31 = matrix3.rotate(90);
		fns.forEach((item) => {
			item.call(this, matrix31.translate(-tx, -ty), ctx);
		})
		var matrix4 = matrix1.flipX();
		fns.forEach((item) => {
			item.call(this, matrix4.translate(-tx, -ty), ctx);
		})
		var matrix41 = matrix4.rotate(90);
		fns.forEach((item) => {
			item.call(this, matrix41.translate(-tx, -ty), ctx);
		})
		this.ctx.restore();
	}

	TransformLR(initMatrix, ctx, ...fns) {
		ctx = ctx || this.ctx
		var size = this.profileJsonObject.size
		var tx = Number(size.x) / 2;
		var ty = Number(size.y) / 2;
		var matrix1 = initMatrix.translate(tx, ty)
		ctx.save();
		fns.forEach((item) => {
			item.call(this, matrix1.translate(-tx, -ty), ctx);
		})
		var matrix2 = matrix1.flipY();
		fns.forEach((item) => {
			item.call(this, matrix2.translate(-tx, -ty), ctx);
		})
		this.ctx.restore();
	}

	TransformUB(initMatrix, ctx, ...fns) {
		ctx = ctx || this.ctx
		var size = this.profileJsonObject.size
		var tx = Number(size.x) / 2;
		var ty = Number(size.y) / 2;
		var matrix1 = initMatrix.translate(tx, ty)
		ctx.save();
		fns.forEach((item) => {
			item.call(this, matrix1.translate(-tx, -ty), ctx);
		})
		var matrix4 = matrix1.flipX();
		fns.forEach((item) => {
			item.call(this, matrix4.translate(-tx, -ty), ctx);
		})
		this.ctx.restore();
	}
}
