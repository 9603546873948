import service from "../tools/service.js"

export function getDatasetByLabel(label){
    return service.get('/image/dataset',{
        params:{
            label
        }
    })
}

export function getLabels(){
    return service.get('/image/labels')
}