import Img from "../SingleShape/Img.js";
import ControlPoint from "../YeahDrawer/ControlPoint.js";
import PainterElement from "./PainterElement.js";

/**
 * 注意传入的img必须已经load
 * **/
export default class CImg extends PainterElement {
	constructor(img, dx, dy, dw, dh, ctx) {
		super(...arguments)
		//this._leftTop
	}

	$getControlPoints(img, dx, dy, dw, dh, ctx) {
		this._leftTop = new ControlPoint(dx, dy, 6)
		this.controlPoints.push(this._leftTop)
		this._rightTop = new ControlPoint(dx + dw, dy, 6)
		this.controlPoints.push(this._rightTop)
		this._rightBottom = new ControlPoint(dx + dw, dy + dh, 6)
		this.controlPoints.push(this._rightBottom)
		this._leftBottom = new ControlPoint(dx, dy + dh, 6)
		this.controlPoints.push(this._leftBottom)
	}

	$getChildShape(img, dx, dy, dw, dh, ctx) {
		this._img = new Img(...arguments)
		this.childShapes.push(this._img)
		//这里要注意一下this的指向
		this._leftTop.bind(this._img, 'dx', 'dy')
	}

	boundryPath() {
		this.ctx.beginPath()
		this.ctx.rect(this.controlPoints[0].x, this.controlPoints[0].y, (this.controlPoints[2].x - this
			.controlPoints[0].x), (this.controlPoints[2].y - this.controlPoints[0].y))
	}

	actualBoundryPath() {
		this.ctx.rect(this.controlPoints[0].x, this.controlPoints[0].y, (this.controlPoints[2].x - this
			.controlPoints[0].x), (this.controlPoints[2].y - this.controlPoints[0].y))
	}
}
