//import CvsStatus from "./CvsStatus"

export default class Gene {

	constructor(cvsStatusList, geneJsonObject = null) {
		this.cvsStatusList = cvsStatusList
		this.profile=[]
		if (geneJsonObject) {
			this.gid = geneJsonObject.gid
			this.info = geneJsonObject.info
			for(let i=0;i<geneJsonObject.profile.length;i++){
				this.profile[i] = new Cas(cvsStatusList[i],geneJsonObject.profile[i])
			}
			
		}
	}
	
	set gid(value){
		this._gid=value
	}
	
	get gid(){
		return this._gid
	}
	
	set info(value){
		this._info=value
	}
	
	get info(){
		return this._info
	}
};

export class Cas {
	constructor(cvsStatus,casJsonObject) {
		this.cvsStatus = cvsStatus
		this.size = casJsonObject.size
		this.cid=casJsonObject.cid
		this.part=[]
		for(let i=0;i<casJsonObject.part.length;i++)
		{
			this.part[i] = new PartItem(cvsStatus,casJsonObject.part[i])
			this.part[i].geneCas=this
		}
		
	}
};
//绘制一个线条就是new 一个part，绘制一个case就是new一个ProfileItem
export class PartItem {
	constructor(cvsStatus, options) {
		this.cvsStatus = cvsStatus
		this.geneCas = null
		this.ptype = options.ptype
		if (this.ptype == 'polygon') {
			this._point = options.point
			this.drawPolygon()
		}
		if (this.ptype == 'curve') {
			this._start = options.start
			this._end = options.end
			this._point = options.point
			this._angle = options.angle
			this.drawCurve()
		}
		if (this.ptype == 'circle') {
			this._start = options.start
			this._end = options.end
			this._centre = options.centre
			this.drawCircle()
		}
		if(this.ptype=='newCircle') {
			this._start=options.start
			this._end=options.end
			this._centre=options.centre
			this._radius=option.radius
			this.drawNewCircle()
		}
	}

	drawPolygon() {
		var {
			ctx
		} = this.cvsStatus
		ctx.beginPath()
		ctx.moveTo(Number(this.point[0].split(' ')[0]), Number(this.point[0].split(' ')[1]))
		for (let i = 1; i < this.point.length; i++) {
			ctx.lineTo(Number(this.point[i].split(' ')[0]), Number(this.point[i].split(' ')[1]))
		}
		ctx.stroke()
	};

	// 绘制老曲线
	drawCurve() {
		var {
			ctx
		} = this.cvsStatus
		
		function fn(x1, y1, x2, y2, x3, y3, angle) {
			angle = angle * (Math.PI / 180);
			var x = 0;
			var fx = 0;
			ctx.beginPath();
			ctx.moveTo(x1, y1);
			for (x = x1 + 1; x <= x2; x++) {
				fx = (y1 * (x - x2) * (x - x3)) / ((x1 - x2) * (x1 - x3)) + (y2 * (x - x1) * (x - x3)) / ((x2 -
					x1) * (x2 -
					x3)) + (y3 * (x - x1) * (x - x2)) / ((x3 - x1) * (x3 - x2));

				//<x,fx>顺时针旋转angle°后的向量<xx,yy>
				var xx = (fx - y1) * Math.sin(angle) + (x - x1) * Math.cos(angle);
				var yy = -(x - x1) * Math.sin(angle) + (fx - y1) * Math.cos(angle);

				ctx.lineTo(xx + x1, yy + y1);
			}
			ctx.stroke();
		}
		var [x1, y1] = this.start.split(' ')
		var [x2, y2] = this.end.split(' ')
		var [x3, y3] = this.point.split(' ')
		var angle = this.angle
		fn(Number(x1), Number(y1), Number(x2), Number(y2), Number(x3), Number(y3), Number(angle))
	};

	//绘制老圆弧
	drawCircle() {
		var [xStart, yStart] = this.start.split(' ')
		var [xEnd, yEnd] = this.end.split(' ')
		var [xCentre, yCentre] = this.centre.split(' ')
		//此方法解析圆弧的起始角度与结束角度

		function fn(x1, y1, x2, y2) {
			let Zita = Math.atan((y1 - y2) / (x1 - x2));
			let pai = Math.PI;
			if (x2 > x1 && y2 >= y1) {
				return Zita;
			} else if (x2 == x1 && y2 > y1) {
				return 0.5 * pai;
			} else if (x2 < x1) {
				return pai + Zita;
			} else if (x2 == x1 && y2 < y1) {
				return 1.5 * pai;
			} else if (x2 > x1 && y2 < y1) {
				return 2 * pai + Zita;
			} else {
				console.log("文件错误!!!");
				return 0;
			}
		};

		var startAngle = fn(Number(xCentre), Number(yCentre), Number(xStart), Number(yStart));
		var endAngle = fn(Number(xCentre), Number(yCentre), Number(xEnd), Number(yEnd));

		var r = Math.sqrt((xStart - xCentre) * (xStart - xCentre) + (yStart - yCentre) * (yStart -
			yCentre));

		var {
			ctx
		} = this.cvsStatus
		ctx.beginPath();
		ctx.arc(xCentre, yCentre, r, startAngle, endAngle, false);
		ctx.stroke();

	};
	//绘制新圆弧
	drawNewCircle(){
		var [xCentre, yCentre] = this.centre.split(' ')
		ctx.beginPath()
		ctx.arc(Number(xCentre),Number(yCentre),Number(this.radius),Number(this.start),Number(this.end),false)
		ctx.stroke()
	}
	
	set point(value) {
		if (this.ptype == 'polygon') {
			//当线条独立与gene而存在的时候
			if (this.gene == null) {
				ctx.putImageData(imgData, cWidth, cHeight)
				this.drawPolygon()
			} else {
				this.drawPolygon()
			}
		}
		if (this.ptype == 'curve') {

		}
		this._point=value
	}


	set start(value) {
		if (this.ptype == 'curve') {

		}
		if (this.ptype == 'circle') {

		}
	}

	set end(value) {
		if (this.ptype == 'curve') {

		}
		if (this.ptype == 'circle') {

		}
	}

	set angle(value) {

	}

	set centre(value) {

	}
	
	set radius(value){
		this._radius=value
	}

	get point() {
		return this._point
	}

	get start() {
		return this._start
	}

	get end() {
		return this._end
	}

	get angle() {
		return this._angle
	}

	get centre() {
		return this._centre
	}
	
	get radius(){
		return this._radius
	}
};
