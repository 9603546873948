/**
 *此对象用来组合多个GenePainter
 *最后转化为一个geneJson对象
 **/
export default class GeneRaw {
	constructor(gid='未命名'+Date.now(),info=null,profiles=[]) {
		this.gid = gid
		this.info = info
		this.profiles = profiles
	}

	addProfile(cas, cover) {
		cas.cover = cover
		var index = this.profiles.findIndex((item) => {
			return item.cid == cas.cid
		})
		if (index!=-1) {
			this.profiles[index]=cas
		}else{
			this.profiles.push(cas)
		}
	}
}
