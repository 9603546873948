import { createApp } from 'vue'
import { createPinia } from 'pinia'
//引入基础样式
import '~/index.css'
import '~/style.css'
//引入图标库的css
import '~assets/iconfont/iconfont.css'
import App from '~/App.vue'

//引入是页面元素自适应的脚本
import '~tools/flexible.js'

import router from'~router/index.js'
//element plus自动导入插件有bug，垃圾
import 'element-plus/theme-chalk/el-message.css'
import "element-plus/theme-chalk/el-notification.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueKonva from 'vue-konva'

//返回app实例
const pinia = createPinia()
var app=createApp(App).use(router).use(pinia).use(VueKonva)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
//全局区域,必须要写在mount前面
app.config.globalProperties.globalMsg = 'Tips:有任何疑问请联系作者1761484765@qq.com'
//返回一个代理对象,就是this
var p=app.mount('#app')

// console.log(app)
// console.log(p)

