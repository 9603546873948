import CGeneCase from "../CompositionShape/CGeneCase.js";
import SShape from "./SShape.js";
import Observer from './Observer.js'
import ControlPoint from "./ControlPoint.js";

/*此图形用来控制某个图形的旋转角度，绑定在控制点上，围绕该控制点旋转*/
/*此图形是画板的直接子元素*/
export default class TurnTable extends SShape {
	constructor(centreX, centreY, radius, ctx) {
		super(ctx)
		this.centreX = centreX
		this.centreY = centreY
		this.radius = radius
		this.fontSize = 24
		this.angle = 0
		this.onMousemove__ = null
		this._defaultDrawFn = this.draw
		this._defaultIsIn = this.$isPointInPath
		this._defaultIsInLeft = this.isPointInLeft
		this._defaultIsInRight = this.isPointInRight
	}

	drawPath() {
		this.ctx.beginPath()
		this.ctx.arc(this.centreX, this.centreY, this.radius, 0, 2 * Math.PI)
		this.ctx.arc(this.centreX, this.centreY, this.radius * 0.75, 0, 2 * Math.PI)
	}

	drawOuterArc() {
		this.ctx.beginPath()
		this.ctx.arc(this.centreX, this.centreY, this.radius, 0, 2 * Math.PI)
	}
	drawInnerArc() {
		this.ctx.beginPath()
		this.ctx.arc(this.centreX, this.centreY, this.radius * 0.75, 0, 2 * Math.PI)
	}

	drawProcessBar() {
		this.ctx.beginPath()
		var deg = this.angle / 180 * Math.PI
		if (deg > 0)
			this.ctx.arc(this.centreX, this.centreY, this.radius * 0.875, -Math.PI / 2, deg - Math.PI / 2)
		else {
			this.ctx.arc(this.centreX, this.centreY, this.radius * 0.875, -Math.PI / 2, deg - Math.PI / 2, true)
		}
	}

	drawTextBackGround() {
		this.ctx.beginPath()
		this.ctx.rect(this.centreX - this.radius * 0.375, this.centreY - this.radius * 0.3125, this.radius * 0.75,
			this.radius * 0.625)
	}

	drawText() {
		this.ctx.font = this.fontSize * this.radius / 80 + "px serif";
		this.ctx.textAlign = "center";
		this.ctx.textBaseline = "middle"
		this.ctx.fillText(`${this.angle}°`, this.centreX + this.radius * 0.1, this.centreY)
	}
	drawVLine() {
		this.ctx.beginPath()
		this.ctx.moveTo(this.centreX, this.centreY - this.radius)
		// this.ctx.lineTo(this.centreX, this.centreY - this.radius + 20)
		this.ctx.lineTo(this.centreX, this.centreY - this.radius * 0.75)
		//this.ctx.moveTo(this.centreX, this.centreY + this.radius - 20)
		this.ctx.moveTo(this.centreX, this.centreY + this.radius * 0.75)
		this.ctx.lineTo(this.centreX, this.centreY + this.radius)
	}
	draw() {
		//必须有绑定的元素才绘制
		if (this.bindShape) {
			this.ctx.save()
			this.drawOuterArc()
			this.ctx.strokeStyle = '#C4C4D1'
			this.ctx.stroke()
			this.drawInnerArc()
			this.ctx.strokeStyle = '#C4C4D1'
			this.ctx.stroke()
			this.drawVLine()
			this.ctx.strokeStyle = 'black'
			this.ctx.stroke()
			this.ctx.strokeStyle = 'rgba(128,178,219,0.5)'
			this.ctx.lineWidth = this.radius * 0.25
			this.drawProcessBar()
			this.ctx.stroke()
			this.ctx.fillStyle = "rgb(237,237,237,0.5)"
			this.drawTextBackGround()
			this.ctx.fill()
			this.ctx.fillStyle = "rgb(34,34,85)"
			this.drawText()
			this.ctx.restore()
		}
	}

	$isPointInPath(x, y) {
		this.ctx.save()
		this.ctx.beginPath()
		this.ctx.arc(this.centreX, this.centreY, this.radius * 0.875, 0, Math.PI * 2)
		this.ctx.lineWidth = this.radius * 0.25
		var flag = this.ctx.isPointInStroke(x, y) //531 410
		this.ctx.restore()
		debugger
		return flag
	}

	isPointInRight(x, y) {
		this.ctx.save()
		this.ctx.beginPath()
		this.ctx.arc(this.centreX, this.centreY, this.radius * 0.875, -Math.PI / 2, Math.PI / 2)
		this.ctx.lineWidth = this.radius * 0.25
		var flag = this.ctx.isPointInStroke(x, y)
		this.ctx.restore()
		return flag
	}

	isPointInLeft(x, y) {
		this.ctx.save()
		this.ctx.beginPath()
		this.ctx.arc(this.centreX, this.centreY, this.radius * 0.875, Math.PI / 2, Math.PI * 3 / 2)
		this.ctx.lineWidth = this.radius * 0.25
		var flag = this.ctx.isPointInStroke(x, y)
		this.ctx.restore()
		return flag
	}

	/**
	 * 此方法用来绑定一个旋转点
	 **/
	bind(point) {
		if (point && point.fatherShape) {
			//换绑定对象时首先要解除上一个绑定的观察者
			//别绑定在对象上 绑定在CtxStatus上 TO DO 2023/2/24
			if (this.bindShape) {
				this.bindShape.removeObserver
			}
			this.bindShape = point.fatherShape
			if (this.bindShape instanceof CGeneCase) {
				var tb = this
				var ob = new Observer()
				ob.update = function(obj) {
					tb.centreX = obj.translateX
					tb.centreY = obj.translateY
					tb.angle = obj.rotate * 180 / Math.PI
				}
				this.bindShape.ctxStatus.addObserver(ob)
				this.centreX = this.bindShape.ctxStatus.translateX
				this.centreY = this.bindShape.ctxStatus.translateY
				this.angle = this.bindShape.ctxStatus.rotate * 180 / Math.PI
				var fnBody = `this.bindShape.ctxStatus.rotate=this.angle*Math.PI/180`;
				this.doRotate = new Function(fnBody)
			} else {}
		}
		//如果绑定的元素为空
		else if (!point) {
			this.bindShape = null
			this.centreX = 0
			this.centreY = 0
			this.angle = 0
			this.doRotate = function() {}
		} else {
			throw new Error('绑定的元素错误')
		}
	}

	/**
	 * 显示该图形
	 **/
	show(size) {
		if (size) {
			this.radius = size
		}
		this.draw = this._defaultDrawFn
		this.$isPointInPath = this._defaultIsIn
		this.$isPointInLeft = this._defaultIsInLeft
		this.$isPointInRight = this._defaultIsInRight
	}
	/**
	 * 隐藏该图形
	 **/
	hidden() {
		this.draw = function() {

		}
		this.$isPointInPath = function() {
			return false
		}
		this.$isPointInLeft = function() {
			return false
		}
		this.$isPointInRight = function() {
			return false
		}
	}

	onMousedown__($event) {
		var son = this
		if (son.timer) {
			clearInterval(son.timer)
		}
		this.fatherShape.onMouseup__ = function() {
			clearInterval(son.timer)
		}
		if (this.isPointInRight($event.clientX, $event.clientY)) {
			this.timer = setInterval(() => {
				this.angle++
				if (this.angle > 360) {
					this.angle -= 360
				}
				this.doRotate()
			}, 33)
		}
		if (this.isPointInLeft($event.clientX, $event.clientY)) {
			//console.log('left')
			this.timer = setInterval(() => {
				this.angle--
				if (this.angle < -360) {
					this.angle += 360
				}
				this.doRotate()
			}, 33)
		}
	}
	onMouseup__($event) {
		clearInterval(this.timer)
	}
}
