import {
	defineStore
} from 'pinia';
import {
	uploadProfile,
	getGenes
} from '../api/PatternProfileApi.js';
import LocalManager from './LocalManger.js';
import FileManager from './FileManager.js'
import clip from '../tools/clip.js'
import ProfilePainter from '../assets/js/PainterDemo-v1.3/Painter/ProfilePainter.js'

export const useProfiledrawerStore = defineStore('profiledrawer', {
	state: () => ({
		painter: null,
		preparedShape: null,
		isSaved: true,
		message: '',
		loading: false,
		progress:0,
		statistics:{
			progress:0,
			avgFPS:0,
			avgDrawingTime:0,
			counting:false
		},
		backgroundMode:1,//用来记录背景模式，切换图像的时候背景模式不变
	}),
	actions: {
		isProfile(obj) {
			if (obj.profileId && (obj.genes instanceof Array) && obj.size) {
				return true
			} else {
				this.message = '文件不是图案文件，请检查' + Date.now()
				return false
			}
		},
		async loadLocal(cont) {
			var obj = await FileManager.readLocalJson()
			if (this.isProfile(obj)) {
				this.createPainter(cont, obj)
			}
		},
		async saveLocal() {
			var obj = LocalManager.getOneditProfile()
			if (obj) {
				var res = await FileManager.writeLocalJson(obj)
				if (res) {
					this.message = '保存本地文件成功！' + Date.now()
				} else {
					this.message = '保存本地文件失败！' + Date.now()
				}
			} else {
				this.message = '当前没有任何已保存的图案！' + Date.now()
			}
		},
		async saveAsLocal() {
			var obj = LocalManager.getOneditProfile()
			if (obj) {
				var res = await FileManager.writeAsLocalJson(obj)
				if (res) {
					this.message = '保存本地文件成功！' + Date.now()
				} else {
					this.message = '保存本地文件失败！' + Date.now()
				}
			} else {
				this.message = '当前没有任何已保存的图案！' + Date.now()
			}
		},
		loadOneditProfile(cont) {
			var item = LocalManager.getOneditProfile()
			if (item) {
				this.createPainter(cont, item)
			}
		},
		/**
		 * 
		 * @param {Object} cont Canvas的Dom对象 
		 * @param {*} profile 
		 */
		async createPainter(cont, profile) {
			this.loading = true
			this.destory()
			var ctx = cont.getContext('2d')
			if (profile) {
				let genes = []
				var geneCasMap = new Map()
				var that = this
				for (let i = 0; i < profile.genes.length; i++) {
					genes.push(profile.genes[i].gid)
				}
				if (genes.length > 0) {
					await getGenes(genes).then((data) => {
						//将data中所有gene的case全部放入map中
						var genesDetail = data.data
						genesDetail.forEach((item) => {
							var gid = item.gid
							item.profiles.forEach((sub) => {
								var cid = sub.cid
								geneCasMap.set(`${gid}-${parseInt(cid)}`, sub)
							})
						})
						this.painter = new ProfilePainter(ctx)
						this.painter.ctxStatus.translateX = 300
						this.painter.ctxStatus.translateY = 300
						this.painter.ctxStatus.scaleX = 2
						this.painter.ctxStatus.scaleY = 2

						this.painter.buildProfile(profile, geneCasMap, ctx)
						// this.painter.ctxStatus.scaleX = 2
						// this.painter.ctxStatus.scaleY =2
						//this.save()
						this.painter.draw()
						cont.onmousedown = function(event) {
							that.painter.$mousedown(event)
						}
						cont.onmousemove = function(event) {
							that.painter.$mousemove(event)
						}
						cont.onmouseup = function(event) {
							that.painter.$mouseup(event)
						}
						cont.onkeydown = function(event) {
							if (event.keyCode == 13) {
								that.painter.$keyEnter(event)
							}
							if (event.keyCode == 46) {
								that.painter.$keyDel(event)
							}
						}
						cont.ondblclick = function(event) {
							that.painter.$dbclick(event)
						}
						//promise正常
						return true
					})
					this.loading = await this.painter.loading
				} else {
					this.createPainter(cont, null)
				}
			} else {
				this.painter = new ProfilePainter(ctx)
				this.painter.loading.then((flag) => {
					this.loading = flag
				})
				this.painter.ctxStatus.translateX = 300
				this.painter.ctxStatus.translateY = 300
				this.painter.ctxStatus.scaleX = 2
				this.painter.ctxStatus.scaleY = 2
				//设置画布背景模式
				this.painter.backgroundMode=this.backgroundMode

				this.painter.buildProfile(null, null, ctx)
				//this.save()
				this.painter.draw()
				var that = this
				cont.onmousedown = function(event) {

					that.painter.$mousedown(event)
				}
				cont.onmousemove = function(event) {

					that.painter.$mousemove(event)
				}
				cont.onmouseup = function(event) {
					that.painter.$mouseup(event)
				}
				cont.onkeydown = function(event) {
					if (event.keyCode == 13) {
						that.painter.$keyEnter(event)
					}
					if (event.keyCode == 46) {
						that.painter.$keyDel(event)
					}
				}
				cont.ondblclick = function(event) {
					that.painter.$dbclick(event)
				}
			}
			FileManager.clearFh()
		},
		/*
			此方法复制当前画布的onEditshape
		*/
		copy(){
			
		},
		downloadFile() {
			if (this.painter) {
				//这个方法有点问题，他会直接根据画板当前的元素产生一个svg而非已保存的元素
				var res = this.painter.transFormIntoSvg()
				var arr=[res]
				var svgBlob=new Blob(arr,{type:'image/svg+xml'})
				var downloadSrc=URL.createObjectURL(svgBlob)
				var a=document.createElement('a')
				a.setAttribute('href',downloadSrc)
				a.setAttribute('download','')
				a.download=`${this.painter.profileId}.svg`
				a.click()
				this.message = 'ok' + Date.now()
			}else{
				this.message = '当前没有打开项目' + Date.now()
			}

		},
		destory() {
			if (this.painter) {
				this.painter.destory()
				this.painter = null
			}
		},
		save() {
			if (this.painter) {
				var item = this.painter.save()
				if (item) {
					LocalManager.storeOneditProfile(item)
					this.isSaved = true
					this.message = '保存成功' + Date.now()
				} else {
					this.message = '保存失败' + Date.now()
				}
			} else {
				this.message = '当前无画板' + Date.now()
			}

		},
		upload() {
			if (this.painter) {
				if (this.isSaved) {
					var uploadJson = LocalManager.getOneditProfile()
					//绘制时用不到的缩略图不存在loacl了
					var startX = this.painter.ctxStatus.translateX
					var startY = this.painter.ctxStatus.translateY
					var sizeX = this.painter.contentSizeX * this.painter.ctxStatus.scaleX
					var sizeY = this.painter.contentSizeY * this.painter.ctxStatus.scaleY
					clip(this.painter.ctx.canvas, startX, startY, sizeX, sizeY)
						.then((img) => {
							uploadProfile(img.src, uploadJson, uploadJson.profileId).then(() => {
								this.message = '上传成功' + Date.now()
							}).catch(() => {
								this.message = '上传失败' + Date.now()
							})
						})
				} else {
					var flag = window.confirm('当前状态没有保存，是否上传？')
					if (flag) {
						var uploadJson = LocalManager.getOneditProfile()
						var startX = this.painter.ctxStatus.translateX
						var startY = this.painter.ctxStatus.translateY
						var sizeX = this.painter.contentSizeX * this.painter.ctxStatus.scaleX
						var sizeY = this.painter.contentSizeY * this.painter.ctxStatus.scaleY
						clip(this.painter.ctx.canvas, startX, startY, sizeX, sizeY).then((img) => {
							uploadProfile(img.src, uploadJson, uploadJson.profileId).then(() => {
								this.message = '上传成功' + Date.now()
							}).catch(() => {
								this.message = '上传失败' + Date.now()
							})
						})
					}
				}
			} else {
				this.message = '没有正在编辑的图形，上传无效' + Date.now()
			}
		},
		/**
		 * 统计平均帧率
		 * @param {Number}  timeSum 一段时间
		 **/
		 countAveFps(timeSum){
			 this.statistics.avgFPS=0
			 this.statistics.avgDrawingTime=0
			 this.statistics.progress=0
			 var time=0
			 this.painter.startCount()
			 this.statistics.counting=true
			 var timer=setInterval(()=>{
				 this.statistics.progress+=(100000/timeSum)//进度条进度，用来给用户展示
				 time+=1000
				 if(time==timeSum)
				 {
					 var temp=this.painter.finishCount()
					 this.statistics.avgFPS=temp.fps
					 this.statistics.avgDrawingTime=temp.avgDrawingTime
					 this.statistics.counting=false
					 clearInterval(timer)
				 }
			 },1000)
		 },
	}
})
