import SolidShape from '../YeahDrawer/SolidShape.js'
export default class Img extends SolidShape{
	constructor(img,dx,dy,dw,dh,ctx){
		super(ctx)
		this.img=img
		this.dx=dx
		this.dy=dy
		this.dw=dw
		this.dh=dh
	}
	
	$setStrokePath(){
		this.strokePath=new Path2D()
		this.strokePath.rect(this.dx,this.dy,this.dw,this.dh)
	}
	
	$isPointInPath(x,y){
		this.ctx.lineWidth=this.lineWidth.outter+2
		var flag=this.ctx.isPointInPath(this.strokePath,x,y)
		this.ctx.lineWidth=1
		return flag
	}
	
	draw(){
		this.$setStrokePath()
		this.ctx.drawImage(this.img,this.dx,this.dy,this.dw,this.dh)
	}
}