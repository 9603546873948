<template>
    <div class="info-bar">
        <li class="btn" @click="showTips"><i class="iconfont icon-pointer"></i>操作说明</li>
        <Silder></Silder>
        <li @click="emitter.emit('newCase')" v-if="route.path == '/DrawGene' || route.path == '/'" class="btn">
            <i class="iconfont icon-xinjian" style="font-size: 24px;vertical-align:text-top;"></i>
            新建case
        </li>
        <li @click.capture="openImg" class="btn">添加临摹底图
        </li>
        <input type="file" style="display: none;" ref="imgRef" @change="handleImgUpload" accept="image/*">
        <li class="btn" @click="emitter.emit('save')">保存(缓存)</li>
        <li>
            <el-dropdown>
                <div>
                    <span>显示设置</span>
                    <span style="font-size: 0.2rem;">▽</span>
                </div>
                <template #dropdown v-if="store3.painter">
                    <el-dropdown-menu>
                        <el-dropdown-item @click="changeBackgroundMode(1)">正常背景</el-dropdown-item>
                        <el-dropdown-item @click="changeBackgroundMode(2)">不显示原点</el-dropdown-item>
                        <el-dropdown-item @click="changeBackgroundMode(3)">不显示坐标轴</el-dropdown-item>
                        <el-dropdown-item @click="changeBackgroundMode(4)">不显示背景</el-dropdown-item>
                        <el-dropdown-item @click="useOrCancelBoundry()" v-if="store1.painter">显示/不显示边界
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

        </li>
    </div>
</template>

<script setup>
import { ElNotification } from 'element-plus'
import Silder from '~components/Silder.vue'
import {
    emitter
} from '~bus/emitter.js'
import {
    ref,
    computed,
    h,
} from 'vue'
import {
    usePatterndrawerStore
} from '~store/patterndrawer.js'

var store3 = usePatterndrawerStore()
var imgRef = ref(null)

function openImg() {
    imgRef.value.click()
    console.log(123)
}

const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
]

function handleImgUpload(event) {
    const file = event.target.files[0]
    if (file) {
        ElNotification({
            title: '生成图',
            dangerouslyUseHTMLString: true,
            message: `<img src="${URL.createObjectURL(file)}" width="300" height="300">`,
            duration: 0,
        })
    }
}



function changeBackgroundMode(mode) {
    if (store3.painter && store3.painter.backgroundMode) {
        store3.painter.backgroundMode = mode
    }
}


function showTips() {
    ElMessageBox({
        title: 'Tips:',
        message: h('ul', {
            style: 'padding-left:0.1rem'
        }, [
            h('li', {
                style: 'color: teal'
            }, "请将浏览器缩放比例调整至80%获得最佳体验"),
            h('li', {
                style: 'color: teal'
            }, "点击文件-新建项目"),
            h('li', {
                style: 'color: teal'
            }, "双击将确认态元素转为编辑态"),
            h('li', {
                style: 'color: teal'
            }, "回车将编辑态元素转为确认态"),
            h('li', {
                style: 'color: teal'
            }, "元素处于编辑态时按Del键删除元素"),
            h('li', {
                style: 'color: teal'
            }, "亦可以在右侧面板操作"),
        ]),
    })
}
</script>

<style scoped src='~assets/css/drawingBoard.css'></style>