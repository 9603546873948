import Shape from "./Shape.js";
import $Event from './$Event.js'
import $Math from "./$Math.js";

export default class SShape extends Shape {
	constructor(ctx) {
		super(ctx)
		this.createTime = Date.now()
		//draw函数和$isPointInPath函数要使用的路径
		this.strokePath = new Path2D()
		this.lineWidth = {
			inner: 1,
			outter: 1
		}
		this.strokeColor = {
			inner: '#ffffff',
			outter: '#000000',
		}
	}

	$setStrokePath() {

	}
	//复制当前元素的一个副本
	copy(){
		return new Shape(this.ctx)
	}
	
	draw() {
		this.$setStrokePath()
		//线条样式是LineShape的属性，
		if (this.lineWidth.inner != this.lineWidth.outter) {
			this.ctx.lineWidth = this.lineWidth.outter
			this.ctx.strokeStyle = this.strokeColor.outter
			this.ctx.stroke(this.strokePath)
			this.ctx.lineWidth = this.lineWidth.inner
			this.ctx.strokeStyle = this.strokeColor.inner
			this.ctx.stroke(this.strokePath)
		} else {
			this.ctx.lineWidth = this.lineWidth.outter
			this.ctx.strokeStyle = this.strokeColor.outter
			this.ctx.stroke(this.strokePath)
		}
	}

	$isPointInPath(x, y) {
		// this.ctx.lineWidth = this.lineWidth.outter + 2
		// var flag = this.ctx.isPointInStroke(this.strokePath, x, y)
		// this.ctx.lineWidth = 1
		// return flag
	}


	__eventProxy__($event, eventHandler__) {

		if (this.fatherShape.fatherShape) {
			// if (!$Math.matrixEqual(this.fatherShape.ctxStatus.matrix, this.fatherShape.fatherShape.ctxStatus
			// 		.matrix))
			$event.update(this, this.fatherShape.ctxStatus)
			if (eventHandler__)
				eventHandler__.call(this, $event)
		} else {
			if (this.fatherShape) {
				if (eventHandler__)
					eventHandler__.call(this, $event)
			} else {
				throw new Error('single shape不能独立存在，请至少将其加入painter中')
			}
		}
	}

	$mousedown($event) {
		//在第三层
		this.__eventProxy__($event, this.onMousedown__)
	}
	$mousemove($event) {
		this.__eventProxy__($event, this.onMousemove__)
	}
	$mouseup($event) {
		this.__eventProxy__($event, this.onMouseup__)
	}
	//on开头的方法暴露给外界使用
	onMousedown__($event) {}

}
