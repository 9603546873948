/*export function remSize(){
	//获取设备宽度
	// 在pc浏览器上
	// window.innerWidth获得的宽度是包括滚动条宽度的
	// document.documentElement.clientWidth获得的宽度不包括滚动条宽度
	// 所以获得的不一样，当然页面没有超出一屏也是一样的
	
	// 在移动端浏览器上，滚动条悬浮在页面内容上，不占宽度，所以获得的是一样的
	var deviceWidth=document.documentElement.clientWidth||window.innerWidth
	
	//不能让宽度太宽，或者太窄，在375~750之间，因为设计稿以750为标准
	if(deviceWidth>=750){
		deviceWidth=750
	}
	if(deviceWidth<=320){
		deviceWidth=320
	}
	//设备宽750--->1rem=100px
	//设备宽375--->1rem=50px
	document.documentElement.style.fontSize=(deviceWidth/7.5)+'px'
}
remSize()//立刻调用函数
*/

window.onresize=function(){
	remSize()
}

function remSize()
{
	var deviceWidth=document.documentElement.clientWidth||window.innerWidth
	if(deviceWidth>=1280)
		deviceWidth=1280
	if(deviceWidth<=320)
		deviceWidth=320
	//设备宽750--->1rem=100px
	//设备宽375--->1rem=50px
	document.documentElement.style.fontSize=(deviceWidth/12.8)+'px'
}
remSize()
window.addEventListener('resize',remSize)
