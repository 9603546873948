import service from "../tools/service.js"

// var baseApi='http://124.221.110.63:8888/lyl_api'
let link = (url, method = "GET", data = {}, params = {}) => {
	return service.request({
		url,
		method,
		data,
		params
	}).then(ok => {
		return ok
	}, err => {
		//抛出请求时的错误
		throw err
	})
}

export default link
