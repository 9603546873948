<template>
	<div class='canvas-warpper-center'>
		<div class="canvas-warpper-sub" v-loading="store.loading">
			<canvas id="patternCont" :height="cHeight" :width="cWidth" tabindex="0" :style="PointerStyle"></canvas>
		</div>
		<Dialog :title='myDialog.loadPattern.title' :visible="eventHappening!=''" @close="eventHappening=''"
			@cancel='handleCancel' @ok='handleOk' :width="1200" :height="900">
			<div v-if="eventHappening=='load'" class="dialog-content">
				<div v-for="(item,index) in myDialog.loadPattern.patterns" class="c-item"
					:class="{'c-selected':(item.pid==myDialog.loadPattern.patternSelected.pid)}"
					@click="myDialog.loadPattern.selectPattern(item,index)">
					<span class="c-item-gid">{{item.pid}}</span>
					<img :src="item.litimgPath" class="c-item-img" />
				</div>
				<el-pagination small background layout="prev, pager, next" :total="myDialog.loadPattern.total"
					v-model:current-page='myDialog.loadPattern.page' class="my-pagination" :page-size="5"/>
				<div>
				</div>
			</div>
			<div v-else-if="eventHappening=='new'">
				<label>基因编号Gid:<input /></label>
			</div>
		</Dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onUpdated,
		watch,
		computed,
		onUnmounted,
	} from 'vue';

	import {
		usePatterndrawerStore
	} from '../store/patterndrawer.js'
	import {
		emitter
	} from '../bus/emitter.js'
	import { h } from 'vue'
	import { ElMessage } from 'element-plus'
	import {getPatternImgByPage,getPatternById} from '../api/PatternProfileApi.js'
import CProfile from '../assets/js/PainterDemo-v1.3/CompositionShape/CProfile.js';
	var store = usePatterndrawerStore()
	var cHeight = ref(800)
	var cWidth = ref(1200)
	var eventHappening = ref('')
	//鼠标样式变化
	var PointerStyle = computed(() => {
		if (store.preparedShape)
			return {
				cursor: 'crosshair'
			}
		else {
			return {
				cursor: 'default'
			}
		}
	})
	var ctx = null
	var cont = null
	//初始化对话框
	var myDialog = reactive({
		loadPattern: {
			title: '加载整体图案',
			patterns: [],
			page: 1,
			patternSelected: {
				pid: '',
				img: '',
				index:0,
			},
			confirmSelectPattern() {
				var loadPattern = this
				// getPatternById(this.patternSelected.pid).then((data) => {
				// 	var pattern = data.data.jsonObj
				// 	initDrawer(pattern)
				// }).catch(console)
				initDrawer(this.patterns[this.patternSelected.index].jsonContext)
			},
			cancelSelectPattern() {
				this.patternSelected.pid = ''
			},
			selectPattern(item,index) {
				this.patternSelected.pid = item.pid
				this.patternSelected.img = item.litimgPath
				this.patternSelected.index=index
			}
		},
		newPattern: {
	
		}
	})
	//监听分页器
	watch(() => myDialog.loadPattern.page, (newVal, v2) => {
		// console.log(newVal, v2);
		var params = {
			size: 5,
			page: newVal
		};
		getPatternImgByPage(params.page, params.size).then((data) => {
			var obj = data.data
			myDialog.loadPattern.total = obj.total
			myDialog.loadPattern.patterns = obj.records
			
		});
	}, {
		immediate: true
	});
	//监听并弹出消息
	watch(()=>store.message,(val)=>{
		if(val)
		{
			ElMessage(val)
		}
	})
	
	function initDrawer(pattern) {
		cont = document.getElementById('patternCont')
		store.createPainter(cont, pattern) //这是异步的，在这里设置加载动画
	}
	
	function load() {
		eventHappening.value = 'load'
	}
	
	function select() {
		eventHappening.value = ''
	}
	
	function handleCancel() {
		eventHappening.value = ''
		myDialog.loadPattern.cancelSelectPattern()
	}
	
	function handleOk() {
		eventHappening.value = ''
		myDialog.loadPattern.confirmSelectPattern()
	}
	
	
	function upload() {
		//获取canvas截图
		//上传截图与profile
		store.upload()
	}
	
	function prepared(data) {
		var {
			profileJsonObj
		} = data
		store.prepareToEdit(profileJsonObj)
	}
	
	function newPattern(){
		store.createPainter(cont, null)
	}
	emitter.on('prepared', prepared)
	emitter.on('loadPattern', load)
	emitter.on('save', store.save)
	emitter.on('uploadPattern', upload)
	emitter.on('newPattern',newPattern)
	
	
	onMounted(() => {
		cont = document.getElementById('patternCont')
		ctx=cont.getContext('2d', {willReadFrequently: true})
		store.loadOneditPattern(cont)
	})
	
	
	onUnmounted(() => {
		store.destory()
		emitter.off('prepared', prepared)
		emitter.off('loadPattern', load)
		emitter.off('save', store.save)
		emitter.off('uploadPattern', upload)
		emitter.off('newPattern',newPattern)
	})
</script>

<style scoped src='../assets/css/cvsPatternDrawer.less'>
</style>
