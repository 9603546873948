import link from "../tools/api.js"


export function uploadLimiting(data,params){

    // 发送请求
    return link("/litimg/saveLimiting.do","post",data,params).then((ok)=>{
        return ok.data;
    }).catch((err)=>{
		console.log(err)
	})
}