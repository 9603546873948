<template>
	<article>
		<h3>
			<span class="btn" @click="currentPage.changeContent('left')">&lt;</span>
			{{currentContentChinese}}
			<span class="btn" @click="currentPage.changeContent('right')">&gt;</span>
		</h3>
		<template v-if="genes&&currentContent=='geneList'">
			<div class="container" v-loading="loadGene">
				<div v-for="item in genes" class="genes-item">
					<span>{{item.gid}}</span>
					<div class="genes-item-imgs">
						<img v-for="(subitem,index) in item.img" :src="subitem" @click="chooseGCase(item.gid,index)" />
					</div>
				</div>
			</div>
			<el-pagination small background layout="prev, pager, next" :total="total" class='my-pager'
				v-model:current-page='page' />
		</template>
		<!-- 注意template不能使用v-show -->
		<template v-else-if="store.painter&&currentContent=='geneInfo'">
			<div class="container">
				<label class="container-item info-label">基本路径数量:
					<span>{{store.painter.getBasicLineNumber()}}</span>
				</label>
				<label class="container-item info-label">帧率统计:
					<br />
					<el-button @click="countAve" :disabled="store.statistics.counting">开始记录</el-button>
					<el-progress :percentage="store.statistics.progress" />
					<span>平均帧率:{{store.statistics.avgFPS}}</span>
					<span>平均每帧绘制时间:{{store.statistics.avgDrawingTime}}</span>
				</label>
				<h3>图案信息</h3>
				<div class="container-item">
					<label class="info-label">
						<span>图案编号</span>
						<div class="info-input">
							<el-input :disabled="!infoEditable.profileId" v-model="store.painter.profileId"></el-input>
							<el-button @click="edit('profileId')">编辑/确定</el-button>
						</div>
					</label>
					<label class="info-label">
						<span>所属民族</span>
						<div class="info-input">
							<el-input :disabled="!infoEditable.nation" v-model="store.painter.info.nation"></el-input>
							<el-button @click="edit('nation')">编辑/确定</el-button>
						</div>
					</label>
					<label class="info-label">
						<span>寓意</span>
						<div class="info-input">
							<el-input :disabled="!infoEditable.sign" v-model="store.painter.info.sign"></el-input>
							<el-button @click="edit('sign')">编辑/确定</el-button>
						</div>
					</label>
					<label class="info-label">
						<span>年份</span>
						<div class="info-input">
							<el-input :disabled="!infoEditable.date" v-model="store.painter.info.date"></el-input>
							<el-button @click="edit('date')">编辑/确定</el-button>
						</div>
					</label>
					<label class="info-label">
						<span>使用场景</span>
						<div class="info-input">
							<el-input :disabled='!infoEditable.useage' v-for="(item,index) in store.painter.info.useage"
								v-model="store.painter.info.useage[index]" style="width: 70%;margin-top: 10px;">
							</el-input>
							<div>
								<el-button :disabled='!infoEditable.useage' @click="addUseage()">添加</el-button>
								<el-button @click="edit('useage')">编辑/确定</el-button>
							</div>
						</div>
					</label>
				</div>
				<h3>当前基因</h3>
				<template v-if="store.painter">
					<ul class="gene-list">
						<li v-for="item in store.painter.genes" @click="setOneditShape(item)">{{item.gid}}</li>
					</ul>
				</template>
			</div>
		</template>
	</article>
	<article v-if="store.painter&&store.painter.onEditShape" class="edit-layer">
		<h3>图元基因定位
			<span class="confirm" title="复制">
				<i class="iconfont icon-copy" @click="store.painter.copy()"></i>
			</span>
			<span class="confirm" title="确定" @click="store.painter.confirm()">✔</span>
		</h3>
		<h4>坐标</h4>
		<label>X:<input v-model.number="store.painter.onEditShape.ctxStatus.translateX"  type="number"/></label>
		<div></div>
		<label>Y:<input v-model.number="store.painter.onEditShape.ctxStatus.translateY" type="number"/></label>
		<h4>缩放比</h4>
		<label>x-scale:<input v-model.number="store.painter.onEditShape.ctxStatus.scaleX" type="number" min="0"
				step="0.1" /></label>
		<div></div>
		<label>y-scale:<input v-model.number="store.painter.onEditShape.ctxStatus.scaleY" type="number" min="0"
				step="0.1" /></label>
		<h4>旋转</h4>
		<label>启用旋转盘<input type="checkbox" :value="true" v-model="store.painter.isShowTurnTable" /></label>
		<div></div>
		<label>旋转角:<input v-model.number="angle" @change="setAngle" type="number" /></label>
		<h4>单独对称模式</h4>
		<label>请选择：
			<select v-model="sysMode">
				<option>default</option>
				<option>none</option>
				<option>LeftRight</option>
				<option>UpDown</option>
				<option>Rectangle</option>
				<option>Triangle</option>
			</select>
		</label>
	</article>
</template>

<script setup>
	import {
		ref,
		reactive,
		watch,
		toRef,
		onMounted,
		computed,
	} from 'vue'
	import {
		useProfiledrawerStore
	} from '../store/profiledrawer.js'
	import {
		getLimitingByPage,
		getById
	} from '../api/PatternProfileApi.js'
	import {
		emitter
	} from '../bus/emitter.js'
	import {
		ElMessage
	} from 'element-plus'
	//自定义指令
	const vFocus = {
		mounted: (el) => el.focus()
	}

	var store = useProfiledrawerStore()
	//当前页码的基因
	var genes = ref([])
	//TODO 2023/2/26做一个缓存
	var total = ref(0)
	//总的基因
	var genesAll = []

	var page = ref(1);

	var infoEditable = reactive({
		profileId: false,
		nation: false,
		sign: false,
		date: false,
		useage: false
	})

	var loadGene = ref(false)

	function edit(key) {
		infoEditable[key] = infoEditable[key] ? false : true
	}

	function addUseage() {
		store.painter.info.useage.push('')
	}
	
	var currentPage = reactive({
		contentIndex: 0,
		contentList: ['geneList', 'geneInfo'],
		changeContent(direction) {
			if (direction == 'left') {
				if (this.contentIndex - 1 < 0) {
					this.contentIndex = this.contentList.length - 1
				} else {
					this.contentIndex--
				}
			} else if (direction == 'right') {
				if (this.contentIndex + 1 > this.contentList.length - 1) {
					this.contentIndex = 0
				} else {
					this.contentIndex++
				}
			}
		},
	})

	var currentContent = computed(() => {
		return currentPage.contentList[currentPage.contentIndex]
	})

	var currentContentChinese = computed(() => {
		if (currentPage.contentList[currentPage.contentIndex] == 'geneList') {
			return '基因库  '
		} else if (currentPage.contentList[currentPage.contentIndex] == 'geneInfo') {
			return '图案信息'
		}
	})

	watch(page, (newVal, v2) => {
		var params = {
			size: 10,
			page: newVal
		};
		getLimitingByPage(null, params).then((data) => {
			var obj = data.data
			total.value = obj.total
			genes.value = obj.records
		});
	}, {
		immediate: true
	});

	function switchTurnTableShow() {
		if (store.painter.isShowTurnTable) {
			store.painter.isShowTurnTable = false
		} else {
			store.painter.isShowTurnTable = true
		}
	}

	function chooseGCase(gid, index) {
		loadGene.value = true
		getById(gid).then((data) => {
			var obj = data.data
			var gid = obj.gid
			var caseJsonObj = obj.profiles[index]
			console.log(caseJsonObj)
			emitter.emit('prepared', {
				gid,
				caseJsonObj
			})
			loadGene.value = false
			ElMessage('完成加载-' + gid)
		}).catch(console)
	};


	var angle = computed({
		get() {
			if (store.painter && store.painter.onEditShape)
				return (store.painter.onEditShape.ctxStatus.rotate * 180 / Math.PI)
			else
				return 0
		},
		set(val) {
			store.painter.onEditShape.ctxStatus.rotate = val / 180 * Math.PI
		}
	});

	function countAve() {
		store.countAveFps(10000)
	}
	/*
		点击一个基因将其进入编辑状态
	*/
	function setOneditShape(item) {
		store.painter._lastOperateShape = item
		store.painter.onEditShape = item
	}

	/*
		鼠标悬浮在一个基因上时
	*/
	function mouseoverGene(item) {

	}


	var sysMode = computed({
		get() {
			if (store.painter && store.painter.onEditShape) {
				switch (store.painter.onEditShape.ctxStatus.sysMode) {
					case null:
						return 'default'
						break;
					case 0:
						return 'none'
						break;
					case 1:
						return 'LeftRight'
						break;
					case 2:
						return 'UpDown'
						break;
					case 3:
						return 'Rectangle'
						break;
					case 4:
						return 'Triangle'
						break;
					default:
						return 'default'
						break
				}
			}
		},
		set(val) {
			switch (val) {
				case 'default':
					store.painter.onEditShape.ctxStatus.sysMode = null
					break;
				case 'LeftRight':
					store.painter.onEditShape.ctxStatus.sysMode = 1
					break;
				case 'none':
					store.painter.onEditShape.ctxStatus.sysMode = 0
					break;
				case 'UpDown':
					store.painter.onEditShape.ctxStatus.sysMode = 2
					break;
				case 'Rectangle':
					store.painter.onEditShape.ctxStatus.sysMode = 3
					break;
				case 'Triangle':
					store.painter.onEditShape.ctxStatus.sysMode = 4
					break;
				default:
					store.painter.onEditShape.ctxStatus.sysMode = null
					break;
			}
		}
	})
</script>

<style scoped src='../assets/css/profileToolBar.less' lang="less">
</style>
