import Point from "./Point.js";

export default class ControlPoint extends Point {
	constructor(x, y, size, ctx) {
		super(x, y, size, ctx)
		this.fillStyle = '#5253aa'
		this.strokeStyle = '#5d62aa'
		this.size = size
	}
	draw() {
		this.ctx.save()
		this.$setStrokePath()
		// this.ctx.beginPath()
		// this.ctx.rect(this.x - this.size / 2, this.y - this.size / 2, this.size-1, this.size-1)
		this.ctx.fillStyle = this.fillStyle
		this.ctx.strokeStyle = this.strokeStyle
		this.ctx.lineWidth = 1
		this.ctx.fill(this.strokePath)
		this.ctx.stroke(this.strokePath)
		this.ctx.restore()
	}

	set x(val) {
		this._x = val
		if (this.fatherShape) {
			this.fatherShape.$afterControlPointChangeX(this.x, val)
		}
		if (this.bindShape) {
			this.bindShape[this.bindPropX] = val
		}
		if(this.bindFn){
			this.bindFn(val,this.y)
		}
	}

	get x() {
		return this._x
	}

	set y(val) {
		this._y = val
		if (this.fatherShape) {
			this.fatherShape.$afterControlPointChangeY(this.x, val)
		}
		if (this.bindShape) {
			this.bindShape[this.bindPropY] = val
		}
		if(this.bindFn){
			this.bindFn(this.x,val)
		}
	}

	get y() {
		return this._y
	}

	//将控制点与要控制的图案对象进行绑定
	bind(shape, p1x, p1y, callback) {
		if (arguments.length < 3) {
			throw new Error('绑定函数必须传入3参数')
		} else {
			if (arguments.length == 3) {
				if(!shape)
				{
					throw new Error('can not find shape to bind')
				}
				this.bindShape = shape
				this.bindPropX = p1x
				this.bindPropY = p1y
			}
			if(arguments.length==4){
				this.bindFn=callback
			}
		}

	}

	onMouseIn__($event) {
		this.ctx.canvas.style.cursor = 'move'
	}
	onMouseOut__($event) {
		this.ctx.canvas.style.cursor = 'default'
	}
}
