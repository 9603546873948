import $Event from "./$Event.js";
import $Math, {
	Contour
} from "./$Math.js";
import BoundryPoint from "./BoundryPoint.js";
import CtxStatus from "./CtxStatus.js";
import Shape from "./Shape.js";

//'$'开头的方法为子类需要重写的方法(如提供了默认方法可选择性重写)，也可能是重写了父类的方法
//‘__xxx__()’格式的方法为对象内部调用的方法，外部不允许调用
//不以特殊符号开头的方法是公开方法
export default class CShape extends Shape {
	constructor(...args) {
		var ctx = null
		//console.log(args[args.length - 1] instanceof CanvasRenderingContext2D)
		if (!args) {
			throw new Error('必须给父类的构造函数传入 arguments对象')
		} else {
			//判断最后一个元素是不是画笔对象
			if (args[args.length - 1] instanceof CanvasRenderingContext2D) {
				ctx = args[args.length - 1]
			}
		}

		super(ctx)
		//判断点击了哪个物体，要优先判断是不是控制点
		//外层对象不允许直接操作控制点对象，向外暴露的只有普通属性
		this.controlPoints = []
		this.boundryPoints = []
		this.childShapes = []
		//可选的旋转中心，不用绘制
		this._centerPoint = {
			x: 0,
			y: 0,
		}

		this.ctxStatus = new CtxStatus(1, 1, 0, 0, 0, null)
		this.coordinateSystemColor='rgba(96,175,255,0.5)'
		//对外缩放平移旋转接口结束
		this.$getControlPoints(...args)
		this.$getChildShape(...args)
		this.$getBoundry(...args)
		this.__getCenterPoints__()
		this.__setFatherShape__()
		this.__setChildCtx__()

		//图像内部的默认事件赋值
		for (let i = 0; i < this.controlPoints.length; i++) {
			this.controlPoints[i].onMousedown__ = function($event) {

				//console.log(father.controlPoints[i])
				var son = this
				var father = this.fatherShape.fatherShape
				//son在vue中是一个代理对象
				//console.log(2,son)这两个对象不一样
				var defaultMoveFn = father.onMousemove__
				var defaultUpFn = father.onMouseup__
				this.fatherShape.fatherShape.onMousemove__ = function($event) {
					var point = son.fatherShape.toOffsetXY($event.clientX, $event.clientY)
					son.x = point.x
					son.y = point.y
				}
				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = defaultMoveFn
					this.onMouseup__ = defaultUpFn
				}
				this.onMouseup__ = function($event) {
					this.fatherShape.fatherShape.onMousemove__ = defaultMoveFn
					this.fatherShape.fatherShape.onMouseup__ = defaultUpFn
				}
				$event.stopPropagation()
			}
		}

		if (this.boundryPoints.length > 0) {
			
			//左上角的边界点默认事件
			this.boundryPoints[0].onMousedown__ = function($event) {
				$event.stopPropagation()
				var [brother0, brother1, brother2, brother3] = this.fatherShape.boundryPoints
				var mousedownX = $event.clientX / this.fatherShape.fatherShape.ctxStatus.scaleX
				var mousedownY = $event.clientY / this.fatherShape.fatherShape.ctxStatus.scaleY
				var shapeWidth = brother1.x - brother0.x
				var shapeHeight = brother2.y - brother0.y
				var maxXControlPoint = $Math.getMaxXControlPoint(this.fatherShape.controlPoints)
				var maxYControlPoint = $Math.getMaxYControlPoint(this.fatherShape.controlPoints)
				if (maxXControlPoint == maxYControlPoint) {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != maxXControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}

					this.fatherShape.fatherShape.onMousemove__ = function($event) {
						//console.log($event)
						let scaleX = (1 - ($event.clientX / this.ctxStatus.scaleX - mousedownX) /
							shapeWidth)
						let scaleY = (1 - ($event.clientY / this.ctxStatus.scaleY - mousedownY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = maxXControlPoint.x - (maxXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = maxXControlPoint.y - (maxXControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
					}
				} else {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					let tempY = maxXControlPoint.y
					let tempX = maxYControlPoint.x
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != maxXControlPoint && item != maxYControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}
					this.fatherShape.fatherShape.onMousemove__ = function($event) {

						let scaleX = (1 - ($event.clientX / this.ctxStatus.scaleX - mousedownX) /
							shapeWidth)
						let scaleY = (1 - ($event.clientY / this.ctxStatus.scaleY - mousedownY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = maxXControlPoint.x - (maxXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = maxYControlPoint.y - (maxYControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
						maxXControlPoint.y = (maxYControlPoint.y - ((maxYControlPoint.y - tempY) * scaleY))
						maxYControlPoint.x = (maxXControlPoint.x - ((maxXControlPoint.x - tempX) * scaleX))
						//$event.stopPropagation()
					}
				}

				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = null
				}
			}
			
			//右上角的边界点默认事件
			this.boundryPoints[1].onMousedown__ = function($event) {
				$event.stopPropagation()
				var [brother0, brother1, brother2, brother3] = this.fatherShape.boundryPoints
				var mousedownX = $event.clientX / this.fatherShape.fatherShape.ctxStatus.scaleX
				var mousedownY = $event.clientY / this.fatherShape.fatherShape.ctxStatus.scaleY
				var shapeWidth = brother1.x - brother0.x
				var shapeHeight = brother2.y - brother0.y
				var minXControlPoint = $Math.getMinXControlPoint(this.fatherShape.controlPoints)
				var maxYControlPoint = $Math.getMaxYControlPoint(this.fatherShape.controlPoints)
				if (minXControlPoint == maxYControlPoint) {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != minXControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}
			
					this.fatherShape.fatherShape.onMousemove__ = function($event) {
						//console.log($event)
						let scaleX = (1 - (mousedownX - $event.clientX / this.ctxStatus.scaleX) /
							shapeWidth)
						let scaleY = (1 - ($event.clientY / this.ctxStatus.scaleY - mousedownY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = minXControlPoint.x - (minXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = minXControlPoint.y - (minXControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
					}
				} else {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					let tempY = minXControlPoint.y
					let tempX = maxYControlPoint.x
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != minXControlPoint && item != maxYControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}
					this.fatherShape.fatherShape.onMousemove__ = function($event) {
			
						let scaleX = (1 - (mousedownX - $event.clientX / this.ctxStatus.scaleX) /
							shapeWidth)
						let scaleY = (1 - ($event.clientY / this.ctxStatus.scaleY - mousedownY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = minXControlPoint.x - (minXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = maxYControlPoint.y - (maxYControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
						minXControlPoint.y = (maxYControlPoint.y - ((maxYControlPoint.y - tempY) * scaleY))
						maxYControlPoint.x = (minXControlPoint.x - ((minXControlPoint.x - tempX) * scaleX))
						//$event.stopPropagation()
					}
				}
			
				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = null
				}
			}
			
			this.boundryPoints[2].onMousedown__ = function($event) {
				$event.stopPropagation()
				var [brother0, brother1, brother2, brother3] = this.fatherShape.boundryPoints
				var mousedownX = $event.clientX / this.fatherShape.fatherShape.ctxStatus.scaleX
				var mousedownY = $event.clientY / this.fatherShape.fatherShape.ctxStatus.scaleY
				var shapeWidth = brother1.x - brother0.x
				var shapeHeight = brother2.y - brother0.y
				var minXControlPoint = $Math.getMinXControlPoint(this.fatherShape.controlPoints)
				var minYControlPoint = $Math.getMinYControlPoint(this.fatherShape.controlPoints)
				if (minXControlPoint == minYControlPoint) {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != minXControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}
			
					this.fatherShape.fatherShape.onMousemove__ = function($event) {
						//console.log($event)
						let scaleX = (1 - (mousedownX - $event.clientX / this.ctxStatus.scaleX) /
							shapeWidth)
						let scaleY = (1 - (mousedownY - $event.clientY / this.ctxStatus.scaleY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = minXControlPoint.x - (minXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = minXControlPoint.y - (minXControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
					}
				} else {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					let tempY = minXControlPoint.y
					let tempX = minYControlPoint.x
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != minXControlPoint && item != minYControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}
					this.fatherShape.fatherShape.onMousemove__ = function($event) {
			
						let scaleX = (1 - (mousedownX - $event.clientX / this.ctxStatus.scaleX) /
							shapeWidth)
						let scaleY = (1 - (mousedownY - $event.clientY / this.ctxStatus.scaleY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = minXControlPoint.x - (minXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = minYControlPoint.y - (minYControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
						minXControlPoint.y = (minYControlPoint.y - ((minYControlPoint.y - tempY) * scaleY))
						minYControlPoint.x = (minXControlPoint.x - ((minXControlPoint.x - tempX) * scaleX))
						//$event.stopPropagation()
					}
				}
			
				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = null
				}
			}
			
			//左下角的边界点事件
			this.boundryPoints[3].onMousedown__ = function($event) {
				$event.stopPropagation()
				var [brother0, brother1, brother2, brother3] = this.fatherShape.boundryPoints
				var mousedownX = $event.clientX / this.fatherShape.fatherShape.ctxStatus.scaleX
				var mousedownY = $event.clientY / this.fatherShape.fatherShape.ctxStatus.scaleY
				var shapeWidth = brother1.x - brother0.x
				var shapeHeight = brother2.y - brother0.y
				var maxXControlPoint = $Math.getMaxXControlPoint(this.fatherShape.controlPoints)
				var minYControlPoint = $Math.getMinYControlPoint(this.fatherShape.controlPoints)
				if (maxXControlPoint == minYControlPoint) {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != maxXControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}
			
					this.fatherShape.fatherShape.onMousemove__ = function($event) {
						//console.log($event)
						let scaleX = (1 - ($event.clientX / this.ctxStatus.scaleX - mousedownX) /
							shapeWidth)
						let scaleY = (1 - (mousedownY - $event.clientY / this.ctxStatus.scaleY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = maxXControlPoint.x - (maxXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = maxXControlPoint.y - (maxXControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
					}
				} else {
					var needToMovePoints = []
					var needToMovePointsDataCopy = []
					let tempY = maxXControlPoint.y
					let tempX = minYControlPoint.x
					for (let i = 0; i < this.fatherShape.controlPoints.length; i++) {
						let item = this.fatherShape.controlPoints[i]
						if (item != maxXControlPoint && item != minYControlPoint) {
							needToMovePoints.push(item)
							needToMovePointsDataCopy.push({
								x: item.x,
								y: item.y
							})
						}
					}
					this.fatherShape.fatherShape.onMousemove__ = function($event) {
			
						let scaleX = (1 - ($event.clientX / this.ctxStatus.scaleX - mousedownX) /
							shapeWidth)
						let scaleY = (1 - (mousedownY - $event.clientY / this.ctxStatus.scaleY) /
							shapeHeight)
						for (let i = 0; i < needToMovePoints.length; i++) {
							let item = needToMovePoints[i]
							item.x = maxXControlPoint.x - (maxXControlPoint.x - needToMovePointsDataCopy[i]
								.x) * scaleX
							item.y = minYControlPoint.y - (minYControlPoint.y - needToMovePointsDataCopy[i]
								.y) * scaleY
						}
						maxXControlPoint.y = (minYControlPoint.y - ((minYControlPoint.y - tempY) * scaleY))
						minYControlPoint.x = (maxXControlPoint.x - ((maxXControlPoint.x - tempX) * scaleX))
						//$event.stopPropagation()
					}
				}
			
				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = null
				}
			}
		}

	}
	//传入符合图形在每帧动画开头时的场景
	draw(imgData) {
		this.ctx.save()
		this.__transform__()
		this.$drawControlPoints()
		this.$drawChildShape()
		//this.showCoordinateSystem()
		this.$drawBoundry()
		this.ctx.restore()
	}
	/**
	 * @param p {ControlPoint} 绕p点旋转
	 **/
	rotate(pIndex, angle) {
		var deg = angle * Math.PI / 180
		var p = this.controlPoints[pIndex]
		var {
			x,
			y
		} = p
		this.controlPoints.forEach((item) => {
			var temp = $Math.revolvePoint(x, y, item.x, item.y, deg)
			item.x = temp.x
			item.y = temp.y
		})
	}

	//此方法用来得到内部图形，要重写,参数和构造函数中的参数相同
	//必须向列表中push你要绘制的图形对象，下面两个方法也一样
	$getChildShape() {
		//override
	}

	$getControlPoints() {
		//override
	}

	//override
	$rules() {
		//default
		if (this.boundryPoints.length == 4) {
			this.boundryPoints[0].x = $Math.minX(this.controlPoints) - 10
			this.boundryPoints[0].y = $Math.minY(this.controlPoints) - 10
			this.boundryPoints[1].x = $Math.maxX(this.controlPoints) + 10
			this.boundryPoints[1].y = this.boundryPoints[0].y
			this.boundryPoints[2].x = this.boundryPoints[1].x
			this.boundryPoints[2].y = $Math.maxY(this.controlPoints) + 10
			this.boundryPoints[3].x = this.boundryPoints[0].x
			this.boundryPoints[3].y = this.boundryPoints[2].y
		}
	}
	//默认根据控制点在this上挂载边界的四个顶点为边界点
	//如果要重写，必须按绘图顺序往list中push元素
	$getBoundry() {
		//default
		//1.创建数组，按顺时针画矩形，所以点必须顺时针放入，要规定点的个数
		this.boundryPoints = new Array(4)

		for (let i = 0; i < this.boundryPoints.length; i++) {
			this.boundryPoints[i] = new BoundryPoint(0, 0, 6, this.ctx)
			this.boundryPoints[i].onMouseIn__ = function($event) {
				switch (i) {
					case 0:
						this.ctx.canvas.style.cursor = 'nw-resize'
						break
					case 1:
						this.ctx.canvas.style.cursor = 'sw-resize'
						break
					case 2:
						this.ctx.canvas.style.cursor = 'nw-resize'
						break
					case 3:
						this.ctx.canvas.style.cursor = 'sw-resize'
						break
				}
			}
			this.boundryPoints[i].onMouseOut__ = function($event) {
				this.ctx.canvas.style.cursor = 'default'
			}
		}
		//2.调用重写的函数
		this.$rules()
		//3.可以重写边框路径,此属性为绘制在画板上的边框路径
		this.boundryPath =
			function() {
				this.ctx.beginPath()
				if (this.boundryPoints.length == 4)
					this.ctx.rect(this.boundryPoints[0].x, this.boundryPoints[0].y, (this.boundryPoints[2].x - this
						.boundryPoints[0].x), (this.boundryPoints[2].y - this.boundryPoints[0].y))
			}
		//如果没有重写实际边框路径，重写实际边框路径，此路径为图形实际的边界
		if (!this.actualBoundryPath) {
			this.actualBoundryPath = function() {
				if (this.boundryPoints.length == 4)
					this.ctx.rect(this.boundryPoints[0].x - 5, this.boundryPoints[0].y - 5, (this.boundryPoints[
							2]
						.x - this.boundryPoints[0].x) + 10, (this.boundryPoints[2].y - this.boundryPoints[0]
						.y) + 10)
			}
		}

	}

	/*
	 *进入旋转模式，显示旋转盘
	 */
	doRotateMode() {

	}

	//中心点直接依赖边界点,为一个图形的形心
	__getCenterPoints__() {
		var con = new Contour(this.boundryPoints)
		this._centerPoint = con.centroid()
	}

	//绘制边界
	$drawBoundry() {
		//default
		this.ctx.save()
		this.ctx.strokeStyle = 'grey'
		this.ctx.setLineDash([7, 4])
		this.ctx.lineWidth=1
		this.boundryPath()
		this.ctx.stroke()
		this.ctx.restore()
		this.ctx.save()
		for (let i = 0; i < this.boundryPoints.length; i++) {
			this.boundryPoints[i].draw()
		}
		this.ctx.restore()
	}

	$drawChildShape() {
		//default
		for (let i = 0; i < this.childShapes.length; i++) {
			//this.ctx.lineWidth = this.childShapes[i].strokeLineWidth
			this.childShapes[i].draw()
		}
		//this.ctx.lineWidth = this.strokeLineWidth
	}

	$drawControlPoints() {
		//default
		for (let i = 0; i < this.controlPoints.length; i++) {
			this.controlPoints[i].draw()
		}
	}

	$isPointInPath(x, y) {
		var flag = false
		this.ctx.save()
		this.__transform__()
		this.ctx.beginPath()
		this.actualBoundryPath()
		flag = this.ctx.isPointInPath(x, y)
		this.ctx.restore()
		return flag
	}

	__setFatherShape__() {
		this.controlPoints.forEach((e) => {
			e.fatherShape = this
		})
		this.boundryPoints.forEach((e) => {
			e.fatherShape = this
		})
		this.childShapes.forEach((e) => {
			e.fatherShape = this
		})
	}

	__setChildCtx__() {
		this.controlPoints.forEach((e) => {
			e.ctx = this.ctx
		})
		this.boundryPoints.forEach((e) => {
			e.ctx = this.ctx
		})
		this.childShapes.forEach((e) => {
			e.ctx = this.ctx
		})
	}


	__transform__() {
		//this.ctx.save()
		this.ctx.setTransform(this.ctxStatus.matrix)
	}
	__confirmPut__() {
		//在确定放置的时候要把真实的坐标计算出来并重绘
	}

	//重写了父类的方法
	$mousedown($event) {
		this.$eventTarget = this
		this.ctx.save()
		this.__transform__()
		for (let i = 0; i < this.controlPoints.length; i++) {
			let item = this.controlPoints[i]
			if (item.mouseIn) {
				this.$eventTarget = item
				//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
				this.$eventTarget.$mousedown($event)
				if ($event.Propagation) {
					this.onMousedown__($event)
				}
				this.$afterControlPointMousedown($event)
				this.ctx.restore()
				this.$eventTarget = this
				return
			}
		}
		if (this.$eventTarget == this) {
			for (let i = 0; i < this.childShapes.length; i++) {
				let item = this.childShapes[i]
				if (item.mouseIn) {
					this.$eventTarget = item
					//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
					this.$eventTarget.$mousedown($event)
					if ($event.Propagation) {
						this.onMousedown__($event)
					}
					this.$afterChildShapesMousedown($event)
					this.ctx.restore()
					this.$eventTarget = this
					return
				}
			}
		}
		if (this.$eventTarget == this) {
			for (let i = 0; i < this.boundryPoints.length; i++) {
				let item = this.boundryPoints[i]
				if (item.mouseIn) {
					this.$eventTarget = item
					//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
					this.$eventTarget.$mousedown($event)
					if ($event.Propagation) {
						this.onMousedown__($event)
					}
					this.$afterBoundryPointMousedown($event)
					this.ctx.restore()
					this.$eventTarget = this
					return
				}
			}
		}
		if (this.$eventTarget == this) {
			//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
			//对比父元素的转换矩阵，如果转换矩阵相同则不更新
			if ($event._useOffsetXY && !$Math.matrixEqual(this.ctxStatus.matrix, this.fatherShape.ctxStatus
				.matrix)) {
				$event.update(this, this.ctxStatus)
			}
			this.onMousedown__($event)
		}
		this.ctx.restore()
	}

	onMousedown__($event) {

	}
	//用来判断鼠标在哪个元素内部
	$mousemove($event) {
		this.$eventTarget = this
		this.ctx.save()
		this.__transform__()
		for (let i = 0; i < this.controlPoints.length; i++) {
			let item = this.controlPoints[i]
			if (item.$isPointInPath($event.clientX, $event.clientY)) {
				if (!item.mouseIn) {
					item.mouseIn = true
					item.onMouseIn__($event)
				}
				this.$eventTarget = item
				this.$eventTarget.$mousemove($event)
				if ($event.Propagation && this.onMousemove__)
					this.onMousemove__($event)
				this.$afterControlPointMousemove($event)
				this.ctx.restore()
				this.$eventTarget = this
				return
			} else {
				if (item.mouseIn) {
					item.mouseIn = false
					item.onMouseOut__($event)
				}
			}
		}
		if (this.$eventTarget == this) {
			//优化，拒绝计算子图形的鼠标移动事件，因为一个基因可能很复杂
			if (this.useChildShapesMouseMove) {
				for (let i = 0; i < this.childShapes.length; i++) {
					let item = this.childShapes[i]
					if (item.$isPointInPath($event.clientX, $event.clientY)) {
						if (!item.mouseIn) {
							item.mouseIn = true
							item.onMouseIn__($event)
						}
						this.$eventTarget = item
						//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
						this.$eventTarget.$mousemove($event)
						if ($event.Propagation && this.onMousemove__)
							this.onMousemove__($event)
						this.$afterControlPointMousemove($event)
						this.ctx.restore()
						this.$eventTarget = this
						return
					} else {
						if (item.mouseIn) {
							item.mouseIn = false
							item.onMouseOut__($event)
						}
					}
				}
			}
		}
		if (this.$eventTarget == this) {
			for (let i = 0; i < this.boundryPoints.length; i++) {
				let item = this.boundryPoints[i]
				if (item.$isPointInPath($event.clientX, $event.clientY)) {
					if (!item.mouseIn) {
						item.mouseIn = true
						item.onMouseIn__($event)
					}
					this.$eventTarget = item
					//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
					this.$eventTarget.$mousemove($event)
					if ($event.Propagation && this.onMousemove__)
						this.onMousemove__($event)
					this.$afterControlPointMousemove($event)
					this.ctx.restore()
					this.$eventTarget = this
					return
				} else {
					if (item.mouseIn) {
						item.mouseIn = false
						item.onMouseOut__($event)
					}
				}
			}
		}
		if (this.$eventTarget == this) {
			//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
			if ($event._useOffsetXY && !$Math.matrixEqual(this.ctxStatus.matrix, this.fatherShape.ctxStatus.matrix)) {
				$event.update(this, this.ctxStatus)
			}
			if (this.onMousemove__) {
				this.onMousemove__($event)
			}
		}
		this.ctx.restore()
	}

	//鼠标在某个元素内部移动时触发的事件
	onMousemove__($event) {

	}

	$click(event) {

	}

	$mouseup($event) {
		this.$eventTarget = this
		this.ctx.save()
		this.__transform__()
		//this.ctx.lineWidth = (this.ctx.lineWidth / this.scale.x)
		for (let i = 0; i < this.controlPoints.length; i++) {
			let item = this.controlPoints[i]
			if (item.mouseIn) {
				this.$eventTarget = item
				//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)

				this.$eventTarget.$mouseup($event)
				if ($event.Propagation && this.onMouseup__)
					this.onMouseup__($event)
				this.$afterControlPointMouseup($event)
				this.ctx.restore()
				this.$eventTarget = this
				return
			}
		}
		if (this.$eventTarget == this) {
			for (let i = 0; i < this.childShapes.length; i++) {
				let item = this.childShapes[i]
				if (item.mouseIn) {
					this.$eventTarget = item
					//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)

					this.$eventTarget.$mouseup($event)
					if ($event.Propagation && this.onMouseup__)
						this.onMouseup__($event)
					this.$afterControlPointMouseup($event)
					this.ctx.restore()
					this.$eventTarget = this
					return
				}
			}
		}
		if (this.$eventTarget == this) {
			for (let i = 0; i < this.boundryPoints.length; i++) {
				let item = this.boundryPoints[i]
				if (item.mouseIn) {
					this.$eventTarget = item
					//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)

					this.$eventTarget.$mouseup($event)
					if ($event.Propagation && this.onMouseup__)
						this.onMouseup__($event)
					this.$afterControlPointMouseup($event)
					this.ctx.restore()
					this.$eventTarget = this
					return
				}
			}
		}
		if (this.$eventTarget == this) {
			//var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
			if ($event._useOffsetXY && !$Math.matrixEqual(this.ctxStatus.matrix, this.fatherShape.ctxStatus.matrix)) {
				$event.update(this, this.ctxStatus)
			}
			if (this.onMouseup__) {
				this.onMouseup__($event)
			}

		}
		this.ctx.restore()
	}

	onMouseup__($event) {

	}

	$afterControlPointMousedown($event) {
		//override
		//console.log('ControlPoint has mousedown, do something of fathers\'')
	}

	$afterBoundryPointMousedown($event) {
		//console.log('BoundryPoint has mousedown, do something of fathers\'')
	}

	$afterChildShapesMousedown($event) {
		//console.log('ChildShapes has mousedown, do something of fathers\'')
	}

	$afterControlPointMousemove($event) {
		//override
		//console.log('ControlPoint has mousemove, do something of fathers\'')
	}

	$afterBoundryPointMousemove($event) {
		//console.log('BoundryPoint has mousemove, do something of fathers\'')
	}

	$afterChildShapesMousemove($event) {
		//console.log('ChildShapes has mousemove, do something of fathers\'')
	}

	$afterControlPointMouseup($event) {
		//override
		//console.log('ControlPoint has mousedown, do something of fathers\'')
	}

	$afterBoundryPointMouseup($event) {
		//console.log('BoundryPoint has mousedown, do something of fathers\'')
	}

	$afterChildShapesMouseup($event) {
		//console.log('ChildShapes has mousedown, do something of fathers\'')
	}

	//控制点x坐标变化后的回调
	$afterControlPointChangeX(oldVal, newVal) {
		this.$rules()
	}
	//控制点y坐标变化后的回调
	$afterControlPointChangeY(oldVal, newVal) {
		this.$rules()
	}

	onDbclick__($event) {

	}
	/**
	 * 将一个坐标值转化为当前图形父容器坐标系下的坐标
	 **/
	toFatherShapeXY(x, y) {
		var point = new DOMPoint(x, y);
		var {
			scaleX,
			scaleY,
			translateX,
			translateY,
			rotate
		} = this.ctxStatus;
		return point.matrixTransform(new DOMMatrix([
			Math.cos(rotate) * scaleX,
			Math.sin(rotate) * scaleX,
			-Math.sin(rotate) * scaleY,
			Math.cos(rotate) * scaleY,
			translateX,
			translateY
		]))
	}
	/**
	 * 将一个坐标值转化为canvas组件坐标系下的坐标(event.offsetX,event.offsetY)
	 **/
	toClientXY(x, y) {
		var point = new DOMPoint(x, y)
		return point.matrixTransform(this.ctxStatus.matrix)
	}

	/**
	 * 将某个坐标转化为当前坐标系下的坐标
	 **/
	toOffsetXY(x, y) {
		var point = new DOMPoint(x, y)
		return point.matrixTransform(this.ctxStatus.invertMatrix)
	}
	
	/**显示局部坐标系**/
	showCoordinateSystem(){
		this.ctx.beginPath()
		var sizeX=this.boundryPoints[2].x
		var sizeY=this.boundryPoints[2].y
		this.ctx.moveTo(0,sizeY/2)
		this.ctx.lineTo(sizeX,sizeY/2)
		this.ctx.moveTo(sizeX/2,0)
		this.ctx.lineTo(sizeX/2,sizeY)
		this.ctx.moveTo(sizeX/2,sizeY)
		this.ctx.lineTo(sizeX/2+4,sizeY-4)
		this.ctx.moveTo(sizeX,sizeY/2)
		this.ctx.lineTo(sizeX-4,sizeY/2+4)
		this.ctx.strokeStyle=this.coordinateSystemColor
		this.ctx.stroke()
	}
	
	set controlPointsSize(val){
		this.controlPoints.forEach((item)=>{
			item.size=val
		})
	}
	get controlPointsSize(){
		return this.controlPoints[0].size
	}
	set controlPointsBorderColor(val){
		this.controlPoints.forEach((item)=>{
			item.strokeStyle=val
		})
	}
	get controlPointsBorderColor(){
		return this.controlPoints[0].strokeStyle
	}
	set controlPointsFillColor(val){
		this.controlPoints.forEach((item)=>{
			item.fillStyle=val
		})
	}
	get controlPointsFillColor(){
		return this.controlPoints[0].fillStyle
	}
}
