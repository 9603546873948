<template>
    <div class="container">
        <iframe src="https://segment-anything.com/demo">
        </iframe>
    </div>
</template>
<style scoped>
.container{
    height: 100%;
}
iframe{
    height: 100%;
    width:100%;
}
</style>