import {
	defineStore
} from 'pinia'
import GenePainter from '../assets/js/PainterDemo-v1.3/Painter/GenePainter.js'

export const useGenedrawerStore = defineStore('genedrawer', {
	state: () => ({
		gid:'',
		painter:null,
		info: {
			date: "1800",
			sign: "未定义",
			usage: [],
			nation: "未定义"
		},
		geneJsonObj:{}
	}),
	actions:{
		destory(){
			if(this.painter&&this.painter.destory)
			{
				this.painter.destory()
			}
			this.painter=null
		},
		/**
		 * 此方法初始化genePainter
		 * @param {Object} case的json对象
		 **/
		createPainter(caseJsonObj,ctx){
			this.destory()
			this.painter = new GenePainter(caseJsonObj, ctx)
			this.painter.ctxStatus.translateX = 300
			this.painter.ctxStatus.translateY = 300
			this.painter.ctxStatus.scaleX = 2
			this.painter.ctxStatus.scaleY = 2
			this.painter.draw()
		},
		downloadFile() {
			if (this.painter instanceof GenePainter) {
				//这个方法有点问题，他会直接根据画板当前的元素产生一个svg而非已保存的元素
				var res = this.painter.transFormIntoSvg()
				var arr=[res]
				var svgBlob=new Blob(arr,{type:'image/svg+xml'})
				var downloadSrc=URL.createObjectURL(svgBlob)
				var a=document.createElement('a')
				a.setAttribute('href',downloadSrc)
				a.setAttribute('download','')
				a.download=`${this.painter.profileId}.svg`
				a.click()
				this.message = 'ok' + Date.now()
			}else{
				this.message = '当前没有打开项目' + Date.now()
			}

		},
	}
})
