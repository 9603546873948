import BoundryPoint from "./BoundryPoint.js"

export default function $Math() {

}

$Math.maxX = function(arr) {
	var max = -999999
	arr.forEach((item) => {
		if (item.x > max)
			max = item.x
	})
	return max
}

$Math.minX = function(arr) {
	var min = 999999
	arr.forEach((item) => {
		if (item.x < min)
			min = item.x
	})
	return min
}

$Math.maxY = function(arr) {
	var max = -999999
	arr.forEach((item) => {
		if (item.y > max)
			max = item.y
	})
	return max
}

$Math.minY = function(arr) {
	var min = 999999
	arr.forEach((item) => {
		if (item.y < min)
			min = item.y
	})
	return min
}

$Math.getBoundryPoints = function(arr) {
	var b1x = 999999
	var b1y = 999999
	var b2x = 0
	var b2y = 999999
	var b3x = 0
	var b3y = 0
	var b4x = 999999
	var b4y = 0
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].x < b1x) {
			b1x = arr[i].x - 10
		}
		if (arr[i].y < b1y) {
			b1y = arr[i].y - 10
		}
		if (arr[i].x > b3x) {
			b3x = arr[i].x + 10
		}
		if (arr[i].y > b3y) {
			b3y = arr[i].y + 10
		}
	}
	b2x = b3x
	b2y = b1y
	b4x = b1x
	b4y = b3y
	return [
		new BoundryPoint(b1x, b1y, 6),
		new BoundryPoint(b2x, b2y, 6),
		new BoundryPoint(b3x, b3y, 6),
		new BoundryPoint(b4x, b4y, 6)
	]
}

$Math.getMaxXControlPoint = function(arr) {
	var max = {
		x: -99999,
		y: -99999
	}
	arr.forEach((item) => {
		if (item.x > max.x)
			max = item
	})
	return max
}

$Math.getMaxYControlPoint = function(arr) {
	var max = {
		x: -99999,
		y: -99999
	}
	arr.forEach((item) => {
		if (item.y > max.y)
			max = item
	})
	return max
}

$Math.getMinXControlPoint = function(arr) {
	var min = {
		x: 99999,
		y: 99999,
	}
	arr.forEach((item) => {
		if (item.x < min.x)
			min = item
	})
	return min
}

$Math.getMinYControlPoint = function(arr) {
	var min = {
		x: 99999,
		y: 99999,
	}
	arr.forEach((item) => {
		if (item.y < min.y)
			min = item
	})
	return min
}


$Math.matrixEqual = function(matrix1, matrix2) {
	if (matrix1.a == matrix2.a && matrix1.b == matrix2.b && matrix1.c == matrix2.c && matrix1.d == matrix2.d &&
		matrix1.e == matrix2.e && matrix1.f == matrix2.f) {
		return true
	} else {
		return false
	}
}

$Math.parseAngle = function(x1, y1, x2, y2) {
	let Zita = Math.atan((y1 - y2) / (x1 - x2));
	let pai = Math.PI;
	if (x2 > x1 && y2 >= y1) {
		return Zita;
	} else if (x2 == x1 && y2 > y1) {
		return 0.5 * pai;
	} else if (x2 < x1) {
		return pai + Zita;
	} else if (x2 == x1 && y2 < y1) {
		return 1.5 * pai;
	} else if (x2 > x1 && y2 < y1) {
		return 2 * pai + Zita;
	} else {
		console.log("文件错误!!!");
		return 0;
	}
}

$Math.canvasArcToSvgPath = function(x, y, radius, startAngle, endAngle, anticlockwise) {
	// 将角度归一化

	if (startAngle < 0) {
		while (startAngle < 0) {
			startAngle += 2 * Math.PI
		}
	}

	if (endAngle < 0) {
		while (endAngle < 0) {
			endAngle += 2 * Math.PI
		}
	}

	if (startAngle > 2 * Math.PI) {
		while (startAngle > 2 * Math.PI) {
			startAngle -= 2 * Math.PI
		}
	}

	if (endAngle > 2 * Math.PI) {
		while (endAngle > 2 * Math.PI) {
			endAngle -= 2 * Math.PI
		}
	}

	if (endAngle < startAngle) {
		while (endAngle < startAngle) {
			endAngle += 2 * Math.PI
		}
	}

	// 计算圆弧的中心点坐标
	var ex = x + radius * Math.cos(endAngle);
	var ey = y + radius * Math.sin(endAngle);

	var sx = x + radius * Math.cos(startAngle);
	var sy = y + radius * Math.sin(startAngle);

	// 计算圆弧的半径
	var arcRadiusX = radius;
	var arcRadiusY = radius;

	if (Math.abs(endAngle - startAngle) > Math.PI) {
		var largeArcFlag = 1;
	} else {
		var largeArcFlag = 0;
	}

	var sweepFlag = anticlockwise ? 0 : 1;

	// 生成 SVG path 中的圆弧参数
	var path = "M " + sx + " " + sy + " " + "A " + arcRadiusX + " " + arcRadiusY + " 0 " + largeArcFlag + " " +
		sweepFlag + " " + ex + " " + ey;

	return path;
}

function getMinXControlPoint(arr) {
	var min = {
		x: 999999,
		y: 999999
	}
	arr.forEach((item) => {
		if (item.y < min.y)
			min = item
	})
	return min
}

function getMinYControlPoint(arr) {
	var min = {
		x: 999999,
		y: 999999
	}
	arr.forEach((item) => {
		if (item.x < min.x)
			min = item
	})
	return min
}

function Point(x, y) {
	this.x = x;
	this.y = y;
}

// Contour object
export function Contour(points) {
	this.pts = points || []; // an array of Point objects defining the contour
}


Contour.prototype.area = function() {
	var area = 0;
	var pts = this.pts;
	var nPts = pts.length;
	var j = nPts - 1;
	var p1;
	var p2;

	for (var i = 0; i < nPts; j = i++) {
		p1 = pts[i];
		p2 = pts[j];
		area += p1.x * p2.y;
		area -= p1.y * p2.x;
	}
	area /= 2;

	return area;
};

Contour.prototype.centroid = function() {
	var pts = this.pts;
	var nPts = pts.length;
	var x = 0;
	var y = 0;
	var f;
	var j = nPts - 1;
	var p1;
	var p2;

	for (var i = 0; i < nPts; j = i++) {
		p1 = pts[i];
		p2 = pts[j];
		f = p1.x * p2.y - p2.x * p1.y;
		x += (p1.x + p2.x) * f;
		y += (p1.y + p2.y) * f;
	}

	f = this.area() * 6;

	return new Point(x / f, y / f);
};


/**
 * 一个点x2,y2或向量绕起始点x1,y1旋转deg后的新坐标或新向量
 * @param x1 旋转中心x坐标
 * @param y1 旋转中心y坐标
 * @param x2 需要旋转的点x坐标
 * @param y2 需要旋转的点y坐标
 * @param deg 旋转角（弧度表示）
 * @returns {object} 旋转后的坐标
 */
$Math.revolvePoint = function(x1, y1, x2, y2, deg) {
	let xx = (y2 - y1) * Math.sin(deg) + (x2 - x1) * Math.cos(deg)
	let yy = -(x2 - x1) * Math.sin(deg) + (y2 - y1) * Math.cos(deg)
	//debugger
	return {
		x: xx + x1,
		y: yy + y1
	}
}

/**
 * 画拉格朗日插值曲线
 * @returns {Path2D} 一个路径
 */
$Math.drawLg = function(pointArr, rad) {
	let x = [];
	let y = [];
	let y2 = [];
	pointArr.forEach(e => {
		x.push(e.x);
		y.push(e.y);
	})
	let min = x[0];
	let max = x[0];
	for (let i = 0; i < x.length; i++) {
		if (max < x[i]) {
			max = x[i];
		}
		if (min > x[i]) {
			min = x[i];
		}
	}
	//加入到预测的插值数组
	for (let i = min; i < max; i += 1) {
		//调用该模块中的拉格朗日插值算法lgsf，请用this
		y2.push(lgsf(i, x, y));
	}

	//设置路径
	var path = new Path2D()
	path.moveTo(min, y2[0])
	var rotatePoint = {
		x: min,
		y: y2[0]
	}

	//此处调整i和min可以减少曲线的精度
	for (let i = 0; i < y2.length; i += 1) {
		// console.log(y2[i])
		let temp = $Math.revolvePoint(rotatePoint.x, rotatePoint.y, min, y2[i], rad)

		path.lineTo(temp.x, temp.y)
		//this.ctx.lineTo(min, y2[i])
		min += 1;
	}
	return path
}
/**
 * 拉格朗日插值法
 * @param x1       待预测的x坐标
 * @param xArray   x坐标数组
 * @param yArray   y坐标数组
 * @returns {number}   预测的y坐标的值
 */
function lgsf(x1, xArray, yArray) {
	//基函数
	let p = [];
	let L_n = 0;
	for (let i = 0; i < xArray.length; i++) {
		//分子  分母
		let fz = 1;
		let fm = 1;
		for (let j = 0; j < xArray.length; j++) {
			if (j !== i) {
				fz *= (x1 - xArray[j])
				fm *= (xArray[i] - xArray[j])
			}
		}
		let pe = fz / fm;
		p.push(pe);
	}
	//多项式
	for (let i = 0; i < xArray.length; i++) {
		L_n += (yArray[i] * p[i]);
	}
	return L_n;
}
/*
1. 先判断圆与矩形交点
2. 如果没有交点，利用圆弧上一个点，判断包含关系： 圆弧被包含，或分离，over
3. 如果有交点，判断交点是否在圆弧上
4. 如果交点不在圆弧上，利用圆弧上一个点，判断包含关系： 圆弧被包含，或分离
5. 如果交点在圆弧上，相交

1，2，4，5都好办，因此关键就是判断圆上一个点是否在指定圆弧上，下面大致说下方法。
假设圆弧起点p1,经过p2,达到终点p3，圆心为p0，问题就是如果p是圆上的点，如何判断p是否属于圆弧。
设下面* 为叉积 angle（a，b）表示两个向量夹角
step 1.先求得起点p1处一个切向d，于是从p1沿d到p3确定一圆弧，p1沿-d到p3确定另一圆弧，p2在其中一个圆弧上，d可以如下求得
圆弧所在平面的法向 n = p1p0*p1p3
圆弧起点处的一个切向：d = p1p0*n
step 2. 于是我们的目的是判断，p是不是和p2处在同样的圆弧段上
假设alpha = angle(p1p3,d),那么p与p2在同一圆弧，当且仅当
angle(p1p2,d) > alpha ; angle(p1p,d) > alpha
或者
angle(p1p2,d) < alpha ; angle(p1p,d) < alpha
*/
/**
 * @param {Circle} arc 圆弧对象
 * @param {DOMRect} rect 矩形对象 
 **/
function isArcInRect(arc, rect) {
	if (isCircleIntersectRect(arc, rect)) {
		//如果有交点，判断交点是否在圆弧上，或者在圆的其他部分
		//首先解方程获取交点 

	} else {
		//如果没有交点,判断圆上一点是否在矩形内部
		var p1 = {}
		p1.x = arc.radius * Math.cos(arc.startRad)
		p1.y = arc.radius * Math.sin(arc.startRad)
		//如果圆上一点在矩形内部，则圆弧在矩形内部，否则不在
		return isPointInRect(p1, rect)
	}
}
/**
 * @param {Point} point 要判断的点
 * @param {DOMRect} rect 要判断的矩形
 **/
function isPointInRect(point, rect) {
	if (point.x <= rect.right && point.x >= rect.left && point.y <= rect.top && point.y >= rect.bottom)
		return true
	else
		return false
}


function distanceFromPointToLine(x_p, y_p, x1, y1, x2, y2) {
	let A = y2 - y1;
	let B = x1 - x2;
	let C = x2 * y1 - y2 * x1;
	let d = Math.abs((A * x_p + B * y_p + C) / Math.sqrt(A * A + B * B));
	return d;
}

//判断圆与矩形相交
function isCircleIntersectRect(circle, rect) {
	//与矩形上边界的最小距离	
	var d1 = distanceFromPointToLine(circle.centreX, circle.centreY, rect.left, rect.top, rect.right, rect.top)
	//与矩形下边界的最小距离
	var d2 = distanceFromPointToLine(circle.centreX, circle.centreY, rect.left, rect.bottom, rect.right, rect.bottom)
	//与矩形左边界的最小距离
	var d1 = distanceFromPointToLine(circle.centreX, circle.centreY, rect.left, rect.top, rect.left, rect.bottom)
	//与矩形右边界的最小距离
	var d2 = distanceFromPointToLine(circle.centreX, circle.centreY, rect.right, rect.top, rect.right, rect.bottom)

	var minDistance = Math.min(d1, d2, d3, d4)
	var maxDistance = Math.max(d1, d2, d3, d4)
	//如果圆心在矩形内部，判断是包含还是相交
	if (isPointInRect({
			x: circle.centreX,
			y: circle.centreY
		}, rect)) {
		if (circle.radius >= minDistance && circle.radius <= maxDistance) {
			return true
		} else {
			//否则不是包含就是被包含
			return false
		}
	} else {
		if (circle.radius >= minDistance) {
			return true
		} else {
			return false
		}
	}

}
