/**
 * 该store用于不同页面之间的参数传递
 */

import {
	defineStore
} from 'pinia'

export const useGlobalParmasStore = defineStore('globalParmasStore', {
	state: () => ({
        /**
         * 不同页面使用不同的属性管理
         */
        fill:{
            img:null
        },
        test:111,
	}),
	actions:{

	}
})