import $Math from "../YeahDrawer/$Math.js";
import LineShape from "../YeahDrawer/LineShape.js";

//拉格朗日插值曲线
export default class CurveL extends LineShape {
	/**
	 * 绘制拉格朗日插值法图像
	 * @param pintArr 点数组[{x:1,y:2},{x:10,y:20},{x:33,y:30},{x:60,y:30},,{x:100,y:130}]
	 * @param ctx 画笔（canvas上下文对象）
	 */
	constructor(pointArr,rad,ctx) {
		super(ctx)
		this.pointArr = pointArr
		this.rad=rad
	}
	
	$setStrokePath(){
		this.strokePath=$Math.drawLg(this.pointArr,this.rad)
	}
	
	// draw() {
	// 	this.ctx.beginPath()
	// 	this.drawLg();
	// 	this.ctx.stroke();
	// }

	// $isPointInPath(offsetX,offsetY) {
	// 	this.ctx.beginPath()
	// 	this.drawLg();
	// 	return this.ctx.isPointInStroke(offsetX,offsetY);
	// }

	/**
	 * 画拉格朗日插值曲线
	 */
	drawLg(){
		let x = [];
		let y = [];
		let y2 = [];
		this.pointArr.forEach(e => {
			x.push(e.x);
			y.push(e.y);
		})
		let min = x[0];
		let max = x[0];
		for (let i = 0; i < x.length; i++) {
			if(max<x[i]){
				max = x[i];
			}
			if(min>x[i]){
				min = x[i];
			}
		}
		// console.log(max,min)

		//加入到预测的插值数组
		for (let i = min; i < max; i += 1) {
			//调用该模块中的拉格朗日插值算法lgsf，请用this
			y2.push(this.lgsf(i, x, y));
		}

		//画线
		this.ctx.moveTo(min,y2[0])
		var rotatePoint={x:min,y:y2[0]}
		//此处调整i和min可以减少曲线的精度
		for (let i = 0; i < y2.length; i+=2) {
			// console.log(y2[i])
			let temp=$Math.revolvePoint(rotatePoint.x,rotatePoint.y,min,y2[i],this.rad)
			
			this.ctx.lineTo(temp.x,temp.y)
			//this.ctx.lineTo(min, y2[i])
			min +=2;
		}
	}
	/**
	 * 拉格朗日插值法
	 * @param x1       待预测的x坐标
	 * @param xArray   x坐标数组
	 * @param yArray   y坐标数组
	 * @returns {number}   预测的y坐标的值
	 */
	lgsf(x1, xArray, yArray) {
		//基函数
		let p = [];
		let L_n = 0;
		for (let i = 0; i < xArray.length; i++) {
			//分子  分母
			let fz = 1;
			let fm = 1;
			for (let j = 0; j < xArray.length; j++) {
				if (j !== i) {
					fz *= (x1 - xArray[j])
					fm *= (xArray[i] - xArray[j])
				}
			}
			let pe = fz / fm;
			p.push(pe);
		}
		//多项式
		for (let i = 0; i < xArray.length; i++) {
			L_n += (yArray[i] * p[i]);
		}
		return L_n;
	}
}
