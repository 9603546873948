import Curve3 from "../SingleShape/Curve3.js";
import ControlPoint from "../YeahDrawer/ControlPoint.js";
import CShape from "../YeahDrawer/CShape.js";
import PainterElement from "./PainterElement.js";

export default class CCurve3 extends PainterElement{
	constructor(startX, startY, p1X, p1Y, p2X, p2Y ,ctx){
		super(...arguments)
	}
	$getControlPoints(startX, startY, p1X, p1Y, p2X, p2Y, ctx) {
		this._start = new ControlPoint(startX, startY, 6)
		this.controlPoints.push(this._start)
		this._p1 = new ControlPoint(p1X, p1Y, 6)
		this.controlPoints.push(this._p1)
		this._p2 = new ControlPoint(p2X, p2Y, 6)
		this.controlPoints.push(this._p2)
	}
	$getChildShape(startX, startY, p1X, p1Y, p2X, p2Y, ctx) {
		this._curve = new Curve3(startX, startY, p1X, p1Y, p2X, p2Y, ctx)
		this._start.bind(this._curve, 'startX', 'startY')
		this._p1.bind(this._curve, 'p1X', 'p1Y')
		this._p2.bind(this._curve, 'p2X', 'p2Y')
		this.childShapes.push(this._curve)
	}
}