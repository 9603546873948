import $Math from "../YeahDrawer/$Math.js";
import ControlPoint from "../YeahDrawer/ControlPoint.js";
import CShape from "../YeahDrawer/CShape.js";
import Curve from "../SingleShape/Curve.js";
import CtxStatus from '../YeahDrawer/CtxStatus.js';
import Circle from "../SingleShape/Circle.js";
import PainterElement from "./PainterElement.js";

export default class CCircle extends PainterElement {
	/**
	 * 暴露属性:半径radius,起始角startRad，结束角endRad，位置pos
	 **/
	constructor(radius, centreX, centreY, startRad, endRad, ctx) {
		super(...arguments)
		this.ctxStatus = new CtxStatus(1, 1, 0, 0, 0)
		this._centre.onMousedown__ = function() {}
		//var fatherShape = this 这玩意是非代理对象，修改属性不触发响应式
		this._start.onMousedown__ = function($event) {
			$event.stopPropagation()
			//console.log(3,fatherShape._start)
			var that=this.fatherShape
			this.fatherShape.fatherShape.onMousemove__ = function($event) {
				var mousePoint = that.toOffsetXY($event.clientX, $event.clientY)
				let newDeg = $Math.parseAngle(that._centre.x, that._centre.y,
					mousePoint.x, mousePoint.y)
				that.startRad=newDeg
				$event.stopPropagation()
			}
			this.fatherShape.fatherShape.onMouseup__ = function($event) {
				this.onMousemove__ = null
			}
		}
		this._end.onMousedown__ = function($event) {
			$event.stopPropagation()
			var that=this.fatherShape
			//console.log(3,fatherShape._end)
			this.fatherShape.fatherShape.onMousemove__ = function($event) {
				var mousePoint = that.toOffsetXY($event.clientX, $event.clientY)
				let newDeg = $Math.parseAngle(that._centre.x, that._centre.y,
					mousePoint.x, mousePoint.y)
				that.endRad=newDeg
				$event.stopPropagation()
			}
			this.fatherShape.fatherShape.onMouseup__ = function($event) {
				this.onMousemove__ = null
			}
		}

		for (let i = 0; i < this.boundryPoints.length; i++) {
			var Circle=this//注意非代理对象，修改他的属性不会触发响应式 傻逼vue
			this.boundryPoints[i].onMousedown__ = function($event) {
				var p=this//这玩意是代理对象
				$event.stopPropagation()
				var mousedownPoint = this.fatherShape.toOffsetXY($event.clientX, $event.clientY)
				var dx = mousedownPoint.x - this.fatherShape._centre.x
				var dy = mousedownPoint.y - this.fatherShape._centre.y
				var d = Math.sqrt(dx ** 2 + dy ** 2)
				let mousedownR = this.fatherShape.radius
				this.fatherShape.fatherShape.onMousemove__ = function($event) {
					var mousePoint = this.toOffsetXY($event.clientX, $event.clientY)
					//console.log(mousePoint)
					let newDx = mousePoint.x - Circle._centre.x
					let newDy = mousePoint.y - Circle._centre.y
					let newD = Math.sqrt(newDx ** 2 + newDy ** 2)
					if (mousedownR + (newD - d)/Math.sqrt(2) > 0) {
						p.fatherShape.radius = mousedownR + (newD - d)/Math.sqrt(2) //这边依然有问题
					}
				}
				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = null
				}
			}
		}

	}

	$getControlPoints(radius, centreX, centreY, startRad, endRad, ctx) {
		this._startRad=startRad
		this._endRad=endRad
		this._radius=radius
		this._posX=centreX
		this._posY=centreY
		let x1 = radius * Math.cos(startRad) + centreX
		let y1 = radius * Math.sin(startRad) + centreY
		let x2 = radius * Math.cos(endRad) + centreX
		let y2 = radius * Math.sin(endRad) + centreY
		this._start = new ControlPoint(x1, y1, 6, ctx)
		this._end = new ControlPoint(x2, y2, 6, ctx)
		this._centre = new ControlPoint(centreX, centreY, 6, ctx)
		this.controlPoints.push(this._start)
		this.controlPoints.push(this._end)
		this.controlPoints.push(this._centre)
	}

	$getChildShape(radius, centreX, centreY, startRad, endRad, ctx) {
		this._circle = new Circle(radius, centreX, centreY, startRad, endRad, ctx)
		this._centre.bind(this._circle, 'centreX', 'centreY')
		this.childShapes.push(this._circle)
	}

	$rules() {
		this.boundryPoints[0].x = this._centre.x - this._circle.radius - 10
		this.boundryPoints[0].y = this._centre.y - this._circle.radius - 10
		this.boundryPoints[1].x = this._centre.x + this._circle.radius + 10
		this.boundryPoints[1].y = this.boundryPoints[0].y
		this.boundryPoints[2].x = this.boundryPoints[1].x
		this.boundryPoints[2].y = this._centre.y + this._circle.radius + 10
		this.boundryPoints[3].x = this.boundryPoints[0].x
		this.boundryPoints[3].y = this.boundryPoints[2].y
	}

	__updateControlPoint__() {

	}
	
	/**
	 * 表面上看控制的是起始点与终止点，实际上控制的是起始角与终止角
	 **/
	set startRad(val) {
		this._startRad=val
		this._circle.startRad=val
		let x1 = this.radius * Math.cos(val) + this._centre.x
		let y1 = this.radius * Math.sin(val) + this._centre.y
		this._start.x=x1
		this._start.y=y1
	}
	
	get startRad(){
		return this._startRad
	}
	
	set endRad(val) {
		this._endRad=val
		this._circle.endRad=val
		let x1 = this.radius * Math.cos(val) + this._centre.x
		let y1 = this.radius * Math.sin(val) + this._centre.y
		this._end.x=x1
		this._end.y=y1
	}
	
	get endRad(){
		return this._endRad
	}
	
	set radius(val){
		this._radius=val
		this._circle.radius=val
		let x1 = val * Math.cos(this.startRad) + this._centre.x
		let y1 = val * Math.sin(this.startRad) + this._centre.y
		let x2 = val * Math.cos(this.endRad) + this._centre.x
		let y2 = val * Math.sin(this.endRad) + this._centre.y
		this._start.x=x1
		this._start.y=y1
		this._end.x=x2
		this._end.y=y2
	}
	
	get radius(){
		return this._radius
	}
	
	/**
	 * 圆心位置
	 **/
	set posX(val){
		var temp=val-this._posX
		this._posX=val
		this._centre.x=val
		this._start.x+=temp
		this._end.x+=temp
	}
	
	get posX(){
		return this._posX
	}
	
	set posY(val){
		var temp=val-this._posY
		this._posY=val
		this._centre.y=val
		this._start.y+=temp
		this._end.y+=temp
	}
	
	get posY(){
		return this._posY
	}
}
