<template>
	<div v-if="store.painter">
		<div v-if="!store.painter.onEditShape">
			<h3>编辑图元基因</h3>
			<div class="choose-warpper">
				<h4>选择线条</h4>
				<div id="img-box">
					<div class="img-btn"><img :src='lineImg' alt="折线" @click="typeSelected('Polygon')"></div>
					<div class="img-btn"><img :src='circleImg' alt="圆弧" @click="typeSelected('Circle')" /></div>
					<div class="img-btn"><img :src='curve3Img' alt="三点曲线" @click="typeSelected('Curve3')"></div>
					<div class="img-btn"><img :src='curveImg' alt="四点曲线" @click="typeSelected('Curve')"></div>
					<div class="img-btn"><img :src='curve5Img' alt="五点曲线" @click="typeSelected('Curve5')"></div>
				</div>
			</div>
			<h3 @click="toolBar.showLines = toolBar.showLines ? false : true">图元基因所含线条 <el-icon class="expend">
					<ArrowDown />
				</el-icon>
			</h3>
			<div v-for="(item, index) in store.painter.childShapes" class="line-item" @click="edit(index)" :key="index"
				v-show="(!store.painter.onEditShape) && toolBar.showLines">
				<span>{{ translateClassname(item) }}</span>
				<button class="l-copy" @click.stop="copy(index)"><i class="iconfont icon-copy"></i></button>
				<span>（复制副本并编辑副本）</span>
			</div>
			<h3>图元基因信息</h3>
			<div class="info-container">
				<label>基因组编号</label>
				<el-input v-model="store.gid"></el-input>
				<label>基因组年份</label>
				<el-input v-model="store.info.date"></el-input>
				<label>所属民族</label>
				<el-input v-model="store.info.nation"></el-input>
				<label>象征意义/纹样类别</label>
				<el-input v-model="store.info.sign"></el-input>
				<label>使用场景</label>
				<el-input v-for="(item, index) in store.info.usage" v-model="store.info.usage[index]"></el-input>
				<div></div>
				<el-button @click="store.info.usage.push('')">添加</el-button>
				<div style="height: 0.2rem;"></div>
			</div>
		</div>
		<div v-else class="edit-layer">
			<h3>{{ oneditTypeCn }}
				<span class="confirm" title="复制" @click="copyOnEditShape">
					<i class="iconfont icon-copy"></i>
				</span>
				<span class="confirm" title="确定" @click="store.painter.confirm()">✔</span>
			</h3>
			<div class="choose-warpper">
				<h5 @click="toolBar.showRotate = toolBar.showRotate ? false : true">
					旋转
					<el-icon class="expend">
						<ArrowDown />
					</el-icon>
				</h5>
				<div class="rotate-container" v-show="oneditTypeCn != '圆弧' && toolBar.showRotate">
					<h4>选择旋转点</h4>
					<div id="point-box">
						<label class="point" v-for="(item, index) in store.painter.onEditShape.controlPoints">P{{ (index +
							1)
						}}<input type="radio" name="rotatePoint" :value="index" v-model="rotatePointIndex"></label>
					</div>
					<h4>旋转角度</h4>
					<div class="r-btngroup">
						<button @click="rotate(false)">逆时针</button>
						<input type="number" v-model.number="angle" />
						<button @click="rotate(true)">顺时针</button>
					</div>
				</div>
				<h5 @click="toolBar.showLineStyle = toolBar.showLineStyle ? false : true">
					线条样式
					<el-icon class="expend">
						<ArrowDown />
					</el-icon>
				</h5>
				<div class="lineStyle container" v-show="toolBar.showLineStyle">
					<div>
						<label>整体宽度<input type="number" step="0.5"
								v-model.number='store.painter.onEditShape.outterLineWidth' />
						</label>
					</div>
					<div>
						<label>内容宽度<input type="number" step="0.5"
								v-model.number='store.painter.onEditShape.innerLineWidth' />
						</label>
					</div>
					<div>
						<label>整体颜色<input type="color" v-model.number='store.painter.onEditShape.outterStrokeColor' />
						</label>
					</div>
					<div>
						<label>内容颜色<input type="color" v-model.number='store.painter.onEditShape.innerStrokeColor' />
						</label>
					</div>
				</div>
				<h5 @click="toolBar.showPointStyle = toolBar.showPointStyle ? false : true">
					控制点样式
					<el-icon class="expend">
						<ArrowDown />
					</el-icon>
				</h5>
				<div class="lineStyle container" v-show="toolBar.showPointStyle">
					<div>
						<label>尺寸<input type="number" step="0.5"
								v-model.number='store.painter.onEditShape.controlPointsSize' />
						</label>
					</div>
					<div>
						<label>边框颜色<input type="color"
								v-model.number='store.painter.onEditShape.controlPointsBorderColor' />
						</label>
					</div>
					<div>
						<label>内容颜色<input type="color" v-model.number='store.painter.onEditShape.controlPointsFillColor' />
						</label>
					</div>
				</div>
				<template v-if="oneditTypeCn != '圆弧'">
					<h5 @click="toolBar.showControlPoints = toolBar.showControlPoints ? false : true">
						控制点坐标
						<el-icon class="expend">
							<ArrowDown />
						</el-icon>
					</h5>
					<div v-for="(item, index) in store.painter.onEditShape.controlPoints" class="coordinate container"
						v-show="toolBar.showControlPoints">
						<div>P{{ index + 1 }}</div>
						<div>
							<label>X<input type="number" v-model="item.x" /></label>
							<input type="range" min="0" />
						</div>
						<div>
							<label>Y<input type="number" v-model="item.y" /></label>
							<input type="range" min="0" />
						</div>
					</div>
				</template>
				<template v-if="oneditTypeCn == '圆弧'">
					<h5>圆心坐标</h5>
					<div class="coordinate container">
						<div>
							<label>X<input type="number" v-model="store.painter.onEditShape.posX" /></label>
							<input type="range" min="0" />
						</div>
						<div>
							<label>Y<input type="number" v-model="store.painter.onEditShape.posY" /></label>
							<input type="range" min="0" />
						</div>
					</div>

					<h5>弧度</h5>
					<div class="coordinate container">
						<div>
							<label>起始角<input type="number" v-model="store.painter.onEditShape.startRad" /></label>
							<input type="range" min="0" />
						</div>
						<div>
							<label>结束角<input type="number" v-model="store.painter.onEditShape.endRad" /></label>
							<input type="range" min="0" />
						</div>

					</div>
					<h5>调整大小</h5>
					<div class="coordinate container">
						<label>半径<input type="number" v-model="store.painter.onEditShape.radius" /></label>
						<input type="range" min="0" />
					</div>
				</template>
				<div v-if="oneditTypeCn == '折线'" class="addpoint">
					<button @click="addPointFront">添加节点(头)</button>
					<button @click="addPointTail">添加节点(尾)</button>
					<button>删除头结点</button>
					<button>删除尾结点</button>
				</div>
				<div class="Mirror">
					<button @click="MirrorReverse(1)">左右翻转</button>
					<button @click="MirrorReverse(2)">上下翻转</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import lineUn from '../assets/img/GoolBar/line.png'
import circleUn from '../assets/img/GoolBar/circle.png'
import curveUn from '../assets/img/GoolBar/curve4.png'
import curve3Un from '../assets/img/GoolBar/curve3.png'
import curve5Un from '../assets/img/GoolBar/curve5.png'
import lineSt from '../assets/img/GoolBar/lineSelected.png'
import circleSt from '../assets/img/GoolBar/circleSelected.png'
import curveSt from '../assets/img/GoolBar/curve4Selected.png'
import curve3St from '../assets/img/GoolBar/curve3Selected.png'
import curve5St from '../assets/img/GoolBar/curve5Selected.png'
import {
	ElMessage
} from 'element-plus'
import {
	ref,
	defineEmits,
	reactive,
	computed,
	watch,
	onUnmounted,
	getCurrentInstance,
} from 'vue'
import {
	useGenedrawerStore
} from '../store/genedrawer.js'
import {
	storeToRefs
} from 'pinia'
import CPolygon from '../assets/js/PainterDemo-v1.3/CompositionShape/CPolygon'
import CCurve from '../assets/js/PainterDemo-v1.3/CompositionShape/CCurve'
import CCurve3 from '../assets/js/PainterDemo-v1.3/CompositionShape/CCurve3'
import CCircle from '../assets/js/PainterDemo-v1.3/CompositionShape/CCircle'
import Polygon from '../assets/js/PainterDemo-v1.3/SingleShape/Polygon'
import Curve from '../assets/js/PainterDemo-v1.3/SingleShape/Curve'
import Curve3 from '../assets/js/PainterDemo-v1.3/SingleShape/Curve3'
import Circle from '../assets/js/PainterDemo-v1.3/SingleShape/Circle'
import CCurveL from '../assets/js/PainterDemo-v1.3/CompositionShape/CCurveL'
import CurveL from '../assets/js/PainterDemo-v1.3/SingleShape/CurveL'

var toolBar = reactive({
	showRotate: true,
	showLineStyle: false,
	showControlPoints: true,
	showPointStyle: false,
	showLines: true
})


const store = useGenedrawerStore()
//图片直接缓存
var imgs = []
var imgsrcs = [lineUn, circleUn, curveUn, curve3Un, curve5Un, lineSt,
	circleSt,
	curveSt,
	curve3St,
	curve5St
]
for (let i = 0; i < 10; i++) {
	let img = new Image()
	img.src = imgsrcs[i]
	imgs.push(img)
}

var save = false
var lineImg = ref(lineUn)
var circleImg = ref(circleUn)
var curveImg = ref(curveUn)
var curve3Img = ref(curve3Un)
var curve5Img = ref(curve5Un)

const emit = defineEmits(['select'])

//旋转点下标
var rotatePointIndex = ref(0)

//旋转角度
var angle = ref(0)

function rotate(flag) {
	if (flag) {
		store.painter.onEditShape.rotate(rotatePointIndex.value, -angle.value)
	} else {
		store.painter.onEditShape.rotate(rotatePointIndex.value, angle.value)
	}
}

function edit(index) {
	store.painter.changeOnEditShape(index)
}
/**
 * 复制一个已有的线条
 * **/
function copy(index) {
	store.painter.copyAndEdit(index)
	ElMessage('复制成功')
}

function copyOnEditShape() {
	var index = 0
	store.painter.childShapes.forEach((item, i) => {
		debugger
		if (item == store.painter.onEditShape) {
			index = i
		}
	})
	store.painter.confirm()
	store.painter.copyAndEdit(index)
	ElMessage('复制成功')
}

function addPointFront() {
	if (store.painter.onEditShape instanceof CPolygon) {
		var p1 = store.painter.onEditShape.controlPoints[0]
		var p0 = {
			x: p1.x / 2,
			y: p1.y / 2
		}
		store.painter.onEditShape.addPointsFront([p0])
	}
}

function addPointTail() {
	if (store.painter.onEditShape instanceof CPolygon) {
		var p1 = store.painter.onEditShape.controlPoints[0]
		var sizeX = store.painter.contentSize.x
		var sizeY = store.painter.contentSize.y
		var p0 = {
			x: sizeX / 2 + p1.x / 2,
			y: sizeY / 2 + p1.y / 2
		}
		store.painter.onEditShape.addPoints([p0])
	}

}

/**
 * 反转正在编辑的图形的控制点
 * **/
function MirrorReverse(mode) {
	store.painter.MirrorReverse(mode)
}

// function clearSelection() {
// 	lineImg.value = lineUn
// 	circleImg.value = circleUn
// 	curveImg.value = curveUn
// 	curve3Img.value = curve3Un
// 	curve5Img.value = curve5Un
// }

lineImg = computed(() => {
	console.log("----computer!!!")
	return store.painter.preparedShape == "Polygon" ? lineSt : lineUn
})

circleImg = computed(() => {
	console.log("----computer!!!")
	return store.painter.preparedShape == "Circle" ? circleSt : circleUn
})

curveImg = computed(() => {
	console.log("----computer!!!")
	return store.painter.preparedShape == "Curve" ? curveSt : curveUn
})

curve3Img = computed(() => {
	console.log("----computer!!!")
	return store.painter.preparedShape == "Curve3" ? curve3St : curve3Un
})

curve5Img = computed(() => {
	console.log("----computer!!!")
	return store.painter.preparedShape == "Curve5" ? curve5St : curve5Un
})


// watch(() => store.painter.preparedShape, (newVal, oldVal) => {
// 	clearSelection()
// 	switch (newVal) {
// 		case 'none':
// 			break;
// 		case 'Polygon':
// 			lineImg.value = lineSt
// 			break;
// 		case 'Circle':
// 			circleImg.value = circleSt
// 			break;
// 		case 'Curve':
// 			curveImg.value = curveSt
// 			break;
// 		case 'Curve3':
// 			curve3Img.value = curve3St
// 			break;
// 		case 'Curve5':
// 			curve5Img.value = curve5St
// 			break;
// 	}
// })


var oneditTypeCn = computed(() => {
	if (store.painter.onEditShape) {

		var res = translateClassname(store.painter.onEditShape)
		return res
	}
})

function translateClassname(obj) {
	if (obj instanceof CPolygon || obj instanceof Polygon) {
		return '折线'
	} else if (obj instanceof CCurve || obj instanceof Curve) {
		return '四点曲线'
	} else if (obj instanceof CCurve3 || obj instanceof Curve3) {
		return '三点曲线'
	} else if (obj instanceof CCircle || obj instanceof Circle) {
		return '圆弧'
	} else if (obj instanceof CCurveL || obj instanceof CurveL) {
		return '拉格朗日曲线'
	} else {
		return 'ERROR'
	}
}



function typeSelected(type) {
	if (store.painter)
		switch (type) {
			case 'Polygon':
				store.painter.prepareToEdit('Polygon')
				break;
			case 'Circle':
				store.painter.prepareToEdit('Circle')
				break;
			case 'Curve':
				store.painter.prepareToEdit('Curve')
				break;
			case 'Curve3':
				store.painter.prepareToEdit('Curve3')
				break;
			case 'Curve5':
				store.painter.prepareToEdit('Curve5')
				break;
		}
}

onUnmounted(() => {
	if (!save) {

	}
})
</script>

<style scoped src='../assets/css/goolBar.less'></style>
