import SShape from "./SShape.js";

export default class LineShape extends SShape{
	constructor(ctx){
		super(ctx)
	}
		
	$isPointInPath(x,y){
		this.ctx.lineWidth=this.lineWidth.outter+2
		var flag=this.ctx.isPointInStroke(this.strokePath,x,y)
		this.ctx.lineWidth=1
		return flag
	}
	
}