import CCircle from './CompositionShape/CCircle.js'
import CCurve from './CompositionShape/CCurve.js'
import CCurve3 from './CompositionShape/CCurve3.js'
import CCurveL from './CompositionShape/CCurveL.js'
import CGeneCase from './CompositionShape/CGeneCase.js'
import CImg from './CompositionShape/CImg.js'
import CPolygon from './CompositionShape/CPolygon.js'
import Circle from './SingleShape/Circle.js'
import Curve from './SingleShape/Curve.js'
import Curve3 from './SingleShape/Curve3.js'
import CurveL from './SingleShape/CurveL.js'
import GeneCase from './SingleShape/GeneCase.js'
import Img from './SingleShape/Img.js'
import Polygon from './SingleShape/Polygon.js'
import CShape from './YeahDrawer/CShape.js'
export default {
	//动态生成反射表
	buildRelationship() {
		this.relationship[Circle.name] = {
			SShape: Circle,
			CShape: CCircle,
			propsRealtion: {
				//创建一个CShape对象时需要依赖的SShape中的属性，右侧为SShape中的属性，左侧为CShape构造函数中的参数,按参数顺序排列
				radius: 'radius',
				centreX: 'centreX',
				centreY: 'centreY',
				startRad: 'startRad',
				endRad: 'endRad',
				ctx: 'ctx'
			}
		}
		this.relationship[Curve.name] = {
			SShape: Curve,
			CShape: CCurve,
			propsRealtion: {
				//创建一个CShape对象时需要依赖的SShape中的属性，右侧为SShape中的属性，左侧为CShape构造函数中的参数,按参数顺序排列
				startX: 'startX',
				startY: 'startY',
				p1X: 'p1X',
				p1Y: 'p1Y',
				p2X: 'p2X',
				p2Y: 'p2Y',
				p3X: 'p3X',
				p3Y: 'p3Y',
				ctx: 'ctx'
			}
		}
		this.relationship[Curve3.name] = {
			SShape: Curve3,
			CShape: CCurve3,
			propsRealtion: {
				//创建一个CShape对象时需要依赖的SShape中的属性，右侧为SShape中的属性，左侧为CShape构造函数中的参数,按参数顺序排列
				startX: 'startX',
				startY: 'startY',
				p1X: 'p1X',
				p1Y: 'p1Y',
				p2X: 'p2X',
				p2Y: 'p2Y',
				ctx: 'ctx'
			}
		}
		this.relationship[GeneCase.name] = {
			"SShape": GeneCase,
			"CShape": CGeneCase,
			"propsRealtion": {
				//创建一个CShape对象时需要依赖的SShape中的属性，右侧为SShape中的属性，左侧为CShape构造函数中的参数,按参数顺序排列
				caseJsonObject: 'caseJsonObject',
				ctx: 'ctx'
			}
		}
		this.relationship[Polygon.name] = {
			"SShape": Polygon,
			"CShape": CPolygon,
			"propsRealtion": {
				//创建一个CShape对象时需要依赖的SShape中的属性，右侧为SShape中的属性，左侧为CShape构造函数中的参数,按参数顺序排列
				pointArr: 'pointArr',
				ctx: 'ctx'
			}
		}
		this.relationship[CurveL.name] = {
			"SShape": CurveL,
			"CShape": CCurveL,
			"propsRealtion": {
				pointArr: 'pointArr',
				rad: 'rad',
				ctx: 'ctx'
			}
		}
		this.relationship[Img.name] = {
			"SShape": Img,
			"CShape": CImg,
			"propsRealtion": {
				img: 'img',
				dx: 'dx',
				dy: 'dy',
				dw: 'dw',
				dh: 'dh',
				ctx: 'ctx'
			}
		}
	},
	relationship: {}
}
