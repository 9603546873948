import $Event from "../YeahDrawer/$Event.js";
import CShape from "../YeahDrawer/CShape.js";
import SShape from "../YeahDrawer/SShape.js";
import CtxStatus from '../YeahDrawer/CtxStatus.js'
import ShapesConfig from '../ShapesConfig.js'
import imgUrl from './bckbig.png'
import imgUrl2 from './bck2.png'
export default class Painter extends CShape {
	constructor(...args) {
		super(...arguments)
		this.ctxStatus = new CtxStatus(1, 1, 0, 0, 0, null)
		//指向最后操作的元素,只能是复合图形对象
		this._lastOperateShape = null
		this.shapesConfigs = ShapesConfig
		this.width = 2500
		this.height = 1000
		this.axisColor = '#358bf3' //xy轴颜色
		this.solidColor = '#CCCCCC70'
		this.dashedColor = '#CCCCCC25'
		this.gridSize = 5
		this.strokeColorDefault = 'black'
		this.strokeColorChoose = 'blue'
		this._onEditShape = null
		this.onMousemove__ = null
		this.onMouseup__ = null
		this.generatingImage = false
		//上一帧的时间
		this.lastCalledTime = performance.now()
		//帧号
		this.frameCount = 0
		ShapesConfig.buildRelationship()
		//设置默认背景
		this.backgroundMode=1
	}

	_setChildStrokeColor() {
		this.childShapes.forEach((item) => {
			item.strokeColor = this.strokeColorDefault
			item.returnColor = this.strokeColorDefault
		})
	}
	/**
	 * 此方法用来计算10秒内的平均帧率和单次绘制时间
	 **/
	startCount() {
		this.startCountTime = performance.now()
		this.frameCount = 1
		this.drawingTime = 0//绘制用时
	}
	/**
	 * 此方法停止计算平均帧率和单次绘制时间
	 **/
	finishCount() {
		var timeSum = (performance.now() - this.startCountTime) / 1000
		var fps = this.frameCount / timeSum
		var avgDrawingTime=this.drawingTime/this.frameCount
		this.frameCount = 0
		this.drawingTime = 0
		return {fps,avgDrawingTime}
	}

	getFps() {
		var nowTime = performance.now()
		var timeInterval = (nowTime - this.lastCalledTime) || 1
		var fps = 1000 / timeInterval
		this.lastCalledTime = nowTime
		this.frameCount = (++this.frameCount) % 144
		return fps
	}

	get gridSize() {
		return this._gridSize
	}

	set gridSize(val) {
		this._gridSize = val
	}

	get height() {
		return this._height
	}

	set height(val) {
		this._height = val
	}

	get width() {
		return this._width
	}

	set width(val) {
		this._width = val
	}
	
	set backgroundMode(val){
		this._backgoundMode=val
		if(val==1){
			this.loading = this.createBackground(imgUrl).then((img) => {
				this.drawBackgroundGrid = function() {
					this.ctx.drawImage(img, 0, 0, this.width * 2, this.height * 2, -this.width / 2, -this
						.height / 2, this.width, this.height)
				}
				return false
			})
		}else if(val==2){
			this.loading = this.createBackground(imgUrl2).then((img) => {
				this.drawBackgroundGrid = function() {
					this.ctx.drawImage(img, 0, 0, this.width * 2, this.height * 2, -this.width / 2, -this
						.height / 2, this.width, this.height)
				}
				return false
			})
		}else if(val==3){
			
		}else if(val==4){
			this.loading=new Promise((resolve, reject)=>{
				this.drawBackgroundGrid=function(){}
				resolve(false)
			})
		}
	}
	
	get backgroundMode(){
		return this._backgoundMode
	}
	
	draw() {
		this.__lineDashOffset__ += 0.1
		if (this.__lineDashOffset__ > 32) {
			this.__lineDashOffset__ = 0
		}
		this.ctx.save()
		this.ctx.clearRect(-10000, -10000, 20000, 20000)
		this.__transform__()
		this.drawBackgroundGrid()
		this.$drawControlPoints()
		this.ctx.lineDashOffset = -this.__lineDashOffset__
		this.$drawChildShape()
		this.$drawBoundry()
		this.ctx.restore()
		this.timer = requestAnimationFrame(() => {
			this.draw()
		})
	}

	destory() {
		cancelAnimationFrame(this.timer)
	}

	//此方法创建背景的图片，以后直接调用drawimg来提高效率
	createBackground(img) {
		return new Promise((resolve, reject) => {
			var bckImg = new Image()
			bckImg.src = img
			bckImg.onload = function() {
				resolve(bckImg)
			}
			bckImg.onerror = function() {
				reject("加载错误");
			}
		})
	}

	drawBackgroundGrid() {}

	$getBoundry(...args) {
		this.__lineDashOffset__ = 0
		this.boundryPath = function() {
			this.ctx.beginPath()
			this.ctx.rect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
		}
		this.ctx.save()
	}

	$drawBoundry() {

	}

	$drawControlPoints() {

	}

	addChildShape(Shape) {
		Shape.fatherShape = this
		this.childShapes.push(Shape)
		// this._lastOperateShape = Shape
		if (Shape instanceof CShape) {
			Shape.ctxStatus = new CtxStatus(1, 1, 0, 0, 0, this.ctxStatus)
		}

	}

	//添加一个新的复合图形作为子图形
	addChildCShape(cShape) {
		if (cShape instanceof CShape) {
			this.addChildShape(cShape)
		} else {
			throw new Error('参数必须为CShape类型')
		}
	}

	/**
	 * 添加图片
	 **/
	addImg(cImg) {
		if (this.onEditShape) {
			this.confirm(this.onEditShape)
		}
		this.addChildCShape(cImg)
		this.onEditShape = cImg
	}

	deleteChildShape(Shape) {

	}

	$afterChildShapesMousedown = function($event) {}

	$mousedown(event) {
		this.$eventTarget = this
		var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
		this.ctx.save()
		this.__transform__()
		if (this.$eventTarget == this) {
			//首先判断在编辑的图形
			if (this._onEditShape && this._onEditShape.mouseIn) {
				this.$eventTarget = this._onEditShape
				if (this.$eventTarget instanceof CShape) {
					this._lastOperateShape = this.$eventTarget
				}
				this.$eventTarget.$mousedown($event)
				if ($event.Propagation && this.onMousedown__) {
					this.onMousedown__($event)
				}
				this.$afterChildShapesMousedown($event)
				this.ctx.restore()
				return
			} else {
				for (let i = 0; i < this.childShapes.length; i++) {
					let item = this.childShapes[i]
					if (item.mouseIn) {
						this.$eventTarget = item
						if (this.$eventTarget instanceof CShape) {
							this._lastOperateShape = this.$eventTarget
						}
						this.$eventTarget.$mousedown($event)
						if ($event.Propagation && this.onMousedown__) {
							this.onMousedown__($event)
						}
						this.$afterChildShapesMousedown($event)
						this.ctx.restore()
						return
					}
				}
			}

		}
		if (this.$eventTarget == this) {
			if (this.onMousedown__) {
				this.onMousedown__($event)
			}
		}
		this.ctx.restore()
	}

	__judgeOther__(event, $event) {
		for (let i = 0; i < this.childShapes.length; i++) {
			let item = this.childShapes[i]
			if (item.$isPointInPath(event.offsetX, event.offsetY)) {
				if (!item.mouseIn) {
					item.mouseIn = true
					item.onMouseIn__($event)
				}
				this.$eventTarget = item
				if (this.$eventTarget instanceof CShape)
					this._lastOperateShape = this.$eventTarget
				this.$eventTarget.$mousemove($event)
				if ($event.Propagation && this.onMousemove__) {
					this.onMousemove__($event)
				}
				this.$afterChildShapesMousemove($event)
				this.ctx.restore()
				return
			} else {
				if (item.mouseIn) {
					item.mouseIn = false
					item.onMouseOut__($event)
				}
			}
		}
	}

	$mousemove(event) {
		this.$eventTarget = this
		var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
		this.ctx.save()
		this.__transform__()
		if (this.$eventTarget == this) {
			if (this._onEditShape) {
				if (this._onEditShape.$isPointInPath(event.offsetX, event.offsetY)) {
					let item = this._onEditShape
					if (!item.mouseIn) {
						item.mouseIn = true
						item.onMouseIn__($event)
					}
					this.$eventTarget = item
					if (this.$eventTarget instanceof CShape)
						this._lastOperateShape = this.$eventTarget
					this.$eventTarget.$mousemove($event)
					if ($event.Propagation && this.onMousemove__) {
						this.onMousemove__($event)
					}
					this.$afterChildShapesMousemove($event)
					this.ctx.restore()
					return
				} else {
					let item = this._onEditShape
					if (item.mouseIn) {
						item.mouseIn = false
						item.onMouseOut__($event)
					}
					this.__judgeOther__(event, $event)
				}
			} else {
				this.__judgeOther__(event, $event)
			}
		}

		if (this.$eventTarget == this) {
			if (this.onMousemove__) {
				this.onMousemove__($event)
			}

		}
		this.ctx.restore()
	}

	$mouseup(event) {
		this.$eventTarget = this
		var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
		this.ctx.save()
		this.__transform__()
		if (this.$eventTarget == this) {
			if (this._onEditShape && this._onEditShape.mouseIn) {
				this.$eventTarget = this._onEditShape
				this.$eventTarget.$mouseup($event)
				if ($event.Propagation && this.onMouseup__) {
					this.onMouseup__($event)
				}
				this.$afterChildShapesMouseup($event)
				this.ctx.restore()
				return
			} else {
				for (let i = 0; i < this.childShapes.length; i++) {
					let item = this.childShapes[i]
					if (item.mouseIn) {
						this.$eventTarget = item
						this.$eventTarget.$mouseup($event)
						if ($event.Propagation && this.onMouseup__) {
							this.onMouseup__($event)
						}
						this.$afterChildShapesMouseup($event)
						this.ctx.restore()
						return
					}
				}
			}

		}
		if (this.$eventTarget == this && this.onMouseup__) {
			this.onMouseup__($event)
		}
		this.ctx.restore()
	}

	$click(event) {

	}

	onclick__($event) {

	}

	$keyEnter(event) {
		this.onkeyEnter__()
	}

	onkeyEnter__($event) {
		this.confirm()
		this.ctx.canvas.style.cursor = 'default'
	}
	$keyDel(event) {
		this.onkeyDel__()
		this.ctx.canvas.style.cursor = 'default'
	}
	onkeyDel__(event) {
		for (let i = 0; i < this.childShapes.length; i++) {
			if (this.childShapes[i] == this._lastOperateShape) {
				this.childShapes.splice(i, 1)
				this._lastOperateShape = null
				this.onEditShape = null
				break
			}
		}
	}
	//画板类默认的鼠标事件
	onMousedown__($event) {
		if (this.$eventTarget != this) {
			let moveShape = this.$eventTarget
			let mousedownPoint = this.toOffsetXY($event.clientX, $event.clientY)
			let temp = []
			if (moveShape instanceof CShape) {
				moveShape.controlPoints.forEach((item) => {
					temp.push({
						x: item.x,
						y: item.y
					})
				})

				this.onMousemove__ = function($event) {
					let mousePoint = this.toOffsetXY($event.clientX, $event.clientY)
					moveShape.controlPoints.forEach((item, index) => {
						item.x = temp[index].x + (mousePoint.x - mousedownPoint.x)
						item.y = temp[index].y + (mousePoint.y - mousedownPoint.y)
					})
				}
				this.onMouseup__ = function($event) {
					this.onMousemove__ = null
				}
			}
		} else {
			let downPoint = {
				x: $event.clientX,
				y: $event.clientY
			}
			let downStatus = this.ctxStatus.copy()
			let startPointCopy = {
				x: this.ctxStatus.translateX,
				y: this.ctxStatus.translateY
			}
			let boundryTop = -this.height / 2 * this.ctxStatus.scaleY - 100 + this.ctx.canvas.height
			let boundtyBottom = this.height / 2 * this.ctxStatus.scaleY + 100
			let boundrtLeft = -this.width / 2 * this.ctxStatus.scaleX - 100 + this.ctx.canvas.width
			let boundryRight = this.width / 2 * this.ctxStatus.scaleX + 100

			this.onMousemove__ = function($event) {
				let mousePoint = {
					x: $event.clientX,
					y: $event.clientY
				}
				let tempx = startPointCopy.x + mousePoint.x - downPoint.x
				let tempy = startPointCopy.y + mousePoint.y - downPoint.y
				if (tempx <= boundryRight && tempx >= boundrtLeft) {
					this.ctxStatus.translateX = tempx
				}
				if (tempy <= boundtyBottom && tempy >= boundryTop) {
					this.ctxStatus.translateY = tempy
				}
			}
			this.onMouseup__ = function($event) {
				this.onMousemove__ = null
			}
		}
	}


	confirm() {
		if (this._lastOperateShape != null) {
			var items = this.toSShape(this._lastOperateShape)
			if (items) {
				//原来的sshape替换成cshape
				for (let i = 0; i < this.childShapes.length; i++) {
					if (this.childShapes[i] == this._lastOperateShape) {
						this.childShapes.splice(i, 1)
						this.childShapes.push(...items)
					}
				}
			}
			this._lastOperateShape = null
		}
	}

	edit(shape) {

	}
	//把下标为index的子图形换成Shape
	replaceChildShape(index, Shape) {
		this.childShapes[index] = Shape
		Shape.fatherShape = this
		if (Shape instanceof CShape) {
			Shape.ctxStatus = new CtxStatus(1, 1, 0, 0, 0, this.ctxStatus)
		}
	}

	toSShape(cshape) {
		if (cshape instanceof CShape) {
			return cshape.childShapes
		} else {
			return null
		}
	}

	toCShape(shape) {
		var temp = this.shapesConfigs.relationship[shape.constructor.name];
		var args = [];
		Object.values(temp.propsRealtion).forEach((item) => {
			var value = shape[item]
			//如果是数组，会出现引用传递的情况，需要复制一份
			if (value instanceof Array) {
				let arr = []
				value.forEach((item) => {
					arr.push({
						x: item.x,
						y: item.y
					})
				})
				args.push(arr)
			} else {
				args.push(shape[item])
			}

		});
		var res = new temp.CShape(...args)
		res.childShapes.forEach((item) => {
			item.lineWidth = {
				inner: shape.lineWidth.inner,
				outter: shape.lineWidth.outter
			}
			item.strokeColor = {
				inner: shape.strokeColor.inner,
				outter: shape.strokeColor.outter
			}
		})
		return res;
	}

	/**
	 * 复制一个子元素,并返回
	 * @returns {SShape}
	 */
	copyOneChild(index) {
		if (!(this.childShapes[index] instanceof SShape)) {
			throw new Error('该元素无法复制')
		} else {
			var shape = this.childShapes[index]
			var temp = this.shapesConfigs.relationship[shape.constructor.name];
			var args = []
			Object.keys(temp.propsRealtion).forEach((item) => {
				let value = shape[item]
				if (value instanceof Array) {
					let arr = []
					value.forEach((item) => {
						arr.push({
							x: item.x,
							y: item.y
						})
					})
					args.push(arr)
				} else {
					args.push(shape[item])
				}
			})
			//获取构造函数
			var consfn = temp.SShape
			var res = new consfn(...args)
			res.lineWidth = {
				inner: shape.lineWidth.inner,
				outter: shape.lineWidth.outter
			}
			res.strokeColor = {
				inner: shape.strokeColor.inner,
				outter: shape.strokeColor.outter
			}
			return res
		}
	}

	copyOnEditShape() {

	}

	/**
	 * 复制一个元素并加入到队列中
	 **/
	copyAndAdd(Index) {
		var obj = this.copyOneChild(Index)
		this.addChildShape(obj)
	}

	/**
	 * 框定区域选择子元素
	 **/
	dividArea() {

	}

	/**
	 * 绘制fps
	 **/
	drawFps(fps) {
		this.ctx.fillText('FPS:' + fps, 0, 0)
	}

	/**生成内容元素的图片**/
	/**论文完成之后再修改**/
	generateContentImage(scaleX,scaleY) {
		//1.关闭背景生成 2.获取指定位置的imgdata 
		//3.使用createImageBitmap创建一个imgBitmap
		this.drawBackgroundGrid = function() {}
		this.$drawControlPoints = function() {}
		this.$drawBoundry = function() {}
		this.ctxStatus.scaleX=scaleX
		this.ctxStatus.scaleY=scaleY
		
		this.draw()
		var w = 100
		var h = 100
		if (this.contentSize) {
			if(scaleX&&scaleY){
				w = this.contentSize.x * scaleX
				h = this.contentSize.y * scaleY
			}else{
				w = this.contentSize.x * this.ctxStatus.scaleX
				h = this.contentSize.y * this.ctxStatus.scaleY
			}
		} else if (this.contentSizeX && this.contentSizeY) {
			if(scaleX&&scaleY){
				w = this.contentSizeX * scaleX
				h = this.contentSizeY * scaleY
			}else{
				w = this.contentSizeX * this.ctxStatus.scaleX
				h = this.contentSizeY * this.ctxStatus.scaleY
			}
		} else {
			throw new Error('该画板没有指定宽高')
		}
		var imgdata = this.ctx.getImageData(this.ctxStatus.translateX, this.ctxStatus.translateY, w, h)
		this.destory()
		//返回的是一个promise
		return createImageBitmap(imgdata)
	}
}
