<template>
	<div class="login-container" v-loading="logining">
		<el-dialog v-model="loginDialogVisible" width="800px" height="640px" center>
			<h1 class="title">用户登录</h1>
			<el-form class="login-form" :model="loginForm">
				<el-form-item>
					<el-input v-model="loginForm.username" placeholder="请输入用户名" clearable class="text"
						prefix-icon="User">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="loginForm.password" type="password" placeholder="请输入密码" clearable class="text"
						prefix-icon="Lock">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="loginClick" class="btn">登录</el-button>
				</el-form-item>
				<el-form-item style="justify-content: center;text-align: center;" class="visitor-login">
					<el-button type="primary" text @click="visit()">游客模式</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<div class="menu">
			<div @click="jumpClick('login')"></div>
			<div></div>
			<div></div>
		</div>
    <!-- 添加备案信息 -->
    <div class="beian-info">
      <p>备案号：<a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023023597号-1</a></p>
    </div>
	</div>
</template>

<script setup>
import {
	reactive,
	toRaw,
	ref,
} from 'vue';
import {
	useRoute,
	useRouter,
} from 'vue-router';
import Pixel from '../components/basic-component/Pixel.vue'
import {
	login
} from '../api/UserApi.js';

var loginForm = reactive({
	username: '',
	password: ''
})
var redirect = ref("/")
var router = useRouter()
var route = useRoute()
var logining = ref(false)
var loginDialogVisible = ref(false)
/**
 * 登录按钮的方法
 * **/
function loginClick() {
	logining.value = true
	login(toRaw(loginForm)).then((data) => {
		console.log("Login.vue", data)
		logining.value = false
		if (data.code == -1) {
			throw new Error(data.msg)
		}
		//跳转回原先的页面
		console.log("Login.vue", route.meta.redirect)
		ElMessage("登录成功，即将跳转")
		setTimeout(() => {
			router.push(route.meta.redirect)
		}, 1000)
	}).catch((reason) => {
		logining.value = false
		console.log("Login.vue", reason.message)
		ElMessage(reason.message)
	})
}

function visit(){
	loginForm.username='visitor'
	loginForm.password='123456'
	loginClick()
}

/**
 * 菜单栏点击事件
 * @param {*} to 前往的地址
 */
function jumpClick(to){
	loginDialogVisible.value=true
}

</script>

<style scoped>
.login-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	position: absolute;
	z-index: 10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url('~assets/img/bck.jpg');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.login-form {
	width: 600px;
	margin-bottom: 80px;
	margin-left: auto;
	margin-right: auto;
	/* background-color: darkmagenta; */
}

.title {
	color: #337ecc;
	font-size: 24px;
	line-height: 28px;
	margin-bottom: 24px;
	user-select: none;
	margin-left: auto;
	width: 500px;
	margin-right: auto;
	text-align: center;
}

.btn {
	width: 100%;
	height: 0.48rem;
	background-color: transparent;
	color: #337ecc;
}

.text {
	height: 0.48rem;
}

.visitor-login >>> .el-form-item__content{
	justify-content: center;
	text-align: center;
}

.menu{
	width: 1037px;
	height: 200px;
	background-image: url('~assets/img/MENU.png');
	background-repeat: no-repeat;
	margin-top: auto;
	margin-left: auto;
	filter: contrast(150%);
	display: flex;
}
.menu>div{
	width: 33.3%;
	height: 100%;
	background-color: transparent;
}

.menu>div:hover{
	background-color: rgba(234,229,226,0.2);
	cursor: pointer;
	height: 150px;
}

/* 添加备案信息的样式 */
.beian-info {
  position: absolute;
  bottom: 1px;
  width: 100%;
  text-align: center;
  color: #635149;
  font-size: 14px;
}

.beian-info a {
  color: #635149;
  text-decoration: none;
}
</style>
