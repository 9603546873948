import {
    defineStore
} from 'pinia'

export const useFillDrawerStore = defineStore('filldrawer', {
    state: () => ({
        color: '',
        contentStack: [],
        ctx: null,
        img: null,
    }),
    actions: {
        destory() {

        },
        save() {

        },
        createPainter(ctx, img) {
            this.ctx = ctx
            this.img = img
            ctx.putImageData(img, 0, 0)
            this.contentStack.push(img)
        },
        /**
         * 使用泛洪法填充颜色，颜色来自this.color，首先要获取当前图像的imageData获得每个像素的rgb
         * @param {*} x 
         * @param {*} y 
         */
        fill(x, y) {
            var pixelStack = [];
            var imageData = ctx.getImageData(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
            var startPos = (y * imageData.width + x) * 4;
            var startColor = [
                imageData.data[startPos],
                imageData.data[startPos + 1],
                imageData.data[startPos + 2],
                imageData.data[startPos + 3]
            ];
            var targetColor=startColor
            if (!this.colorMatch(startColor, targetColor)) {
                return;
            }
            pixelStack.push([x, y]);
            while (pixelStack.length) {
                var newPos, x, y, pixelPos, reachLeft, reachRight;
                newPos = pixelStack.pop();
                x = newPos[0];
                y = newPos[1];

                pixelPos = (y * imageData.width + x) * 4;
                while (y-- >= 0 && this.colorMatch(startColor, targetColor)) {
                    pixelPos -= imageData.width * 4;
                }
                pixelPos += imageData.width * 4;
                ++y;
                reachLeft = false;
                reachRight = false;
                while (y++ < imageData.height - 1 && this.colorMatch(startColor, targetColor)) {
                    this.colorPixel(pixelPos, this.color);
                    if (x > 0) {
                        if (this.colorMatch(this.getPixelColor(pixelPos - 4), targetColor)) {
                            if (!reachLeft) {
                                pixelStack.push([x - 1, y]);
                                reachLeft = true;
                            }
                        } else if (reachLeft) {
                            reachLeft = false;
                        }
                    }
                    if (x < imageData.width - 1) {
                        if (this.colorMatch(this.getPixelColor(pixelPos + 4), targetColor)) {
                            if (!reachRight) {
                                pixelStack.push([x + 1, y]);
                                reachRight = true;
                            }
                        } else if (reachRight) {
                            reachRight = false;
                        }
                    }
                    pixelPos += imageData.width * 4;
                }
            }
            ctx.putImageData(imageData, 0, 0);
            this.contentStack.push(imageData)
        },
        draw() {

        },
        //此方法为内部方法，判断两个像素点颜色是否相同
        colorMatch(color1, color2) {
            return color1[0] === color2[0] && color1[1] === color2[1] && color1[2] === color2[2] && color1[3] === color2[3];
        },

        getPixelColor(pixelPos) {
            return [
                imageData.data[pixelPos],
                imageData.data[pixelPos + 1],
                imageData.data[pixelPos + 2],
                imageData.data[pixelPos + 3]
            ];
        },
        
        colorPixel(pixelPos, fillColor) {
            imageData.data[pixelPos] = fillColor[0];
            imageData.data[pixelPos + 1] = fillColor[1];
            imageData.data[pixelPos + 2] = fillColor[2];
            imageData.data[pixelPos + 3] = fillColor[3];
        },
    }
})
