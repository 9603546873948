<template>
	<i>
		当前逻辑分辨率 : {{this.logicalWidth}}x{{this.logicalHeight}}
	</i>
	<el-icon size="18" color="orange" class="middle" v-if="logicalWidth<1800"><WarningFilled /></el-icon>
	<el-icon size="18" color="orange" class="middle" v-else="logicalWidth<1800"><Select /></el-icon>
</template>
<script>
	import {WarningFilled,Select} from '@element-plus/icons-vue'
	export default {
		
		data() {
			return {
				logicalWidth: 0,
				logicalHeight: 0
			}
		},
		
		methods:{
			updatePixel(){
				this.logicalWidth=window.innerWidth
				this.logicalHeight=window.innerHeight
			},
		},
		
		mounted() {
			this.updatePixel()
			window.addEventListener('resize',this.updatePixel)
		},
		
		components:{
			 WarningFilled,
			 Select
		}
	}
</script>

<style scoped>
	.middle{
		margin-left: 10px;
		vertical-align: text-bottom;
	}
</style>
