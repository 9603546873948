import Observer from "./Observer.js"

export default class CtxStatus {
	constructor(scaleX, scaleY, translateX, translateY, rotate, fatherStatus) {
		this._scaleX = scaleX
		this._scaleY = scaleY
		this._translateX = translateX
		this._translateY = translateY
		this._rotate = rotate
		this.observers = []
		this.sonStatus = []
		//对称模式 null代表默认，0代表无对称 1代表左右对称 2代表上下对称 3代表上下左右对称 4代表以y=x y=-x x=0 y=0四条轴对称 5八条轴对称...6十六条
		this.sysMode = null
		this.lineWidth = {
			inner: 1,
			outter: 1
		}
		this.strokeColor = {
			inner: '#000000',
			outter: '#000000',
		}
		if (fatherStatus) {
			this.fatherStatus = fatherStatus
			//this.fatherStatus.addObserver(new Observer())
			this.fatherStatus.sonStatus.push(this)
			this.matrix = new DOMMatrix([
				Math.cos(rotate) * scaleX, //1
				Math.sin(rotate) * scaleX, //0
				-Math.sin(rotate) * scaleY, //0
				Math.cos(rotate) * scaleY, //1
				translateX * fatherStatus.scaleX, //0 这里要说明一下，在父元素有缩放倍数时，子元素平移要基于父元素缩放倍数mu
				translateY * fatherStatus.scaleY //0
			]).multiplySelf(this.fatherStatus.matrix);
		} else {
			this.fatherStatus = null
			this.matrix = new DOMMatrix([
				Math.cos(rotate) * scaleX, //1
				Math.sin(rotate) * scaleX, //0
				-Math.sin(rotate) * scaleY, //0
				Math.cos(rotate) * scaleY, //1
				translateX, //0
				translateY //0
			])
			// if(fatherStatus!=null)
			// {
			// 	this.fatherStatus = new CtxStatus(1,1,0,0,0,null)
			// }		
			// else{
			// 	this.fatherStatus={
			// 		scaleX:1,
			// 		scaleY:1,
			// 		translateX:0,
			// 		translateY:0,
			// 		rotate:0
			// 	}
			// }
		}
		this.invertMatrix = this.matrix.inverse()
	}

	//在父元素变化的时候子元素的矩阵要自动重算
	addObserver(ob) {
		this.observers.push(ob)
	}
	notify(obj) {
		this.observers.forEach((item) => {
			item.update(obj)
		})
	}

	set fatherStatus(val) {
		this._fatherStatus = val
	}

	get fatherStatus() {
		return this._fatherStatus
	}

	set scaleX(val) {
		this._scaleX = val
		this.matrix.a = Math.cos(this.rotate) * val * (this.fatherStatus ? this.fatherStatus.scaleX : 1)
		this.matrix.b = Math.sin(this.rotate) * val * (this.fatherStatus ? this.fatherStatus.scaleX : 1)
		this.invertMatrix = this.matrix.inverse()

		for (let i = 0; i < this.sonStatus.length; i++) {
			let item = this.sonStatus[i]
			item.matrix.a = Math.cos(item.rotate) * item.scaleX * val
			item.matrix.b = Math.sin(item.rotate) * item.scaleX * val
			item.matrix.e = item.translateX * val + this.translateX
			item.matrix.f = item.translateY * val + this.translateY
			item.invertMatrix = item.matrix.inverse()
		}
		this.notify(this)
	}

	get scaleX() {
		return this._scaleX
	}

	set scaleY(val) {
		this._scaleY = val
		this.matrix.c = -Math.sin(this.rotate) * val * (this.fatherStatus ? this.fatherStatus.scaleY : 1)
		this.matrix.d = Math.cos(this.rotate) * val * (this.fatherStatus ? this.fatherStatus.scaleY : 1)
		this.invertMatrix = this.matrix.inverse()
		for (let i = 0; i < this.sonStatus.length; i++) {
			let item = this.sonStatus[i]
			item.matrix.c = -Math.sin(item.rotate) * item.scaleY * val
			item.matrix.d = Math.cos(item.rotate) * item.scaleY * val
			item.matrix.e = item.translateX * val + this.translateX
			item.matrix.f = item.translateY * val + this.translateY
			item.invertMatrix = item.matrix.inverse()
		}
		this.notify(this)
	}

	get scaleY() {
		return this._scaleY
	}
	//平移并不是叠加，而是重置
	set translateX(val) {
		this._translateX = val
		this.matrix.e = val * (this.fatherStatus ? this.fatherStatus.scaleX : 1) + (this.fatherStatus ? this
			.fatherStatus.translateX : 0)
		this.invertMatrix = this.matrix.inverse()
		for (let i = 0; i < this.sonStatus.length; i++) {
			let item = this.sonStatus[i]
			item.matrix.e = item.translateX * this.scaleX + val
			item.invertMatrix = item.matrix.inverse()
		}
		//通知旋转盘移动
		this.notify(this)
	}

	get translateX() {
		return this._translateX
	}

	set translateY(val) {
		this._translateY = val
		this.matrix.f = val * (this.fatherStatus ? this.fatherStatus.scaleY : 1) + (this.fatherStatus ? this
			.fatherStatus.translateY : 0)
		this.invertMatrix = this.matrix.inverse()
		for (let i = 0; i < this.sonStatus.length; i++) {
			let item = this.sonStatus[i]
			item.matrix.f = item.translateY * this.scaleY + val
			item.invertMatrix = item.matrix.inverse()
		}
		this.notify(this)
	}

	get translateY() {
		return this._translateY
	}

	set rotate(val) {
		this._rotate = val
		if (this.fatherStatus) {
			this.matrix.a = Math.cos(val + (this.fatherStatus.rotate)) * this.scaleX * this.fatherStatus.scaleX
			this.matrix.b = Math.sin(val + (this.fatherStatus.rotate)) * this.scaleX * this.fatherStatus.scaleX
			this.matrix.c = -Math.sin(val + (this.fatherStatus.rotate)) * this.scaleY * this.fatherStatus.scaleY
			this.matrix.d = Math.cos(val + (this.fatherStatus.rotate)) * this.scaleY * this.fatherStatus.scaleY
		} else {
			this.matrix.a = Math.cos(val) * this.scaleX
			this.matrix.b = Math.sin(val) * this.scaleX
			this.matrix.c = -Math.sin(val) * this.scaleY
			this.matrix.d = Math.cos(val) * this.scaleY
		}
		this.invertMatrix = this.matrix.inverse()
		for (let i = 0; i < this.sonStatus.length; i++) {
			let item = this.sonStatus[i]
			item.matrix.a = Math.cos(item.rotate + val) * item.scaleX
			item.matrix.b = Math.sin(item.rotate + val) * item.scaleX
			item.matrix.c = -Math.sin(item.rotate + val) * item.scaleY
			item.matrix.d = Math.cos(item.rotate + val) * item.scaleY
			item.invertMatrix = item.matrix.inverse()
		}
		this.notify(this)
	}

	get rotate() {
		return this._rotate
	}

	copy() {
		return new CtxStatus(this.scaleX, this.scaleY, this.translateX, this.translateY, this.rotate, this
			.fatherStatus)
	}

	toOffsetXY(x, y) {
		var point = new DOMPoint(x, y)
		return point.matrixTransform(this.invertMatrix)
	}
	/**
	 * 此方法获取一个去除父坐标系变化的变换矩阵
	 **/
	getMatrixWithoutFatherTransform() {
		// if(this.fatherStatus){
		// 	return this.fatherStatus.matrix.inverse().multiply(this.matrix)
		// 	//return this.matrix.multiply(this.fatherStatus.matrix.inverse)
		// }
		// else {
		// 	return this.matrix
		// }
		return new DOMMatrix([
			Math.cos(this.rotate) * this.scaleX, //1
			Math.sin(this.rotate) * this.scaleX, //0
			-Math.sin(this.rotate) * this.scaleY, //0
			Math.cos(this.rotate) * this.scaleY, //1
			this.translateX, //0 这里要说明一下，在父元素有缩放倍数时，子元素平移要基于父元素缩放倍数mu
			this.translateY //0
		])
	}
}
