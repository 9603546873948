import Painter from "./Painter.js";
import CProfile from '../CompositionShape/CProfile.js'
import TurnTable from "../YeahDrawer/TurnTable.js";
import $Event from "../YeahDrawer/$Event.js";
import Profile from "../SingleShape/Profile.js";
import CGeneCase from '../CompositionShape/CGeneCase.js'
import CImg from "../CompositionShape/CImg.js";
import {
	SVG
} from '@svgdotjs/svg.js';
export default class ProfilePainter extends Painter {
	constructor(ctx) {
		if (arguments.length < 1) {
			throw new Error('参数数量不对')
		}
		super(...arguments)
		//默认id
		this.profileId = '未命名' + Date.now()
		this.turnTable = new TurnTable(0, 0, 80, this.ctx)
		this._onEditShape = null

		//此属性控制在编辑状态下旋转盘是否显示
		this.isShowTurnTable = false
		this.addChildShape(this.turnTable)
		//初始为非对称模式
		this.transformSym = this.TransformNone
		//this.transformSymSvg=this.TransformNoneSvg
		this.contentSizeX = 200
		this.contentSizeY = 200
		this.defaultMouseDown = this.onMousedown__
		this.genes = []
	}

	buildProfile(profile, geneCaseMap, ctx) {
		if (profile && geneCaseMap) {
			var painter = this
			var temp = null
			if (profile instanceof CProfile) {
				temp = profile._profile.profileJsonObject
			} else if (profile instanceof Profile) {
				temp = profile.profileJsonObject
			} else if (profile instanceof Object) {
				temp = profile
			} else if (profile instanceof String) {
				temp = JSON.parse(profile)
			}
			this.profileId = temp.profileId
			this.contentSizeX = Number(temp.size.x)
			this.contentSizeY = Number(temp.size.y)
			this.info = temp.info || new ProfileInfo()
			switch (temp.symMode) {
				case '4':
					this.transformSym = this.TransformTriangle
					break;
				case '3':
					this.transformSym = this.TransformRectangle
					break;
				case '0':
					this.transformSym = this.TransformNone
					break;
				case '1':
					this.transformSym = this.TransformLR
					break;
				case '2':
					this.transformSym = this.TransformUB
					break;
				default:
					this.transformSym = this.TransformNone
					break;
			}
			temp.genes.forEach((item) => {
				var length = item.cas.length
				item.cas.forEach((subItem) => {
					var gcas = geneCaseMap.get(item.gid + '-' + parseInt(subItem.gCase));
					var genecas = new CGeneCase(item.gid, gcas, ctx);
					this.addChildShape(genecas)
					this.genes.push(genecas)
					var deg = Number(subItem.deg);
					var coordinate = subItem.coordinate;
					var tx = Number(coordinate.x);
					var ty = Number(coordinate.y);
					var scaleX = Number(subItem.scale.x)
					var scaleY = Number(subItem.scale.y)
					if (subItem.symMode != null) {
						var sysMode = Number(subItem.symMode)
					} else {
						var sysMode = null
					}
					genecas.ctxStatus.translateX = tx
					genecas.ctxStatus.translateY = ty
					genecas.ctxStatus.rotate = -deg * Math.PI / 180
					genecas.ctxStatus.scaleX = scaleX
					genecas.ctxStatus.scaleY = scaleY
					genecas.ctxStatus.sysMode = sysMode
					genecas.confirm()
				})
			})
		} else {
			this.transformSym = this.TransformNone
			this.contentSizeX = 200
			this.contentSizeY = 200
			this.info = new ProfileInfo()
		}
	}

	changeModel() {
		switch (this.transformSym) {
			case this.TransformTriangle:
				return '4'
			case this.TransformRectangle:
				return '3'
			case this.TransformNone:
				return '0';
			case this.TransformLR:
				return '1';
			case this.TransformUB:
				return '2';
		}
	}

	/**
	 * 重写painter类的confirm方法，在按下回车键时触发
	 **/
	confirm(gene) {
		if (gene) {
			//gene.confirm()
			this.onEditShape = null
			//this.hiddeTurnTable()
			this._lastOperateShape = null
		} else if (this._lastOperateShape != null) {
			//this._lastOperateShape.confirm()
			this.onEditShape = null
			//this.hiddeTurnTable()
			this._lastOperateShape = null
		}
	}

	draw() {
		// var fps = this.getFps()
		var start = performance.now()
		this.__lineDashOffset__ += 0.1
		if (this.__lineDashOffset__ > 32) {
			this.__lineDashOffset__ = 0
		}
		this.ctx.save()
		this.ctx.clearRect(-10000, -10000, 20000, 20000)
		this.__transform__()
		this.drawBackgroundGrid()
		this.ctx.lineDashOffset = -this.__lineDashOffset__
		this.$drawChildShape()
		//this.showCoordinateSystem()
		//this.drawFps(fps.toFixed(0))
		this.ctx.restore()
		if (this.frameCount) {
			var lasted = performance.now() - start
			this.frameCount++
			this.drawingTime += lasted
		}
		this.timer = requestAnimationFrame(() => {
			this.draw()
		})
	}

	$drawChildShape() {
		for (let i = 0; i < this.childShapes.length; i++) {
			this.childShapes[i].draw()
			//console.log(this.childShapes[i] instanceof TurnTable)
			//这边可以优化，单独画TurnTable TO DO
			if ((this.childShapes[i] instanceof CGeneCase)) {
				if (this.childShapes[i].ctxStatus.sysMode == null) {
					this.transformSym(this.childShapes[i])
				} else if (this.childShapes[i].ctxStatus.sysMode == 1) {
					this.TransformLR(this.childShapes[i])
				} else if (this.childShapes[i].ctxStatus.sysMode == 2) {
					this.TransformUB(this.childShapes[i])
				} else if (this.childShapes[i].ctxStatus.sysMode == 3) {
					this.TransformRectangle(this.childShapes[i])
				} else if (this.childShapes[i].ctxStatus.sysMode == 4) {
					this.TransformTriangle(this.childShapes[i])
				}
			}
		}
	}

	$dbclick(event) {
		this.$eventTarget = this
		var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
		this.ctx.save()
		this.__transform__()
		//不传播
		this.onDbclick__($event)
		this.ctx.restore()
	}

	$getChildShape(profile, geneCaseMap, ctx) {}

	getBasicLineNumber() {
		var res = 0
		var multiPower = 1 //倍率
		if (this.transformSym == this.TransformTriangle) {
			multiPower = 8
		} else if (this.transformSym == this.TransformRectangle) {
			multiPower = 4
		} else if (this.transformSym == this.TransformLR || this.transformSym == this.TransformUB) {
			multiPower = 2
		} else {
			multiPower = 1
		}
		this.childShapes.forEach((item) => {
			if (item instanceof CGeneCase) {
				var itemMultiPower = 1
				if (item.ctxStatus.sysMode == null) {
					itemMultiPower = multiPower
				} else if (item.ctxStatus.sysMode == 0) {
					itemMultiPower = 1
				} else if (item.ctxStatus.sysMode == 1 || item.ctxStatus.sysMode == 2) {
					itemMultiPower = 2
				} else if (item.ctxStatus.sysMode == 3) {
					itemMultiPower = 4
				} else if (item.ctxStatus.sysMode == 4) {
					itemMultiPower = 8
				} else {
					throw new Error('sysMode错误')
				}
				res += item._geneCase.caseJsonObject.parts.length * itemMultiPower
			}
		})
		return res
	}

	onDbclick__($event) {
		for (let i = 0; i < this.childShapes.length; i++) {
			let item = this.childShapes[i]
			if (!(item instanceof TurnTable) && (!item.isEdit) && item.$isPointInPath($event.clientX, $event
					.clientY)) {
				this._lastOperateShape = this.childShapes[i]
				this.onEditShape = item
				break
			}
		}
	}

	//此事件向外暴露
	onEditShapeChange() {

	}

	onkeyDel__(event) {
		
		var delShape = null
		for (let i = 0; i < this.childShapes.length; i++) {
			if (this.childShapes[i] == this._lastOperateShape) {
				if (!(this.childShapes[i] instanceof TurnTable)) {
					delShape = this.childShapes[i]
					this.childShapes.splice(i, 1)
					this._lastOperateShape = null
					this.onEditShape = null
					break
				}
			}
		}
		
		if (delShape) {
			for (let i = 0; i < this.genes.length; i++) {
				if (this.genes[i] == delShape) {
					this.genes.splice(i, 1)
				}
			}
		}
	}

	preparedToEdit(cgenecas) {
		this.preparedShape = cgenecas
		//鼠标点击放置一个图形
		this.onMousedown__ = function($event) {
			$event.stopPropagation()
			var downPoint = this.toOffsetXY($event.clientX, $event.clientY)
			var cx = cgenecas._geneCase.size.x / 2
			var cy = cgenecas._geneCase.size.y / 2
			this.addChildShape(cgenecas)
			this.genes.push(cgenecas)
			this.onEditShape = cgenecas
			//鼠标点击放置位置为基因的中心
			cgenecas.ctxStatus.translateX = (downPoint.x - cx)
			cgenecas.ctxStatus.translateY = (downPoint.y - cy)
			this.onMousedown__ = this.defaultMouseDown
		}
	}

	/**显示局部坐标系**/
	showCoordinateSystem() {
		this.ctx.beginPath()
		var sizeX = this.contentSizeX
		var sizeY = this.contentSizeY
		this.ctx.moveTo(0, sizeY / 2)
		this.ctx.lineTo(sizeX, sizeY / 2)
		this.ctx.moveTo(sizeX / 2, 0)
		this.ctx.lineTo(sizeX / 2, sizeY)
		this.ctx.moveTo(sizeX / 2, sizeY)
		this.ctx.lineTo(sizeX / 2 + 4, sizeY - 4)
		this.ctx.moveTo(sizeX, sizeY / 2)
		this.ctx.lineTo(sizeX - 4, sizeY / 2 + 4)
		this.ctx.strokeStyle = this.coordinateSystemColor
		this.ctx.stroke()
	}


	save() {
		let patternSize = {
			x: this.contentSizeX,
			y: this.contentSizeY
		};
		let sysMode = this.changeModel();
		let genes = []
		for (let i = 0; i < this.childShapes.length; i++) {
			if (!(this.childShapes[i] instanceof TurnTable)) {
				let gid = this.childShapes[i]._geneCase.gid;
				let cid = this.childShapes[i]._geneCase.caseJsonObject.cid;
				let ctxStatus = this.childShapes[i].ctxStatus;
				let scale = {
					x: this.childShapes[i].ctxStatus.scaleX,
					y: this.childShapes[i].ctxStatus.scaleY
				};
				let casObj = {
					"deg": -ctxStatus.rotate / Math.PI * 180,
					"coordinate": {
						x: ctxStatus.translateX,
						y: ctxStatus.translateY
					},
					"gCase": cid,
					"scale": scale,
					"symMode": ctxStatus.sysMode,
				};

				//判断gid是否相同，相同则加入到cas数组中去
				let gidRepeatFlag = false;
				for (let j = 0; j < genes.length; j++) {
					if (gid == genes[j].gid) {
						genes[j].cas.push(casObj);
						gidRepeatFlag = true;
					}
				}
				//没有，则放入一个新的
				if (!gidRepeatFlag) {
					let tmpObj = {
						"gid": gid,
						"cas": [casObj]
					}
					genes.push(tmpObj);
				}
			}
		}
		let profile = {
			profileId: this.profileId,
			genes: genes,
			repeatMode: [{
				x: 0,
				y: 0
			}],
			size: patternSize,
			symMode: sysMode,
			info: this.info
		};
		return profile
	}
	/**
	 * 将画板内容转化为svg
	 * @returns {String} svg字符串
	 **/
	transFormIntoSvg() {
		var svgDrawer = new SVG()
		svgDrawer.size(this.contentSizeX, this.contentSizeY).viewbox(0, 0, this.contentSizeX, this.contentSizeY)
			.stroke('black').fill('none')
		this.childShapes.forEach((item) => {
			if (item instanceof CGeneCase) {
				let path = item.getSvgPath()
				// let {translateX,translateY,scaleX,scaleY,rotate}=item.ctxStatus
				//rotate=rotate*180/Math.PI
				let initMatrix = item.ctxStatus.getMatrixWithoutFatherTransform()
				svgDrawer.path(path).transform(initMatrix)
				if (item.ctxStatus.sysMode == null) {
					//判断全局对称模式
					switch (this.transformSym) {
						case this.TransformTriangle:
							this.TransformTriangleSvg(svgDrawer, path, item)
							break
						case this.TransformRectangle:
							this.TransformRectangleSvg(svgDrawer, path, item)
							break
						case this.TransformNone:
							this.TransformNoneSvg(svgDrawer, path, item)
							break
						case this.TransformLR:
							this.TransformLRSvg(svgDrawer, path, item)
							break
						case this.TransformUB:
							this.TransformUBSvg(svgDrawer, path, item)
							break
						default:
							throw new Error('类型不存在')
							break
					}
				}
				if (item.ctxStatus.sysMode == 0) {
					this.TransformNoneSvg(svgDrawer, path, item)
				}
				if (item.ctxStatus.sysMode == 1) {
					this.TransformLRSvg(svgDrawer, path, item)
				}
				if (item.ctxStatus.sysMode == 2) {
					this.TransformUBSvg(svgDrawer, path, item)
				}
				if (item.ctxStatus.sysMode == 3) {
					this.TransformRectangleSvg(svgDrawer, path, item)
				}
				if (item.ctxStatus.sysMode == 4) {
					this.TransformTriangleSvg(svgDrawer, path, item)
				}
			}
		})
		return svgDrawer.svg()
	}

	/**
	 * 对称模式
	 **/
	TransformRectangle(cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		var initMatrix = this.ctxStatus.matrix.translate(tx, ty)
		var m1 = initMatrix.flipY()
		var m2 = initMatrix.flipX()
		var m3 = m1.flipX()
		this.ctx.save()
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)
		this.ctx.setTransform(m1.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		this.ctx.setTransform(m2.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		this.ctx.setTransform(m3.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		this.ctx.restore()
	}
	TransformRectangleSvg(svgDrawer, path, cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		var initMatrix = new DOMMatrix().translate(tx, ty)
		var m1 = initMatrix.flipY()
		var m2 = initMatrix.flipX()
		var m3 = m1.flipX()
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)
		svgDrawer.path(path).transform(m1.multiply(tempMatrix))
		svgDrawer.path(path).transform(m2.multiply(tempMatrix))
		svgDrawer.path(path).transform(m3.multiply(tempMatrix))
	}
	TransformNone(cgenecas) {}
	TransformNoneSvg(svgDrawer) {}
	TransformTriangle(cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		this.ctx.save()
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var initMatrix = this.ctxStatus.matrix.translate(tx, ty)
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)
		var m1 = initMatrix.rotate(90)
		this.ctx.setTransform(m1.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		var m2 = initMatrix.flipY()
		this.ctx.setTransform(m2.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		var m21 = m2.rotate(90)
		this.ctx.setTransform(m21.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		var m3 = m2.flipX();
		this.ctx.setTransform(m3.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		var m31 = m3.rotate(90);
		this.ctx.setTransform(m31.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		var m4 = initMatrix.flipX()
		this.ctx.setTransform(m4.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		var m41 = m4.rotate(90);
		this.ctx.setTransform(m41.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		this.ctx.restore();
	}
	TransformTriangleSvg(svgDrawer, path, cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var initMatrix = new DOMMatrix().translate(tx, ty)
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)
		var m1 = initMatrix.rotate(90)
		var m2 = initMatrix.flipY()
		var m21 = m2.rotate(90)
		var m3 = m2.flipX();
		var m31 = m3.rotate(90);
		var m4 = initMatrix.flipX()
		this.ctx.setTransform(m4.multiply(tempMatrix))
		var m41 = m4.rotate(90);

		svgDrawer.path(path).transform(m1.multiply(tempMatrix))
		svgDrawer.path(path).transform(m2.multiply(tempMatrix))
		svgDrawer.path(path).transform(m21.multiply(tempMatrix))
		svgDrawer.path(path).transform(m3.multiply(tempMatrix))
		svgDrawer.path(path).transform(m31.multiply(tempMatrix))
		svgDrawer.path(path).transform(m4.multiply(tempMatrix))
		svgDrawer.path(path).transform(m41.multiply(tempMatrix))
	}
	//左右
	TransformLR(cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		var initMatrix = this.ctxStatus.matrix.translate(tx, ty)
		var m1 = initMatrix.flipX()
		this.ctx.save()
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)
		this.ctx.setTransform(m1.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		this.ctx.restore()
	}
	TransformLRSvg(svgDrawer, path, cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		var initMatrix = new DOMMatrix().translate(tx, ty)
		var m1 = initMatrix.flipX()
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)

		svgDrawer.path(path).transform(m1.multiply(tempMatrix))
	}
	//上下
	TransformUB(cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		var initMatrix = this.ctxStatus.matrix.translate(tx, ty)
		var m1 = initMatrix.flipY()
		this.ctx.save()
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)
		this.ctx.setTransform(m1.multiply(tempMatrix))
		cgenecas._geneCase.draw()
		this.ctx.restore()
	}
	TransformUBSvg(svgDrawer, path, cgenecas) {
		var tx = this.contentSizeX / 2
		var ty = this.contentSizeY / 2
		var initMatrix = new DOMMatrix().translate(tx, ty)
		var m1 = initMatrix.flipY()
		var tx2 = cgenecas.ctxStatus.translateX
		var ty2 = cgenecas.ctxStatus.translateY
		var scaleX = cgenecas.ctxStatus.scaleX
		var scaleY = cgenecas.ctxStatus.scaleY
		var angle = cgenecas.ctxStatus.rotate * 180 / Math.PI
		var tempMatrix = new DOMMatrix().translate(-tx, -ty).translate(tx2, ty2).rotate(angle).scale(scaleX, scaleY)

		svgDrawer.path(path).transform(m1.multiply(tempMatrix))
	}


	/**
	 * 这个值设置为true后，如有图形正在编辑则显示对应的旋转盘，如果没有图形在编辑，则不显示
	 * 这个值设置为false后则不显示旋转盘
	 **/
	set isShowTurnTable(val) {
		this._isShowTurnTable = val
		if (val) {
			this.turnTable.show(50)
		} else {
			this.turnTable.hidden()
		}
	}

	get isShowTurnTable() {
		return this._isShowTurnTable
	}

	/**
	 * 此状态变化时，旋转盘绑定的对象变化
	 **/
	set onEditShape(val) {

		//如果存在正在编辑的图形，则把正在编辑的图形确认编辑
		if (this._onEditShape) {
			if (this.onEditShape instanceof CGeneCase) {
				this._onEditShape.confirm()
			} else if (this.onEditShape instanceof CImg) {
				//do no thing
			}
		}
		this._onEditShape = val
		//设置为null则绑定为null并隐藏
		if (val) {
			if (val instanceof CImg) {
				this.turnTable.bind(null)
				this.isShowTurnTable = false
			} else if (val instanceof CGeneCase) {
				this._onEditShape.edit()
				this.turnTable.bind(val.boundryPoints[0])
			}
		} else {
			this.turnTable.bind(null)
			this.isShowTurnTable = false
		}
		//回调
		this.onEditShapeChange()
	}

	get onEditShape() {
		return this._onEditShape
	}
}

class ProfileInfo {
	constructor(date, nation, sign, useage) {
		this.date = date || '未定义'
		this.nation = nation || '未定义'
		this.sign = sign || '未定义'
		this.useage = useage || ['未定义']
	}
	addUseage(item) {
		this.useage.push(item)
	}
}
