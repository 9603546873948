import LineShape from "../YeahDrawer/LineShape.js";

export default class Curve extends LineShape {
	constructor(startX, startY, p1X, p1Y, p2X, p2Y, p3X, p3Y, ctx) {
		super(ctx)
		this.startX = startX
		this.startY = startY
		this.p1X = p1X
		this.p1Y = p1Y
		this.p2X = p2X
		this.p2Y = p2Y
		this.p3X = p3X
		this.p3Y = p3Y
	}
	
	$setStrokePath(){
		//再父类调用该方法时这些值还没有给定
		this.strokePath=new Path2D()
		this.strokePath.moveTo(this.startX, this.startY)
		this.strokePath.bezierCurveTo(this.p1X, this.p1Y, this.p2X, this.p2Y, this.p3X, this.p3Y)
	}
	
	// draw() {
	// 	this.ctx.beginPath()
	// 	this.ctx.moveTo(this.startX, this.startY)
	// 	this.ctx.bezierCurveTo(this.p1X, this.p1Y, this.p2X, this.p2Y, this.p3X, this.p3Y)
	// 	this.ctx.stroke()
	// }

	// $isPointInPath(x, y) {
	// 	// this.ctx.beginPath()
	// 	// this.ctx.moveTo(this.startX, this.startY)
	// 	// this.ctx.bezierCurveTo(this.p1X, this.p1Y, this.p2X, this.p2Y, this.p3X, this.p3Y)
	// 	return this.ctx.isPointInStroke(this.strokePath,x, y)
	// }
	

}
