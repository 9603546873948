import Painter from "./Painter.js";
import CProfile from '../CompositionShape/CProfile.js'
import TurnTable from "../YeahDrawer/TurnTable.js";
import $Event from "../YeahDrawer/$Event.js"
/**
 * 说明：为了提高性能，每个图案均采用位图而非矢量图绘制
 * 1.使用profile.js绘制一次，获取“底色透明”的位图，
 * profile的draw方法要绘制图片，还要一个生成图片的方法
 * 2.使用Cprofile装载profile
 * 3.在缩放时缩放比例的区间，超过一个值就通知profile进行重绘
 * （分为世界坐标系的缩放与用户坐标系的缩放），然后重新生成图片
 * 4.获取背景图片需要处理imgdata 然后putImgdata->toDataUrl
 **/
export default class PatternPainter extends Painter {
	constructor(ctx) {
		super(...arguments)
		this.turnTable = new TurnTable(0, 0, 60, this.ctx)
		this._onEditShape = null
		this.isShowTurnTable = false
		this.addChildShape(this.turnTable)
		this.contentSizeX = 200
		this.contentSizeY = 200
		this.defaultMouseDown = this.onMousedown__
	}

	draw() {
		this.__lineDashOffset__ += 0.1
		if (this.__lineDashOffset__ > 32) {
			this.__lineDashOffset__ = 0
		}
		this.ctx.save()
		this.ctx.clearRect(-5000, -5000, 10000, 10000)
		this.__transform__()
		this.drawBackgroundGrid()
		this.ctx.lineDashOffset = -this.__lineDashOffset__
		this.$drawChildShape()
		this.ctx.restore()
		this.timer = requestAnimationFrame(() => {
			this.draw()
		})
	}


	buildPattern(pattern, geneCasMap, ctx) {
		if (pattern) {
			this.pid = pattern.pid
			this.size = {
				x: Number(pattern.size.x),
				y: Number(pattern.size.y),
			}
			this.url = pattern.url
			if (geneCasMap) {
				pattern.profiles.forEach((item) => {
					item.repeatMode.forEach((subItem) => {
						let cprofile = new CProfile(item, geneCasMap, ctx)
						this.addChildShape(cprofile)
						cprofile.ctxStatus.translateX = Number(subItem.coordinate.x)
						cprofile.ctxStatus.translateY = Number(subItem.coordinate.y)
						cprofile.ctxStatus.scaleX = Number(subItem.scale.x)
						cprofile.ctxStatus.scaleY = Number(subItem.scale.y)
						cprofile.ctxStatus.rotate = Number(subItem.deg)
						cprofile.confirm()
					})
				})
			} else {
				throw new Error('请传入geneCasMap')
			}
		} else {
			this.pid = '未命名' + Date.now()
			this.size = {
				x: 200,
				y: 200
			}
			this.url = ''
		}
	}
	//要缩小一个profile的话,genes的scale和translate都要缩小,整个profile的size也要缩小，
	//先基于图片缩放比例缩小，不去真正的缩小上述属性，保存的时候再修改上述属性
	/**
	 * @param pattern{Object} pattern的JSON对象
	 **/
	$getChildShape(pattern, geneCasMap, ctx) {}

	$dbclick(event) {
		this.$eventTarget = this
		var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
		this.ctx.save()
		this.__transform__()
		//不传播
		this.onDbclick__($event)
		this.ctx.restore()
	}

	onDbclick__($event) {
		for (let i = 0; i < this.childShapes.length; i++) {
			let item = this.childShapes[i]
			//如果图形没有正在编辑且鼠标落在图形内部
			if ((!item.isEdit) && item.$isPointInPath($event.clientX, $event.clientY)) {
				this._lastOperateShape = this.childShapes[i]
				this.onEditShape = item
				break
			}
		}
	}

	onkeyEnter__($event) {
		this.confirm()
		this.ctx.canvas.style.cursor = 'default'
	}

	preparedToEdit(cprofile) {
		this.preparedShape = cprofile
		//TODO 鼠标点击放置一个图形
		this.onMousedown__ = function($event) {
			var downPoint = this.toOffsetXY($event.clientX, $event.clientY)
			var cx = cprofile._profile.size.x / 2
			var cy = cprofile._profile.size.y / 2
			this.addChildShape(cprofile)

			this.onEditShape = cprofile
			//鼠标点击放置位置为基因的中心
			cprofile.ctxStatus.translateX = (downPoint.x - cx)
			cprofile.ctxStatus.translateY = (downPoint.y - cy)
			// this.onMousedown__=defaultMouseDown
		}
		this.onMouseup__ = function() {
			this.onMousedown__ = this.defaultMouseDown
		}
	}

	set onEditShape(val) {
		//如果存在正在编辑的图形，则把正在编辑的图形确认编辑
		if (this._onEditShape) {
			this._onEditShape.confirm()
		}
		//赋值
		this._onEditShape = val
		//设置为null则绑定为null并隐藏
		if (val) {
			//切换onEditshape时发生的变化
			if (!this._onEditShape.isEdit) {
				this._onEditShape.edit()
			}
			this.turnTable.bind(val.boundryPoints[0])
		} else {
			//这两句之间其实有很大耦合
			this.turnTable.bind(null)
			this.isShowTurnTable = false
		}
	}

	get onEditShape() {
		return this._onEditShape
	}

	set isShowTurnTable(val) {
		this._isShowTurnTable = val
		if (val) {
			this.turnTable.show(50)
		} else {
			this.turnTable.hidden()
		}
	}

	get isShowTurnTable() {
		return this._isShowTurnTable
	}

	confirm() {
		// this.onEditShape.confirm()
		this.onEditShape = null
	}

	/**存储为一个json对象**/
	save() {
		var res = {
			info: {},
			pid: this.pid,
			profiles: [],
			size: this.size,
			url: this.url,
		}
		var profileMap = new Map()
		this.childShapes.forEach((item) => {
			if (!(item instanceof TurnTable) && (!item.isEdit)) {
				//不变的属性
				let profile = item.profileJsonObject
				//会改变的属性
				profile.profileId = item.profileId
				//会改变的属性
				profile.repeatMode = []
				// {
				// 	coordinate: {
				// 		x: item.ctxStatus.translateX,
				// 		y: item.ctxStatus.translateY
				// 	},
				// 	scale: {
				// 		x: item.ctxStatus.scaleX,
				// 		y: item.ctxStatus.scaleY
				// 	},
				// 	deg: item.ctxStatus.rotate
				// }
				profileMap.set(profile.profileId, profile)
			}
		})
		this.childShapes.forEach((item) => {
			if (!(item instanceof TurnTable) && (!item.isEdit)) {
				item.profileId
				//会改变的属性
				let repeatModeItem = {
					coordinate: {
						x: item.ctxStatus.translateX,
						y: item.ctxStatus.translateY
					},
					scale: {
						x: item.ctxStatus.scaleX,
						y: item.ctxStatus.scaleY
					},
					deg: item.ctxStatus.rotate
				}
				//从map中获取profile的repeatMode数组并push
				let profile=profileMap.get(item.profileId)
				profile.repeatMode.push(repeatModeItem)
			}
		})
		res.profiles=Array.from(profileMap.values())
		return res
	}
}
