/**
 *对本地缓存存储进行管理
 *保存正在编辑的图案对象，防止刷新后消失
 **/
import GeneRaw from '../entity/GeneRaw.js'

export default {
	/**
	 * @param {GeneRaw} gene 
	 **/
	storeOneditGene: function(gene) {
		gene = JSON.stringify(gene)
		localStorage.setItem('storeOneditGene', gene)
		this.geneHistoryStack.push(gene)
		sessionStorage.setItem('geneHistoryStack', JSON.stringify(this.geneHistoryStack))
	},
	// TODO : 2023/2/30 返回一个GeneRaw对象而非普通Object
	/**
	 * @returns {GeneRaw}
	 **/
	getOneditGene: function() {
		var item = localStorage.getItem('storeOneditGene')
		if (item) {
			this.geneHistoryStack.push(('storeOneditGene', item))
			item = JSON.parse(item)
			var geneRaw = new GeneRaw(item.gid, item.info, item.profiles)
			return geneRaw
		} else
			return null
	},
	storeOneditProfile: function(profile) {
		profile = JSON.stringify(profile)
		localStorage.setItem('storeOneditProfile', profile)
		this.profileHistoryStack.push(profile)
		sessionStorage.setItem('profileHistoryStack', JSON.stringify((this.profileHistoryStack)))
	},
	getOneditProfile: function() {
		var item = localStorage.getItem('storeOneditProfile')
		if (item) {
			this.profileHistoryStack.push(('storeOneditProfile', item))
			item = JSON.parse(item)
			return item
		} else
			return null
	},
	storeOneditPattern: function(pattern) {
		pattern = JSON.stringify(pattern)
		localStorage.setItem('storeOneditPattern', pattern)
		// this.profileHistoryStack.push(pattern)
		// sessionStorage.setItem('profileHistoryStack', JSON.stringify((this.profileHistoryStack)))
	},
	getOneditPattern: function() {
		var item = localStorage.getItem('storeOneditPattern')
		if (item) {
			//this.profileHistoryStack.push(('storeOneditProfile', item))
			item = JSON.parse(item)
			return item
		} else
			return null
	},
	returnLastGeneStatus: function() {
		var item = this.geneHistoryStack.pop()
		this.geneRedoStack.push(item)
		return item
	},
	returnLastProfileStatus: function() {
		var item = this.profileHistoryStack.pop()
		this.profileRedoStack.push(item)
		return item
	},
	geneHistoryStack: [],
	geneRedoStack: [],
	profileHistoryStack: [],
	profileRedoStack: [],
}
