import LineShape from "../YeahDrawer/LineShape.js";

export default class Polygon extends LineShape {
	constructor(pointArr,ctx){
		super(ctx)
		//第一个参数内部放端点数组，以{x:1,y:1}的对象格式来存储
		this.pointArr=pointArr
		if(pointArr.length<1){
			throw new Error('polygon至少需要一个起始点')
		}
	}
	
	$setStrokePath(){
		this.strokePath=new Path2D()
		this.strokePath.moveTo(this.pointArr[0].x,this.pointArr[0].y)
		this.pointArr.forEach((item)=>{
			this.strokePath.lineTo(item.x,item.y)
		})
	}
	
	// draw(){
	// 	this.ctx.beginPath()
	// 	this.ctx.moveTo(this.pointArr[0].x,this.pointArr[0].y)
	// 	this.pointArr.forEach((item)=>{
	// 		this.ctx.lineTo(item.x,item.y)
	// 	})
	// 	this.ctx.stroke()
	// }
	
	// $isPointInPath(x,y){
	// 	//this.ctx.beginPath()
	// 	this.ctx.lineWidth=3
	// 	// this.ctx.moveTo(this.pointArr[0].x,this.pointArr[0].y)
	// 	// this.pointArr.forEach((item)=>{
	// 	// 	this.ctx.lineTo(item.x,item.y)
	// 	// })
	// 	var flag=this.ctx.isPointInStroke(this.strokePath,x,y)
	// 	this.ctx.lineWidth=1
	// 	return flag
	// }
}