import {
	createRouter,
	createWebHashHistory
} from "vue-router"
import GoolBar from '../pages/GoolBar.vue'
import PoolBar from '../pages/PoolBar.vue'
import ProfileToolBar from '../pages/ProfileToolBar.vue'
import CvsGeneLib from "../pages/CvsGeneLib.vue"
import CvsPatternLib from "../pages/CvsPatternLib.vue"
import CvsPatternDrawer from "../pages/CvsPatternDrawer.vue"
import CvsPatternProfileDrawer from '../pages/CvsPatternProfileDrawer.vue'
import CvsGeneDrawer from "../pages/CvsGeneDrawer.vue"
import GLib from "../pages/GLib.vue"
import PLib from "../pages/PLib.vue"
import Login from '../pages/Login.vue'
import Gallery from '~pages/extract-gene/context.vue'
import Stage from '~pages/extract-gene/components/Stage.vue'
import DrawingBoard from "../components/DrawingBoard.vue"
import FinfoBar from "~pages/info-bar/FinfoBar.vue"
import GinfoBar from "~pages/info-bar/GinfoBar.vue"
import PinfoBar from "~pages/info-bar/PinfoBar.vue"
import PLinfoBar from "~pages/info-bar/PLinfoBar.vue"
import PLtoolBar from "~pages/tool-bar/PLtoolBar.vue"
import segment from "~pages/segment/segment.vue"
import FillToolBar from "~pages/tool-bar/FillToolBar.vue"
import FillDrawer from "~pages/FillDrawer.vue"
import { Path } from "@svgdotjs/svg.js"
const routes = [
	{
		path: '/',
		components: {
			main: DrawingBoard,
		},
		meta: {
			requiresAuth: true
		},
		redirect: to => {
			return { path: '/DrawBoard/DrawGene' }
		},
	},
	{
		path: '/Login',
		components: {
			main: Login,
		},
		name: 'Login',
		meta: {
			requiresAuth: false,
			redirect: '/'
		},
		beforeEnter: (to, from) => {
			if (to.query && to.query.redirect) {
				to.meta.redirect = to.query.redirect
			} else {
				to.meta.redirect = '/'
			}
		},
	},
	{
		path: '/DrawBoard',
		components: {
			main: DrawingBoard
		},
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '',
				components: {
					toolbar: GoolBar,
					drawingboard: CvsGeneDrawer,
					infobar:GinfoBar
				},
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'DrawGene',
				components: {
					toolbar: GoolBar,
					drawingboard: CvsGeneDrawer,
					infobar:GinfoBar
				},
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'DrawPatternProfile',
				components: {
					toolbar: ProfileToolBar,
					drawingboard: CvsPatternProfileDrawer,
					infobar:PinfoBar
				},
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'DrawPattern',
				components: {
					toolbar: PoolBar,
					drawingboard: CvsPatternDrawer,
					infobar:FinfoBar
				},
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'Glib',
				components: {
					toolbar: GLib,
					drawingboard: CvsGeneLib
				},
				meta: {
					requiresAuth: false
				}
			},
			{
				path: 'Plib',
				components: {
					toolbar: PLtoolBar,
					drawingboard: CvsPatternLib,
					infobar:PLinfoBar
				},
				meta: {
					requiresAuth: false
				}
			},
			{
				path:'segment',
				components:{
					drawingboard:segment
				},
				meta: {
					requiresAuth: false
				}
			},
			{
				path:'fill',
				components:{
					toolbar:FillToolBar,
					drawingboard:FillDrawer,
				},
				meta:{
					requiresAuth:true
				}
			}
		]
	}
]
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

/*
全局路由拦截器，对未登录的情况做拦截，限制跳转
*/
router.beforeEach((to, from) => {
	if (to.path.startsWith('/Login')) {
		sessionStorage.removeItem("Authorization")
	}
	if (to.meta.requiresAuth) {
		var token = sessionStorage.getItem('Authorization')
		if (token && token != "undefined") {
			return true
		} else {
			if (to.name != 'Login') {
				return {
					name: 'Login',
					//登录成功后要跳转回原来的页面，这里记录原页面
					query: {
						redirect: to.fullPath
					},
					//返回的对象没有这个属性
					// props:{
					// 	drawingboard:to.fullPath
					// }
				}
			}
		}
	}
})

export default router
