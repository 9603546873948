<template>
	<div class="silder-bar">
		<span class="btn" @click="zoom('out')">-</span>
		<input type="range" min="80" max="300" v-model.number="currentScale" />
		<span class="btn" @click="zoom('in')">+</span>
		<span class='silder-value'>{{currentScale}}%</span>
	</div>
</template>

<script setup>
	import {
		computed
	} from 'vue'
	import {
		useRoute,
	} from 'vue-router'
	import {
		emitter
	} from '../bus/emitter.js'
	import {
		useGenedrawerStore
	} from '../store/genedrawer.js'
	import {
		useProfiledrawerStore
	} from '../store/profiledrawer.js'
	import {
		usePatterndrawerStore
	} from '../store/patterndrawer.js'
	const geneStore = useGenedrawerStore()
	const profileStore = useProfiledrawerStore()
	const patternStore = usePatterndrawerStore()
	const route = useRoute()

	var currentScale = computed({
		get() {
			var scaleShow = 100
			switch (route.path) {
				case '/':
					if (geneStore.painter) {
						scaleShow = Math.round(geneStore.painter.ctxStatus.scaleX * 100)
						return scaleShow
					}
					break;
				case '/DrawBoard/DrawGene':
					if (geneStore.painter) {
						scaleShow = Math.round(geneStore.painter.ctxStatus.scaleX * 100)
						return scaleShow
					}
					break;
				case '/DrawBoard/DrawPatternProfile':
					if (profileStore.painter) {
						scaleShow = Math.round(profileStore.painter.ctxStatus.scaleX * 100)
						return scaleShow
					}
					break;
				case '/DrawBoard/DrawPattern':
					if (patternStore.painter) {
						scaleShow = Math.round(patternStore.painter.ctxStatus.scaleX * 100)
						return scaleShow
					}
					break;
				default:
					return scaleShow
					break;
			}
		},
		set(val) {
			switch (route.path) {
				case '/':
					//判断画板是否被初始化，因为geneStore当时写的不是很好，只能用destory函数是否存在来判断
					if (geneStore.painter.destory) {
						geneStore.painter.ctxStatus.scaleX=val/100
						geneStore.painter.ctxStatus.scaleY=val/100
					}
					break;
				case '/DrawBoard/DrawGene':
					if (geneStore.painter.destory) {
						geneStore.painter.ctxStatus.scaleX=val/100
						geneStore.painter.ctxStatus.scaleY=val/100
					}
					break;
				case '/DrawBoard/DrawPatternProfile':
					if (profileStore.painter) {
						profileStore.painter.ctxStatus.scaleX=val/100
						profileStore.painter.ctxStatus.scaleY=val/100
					}
					break;
				case '/DrawBoard/DrawPattern':
					if (patternStore.painter) {
						patternStore.painter.ctxStatus.scaleX=val/100
						patternStore.painter.ctxStatus.scaleY=val/100
					}
					break;
				default:
					break;
			}
		}
	})

	function zoom(inOrOut) {
		switch (route.path) {
			case '/':
				//判断画板是否被初始化，因为geneStore当时写的不是很好，只能用destory函数是否存在来判断
				if (geneStore.painter.destory) {
					var scalex = geneStore.painter.ctxStatus.scaleX
					var scaley = geneStore.painter.ctxStatus.scaleY
					if (inOrOut == 'out') {
						scalex -= 0.1
						scaley -= 0.1
						geneStore.painter.ctxStatus.scaleX = scalex
						geneStore.painter.ctxStatus.scaleY = scaley
					}
					if (inOrOut == 'in') {
						scalex += 0.1
						scaley += 0.1
						geneStore.painter.ctxStatus.scaleX = scalex
						geneStore.painter.ctxStatus.scaleY = scaley
					}
				}
				break;
			case '/DrawBoard/DrawGene':
				if (geneStore.painter.destory) {
					var scalex = geneStore.painter.ctxStatus.scaleX
					var scaley = geneStore.painter.ctxStatus.scaleY
					if (inOrOut == 'out') {
						scalex -= 0.1
						scaley -= 0.1
						geneStore.painter.ctxStatus.scaleX = scalex
						geneStore.painter.ctxStatus.scaleY = scaley
					}
					if (inOrOut == 'in') {
						scalex += 0.1
						scaley += 0.1
						geneStore.painter.ctxStatus.scaleX = scalex
						geneStore.painter.ctxStatus.scaleY = scaley
					}
				}
				break;
			case '/DrawBoard/DrawPatternProfile':
				if (profileStore.painter) {
					var scalex = profileStore.painter.ctxStatus.scaleX
					var scaley = profileStore.painter.ctxStatus.scaleY
					if (inOrOut == 'out') {
						scalex -= 0.1
						scaley -= 0.1
						profileStore.painter.ctxStatus.scaleX = scalex
						profileStore.painter.ctxStatus.scaleY = scaley
					}
					if (inOrOut == 'in') {
						scalex += 0.1
						scaley += 0.1
						profileStore.painter.ctxStatus.scaleX = scalex
						profileStore.painter.ctxStatus.scaleY = scaley
					}
				}
				break;
			case '/DrawBoard/DrawPattern':
				if (patternStore.painter) {
					var scalex = patternStore.painter.ctxStatus.scaleX
					var scaley = patternStore.painter.ctxStatus.scaleY
					if (inOrOut == 'out') {
						scalex -= 0.1
						scaley -= 0.1
						patternStore.painter.ctxStatus.scaleX = scalex
						patternStore.painter.ctxStatus.scaleY = scaley
					}
					if (inOrOut == 'in') {
						scalex += 0.1
						scaley += 0.1
						patternStore.painter.ctxStatus.scaleX = scalex
						patternStore.painter.ctxStatus.scaleY = scaley
					}
				}
				break;
			default:
				break;
		}
	}
</script>

<style scoped src='../assets/css/silder.css'>
</style>
