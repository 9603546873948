<template>
	<div class="menu-warpper">
		<div class="menu-title" v-if="route.path=='/'||route.path=='/DrawGene'">
			{{store.gid}}-Ethnic Pattern Painter{{route.path}}
		</div>
		<div class="menu-title" v-else>
			Ethnic Pattern Painter{{route.path}}
		</div>
		<ul class="menu-items">
			<li tabindex="0" @blur="fBVisible=false">
				<div @click="fBVisible=true">
					<i class="iconfont icon-wenjianjia_o"></i>
					<span>文件</span>
				</div>
				<FileBlock v-show="fBVisible&&route.path!='/Glib'&&route.path!='/Plib'" @close='fBVisible=false' >
				</FileBlock>
			</li>
			<ul class="menu-second-items">
				<li @click="routeTo('pattern')" :class="{'active-li':drawpatternActive}">
					<i class="iconfont icon-pattern"></i>
					<span>一级图元</span>
				</li>
				<li @click="routeTo('profile')" :class="{'active-li':drawprofileActive}">
					<i class="iconfont icon-atomic"></i>
					<span>二级图元</span>
				</li>
				<li @click="routeTo('gene')" :class="{'active-li':drawgeneActive}">
					<i class="iconfont icon-jiyin"></i>
					<span>三级图元</span>
				</li>
				<li @click="routeTo('segment')" :class="{'active-li':segmentActive}">
					<el-icon style="text-align: center;"><Filter /></el-icon>
					<span>提取图元</span>
				</li>
				<li @click="routeTo('plib')" :class="{'active-li':plibActive}">
					<i class="iconfont icon-bowuguan-01"></i>
					<span>图案库</span>
				</li>
				<li :class="{'active-li':glibActive}" class="todo">
					<i class="iconfont icon-tushuguan"></i>
					<span>图元库</span>
				</li>
			</ul>
			<ul class="menu-second-items">
				<li class="todo">
					<i class="iconfont icon-chexiao"></i>
					<span>撤销</span>
				</li>
				<li class="todo">
					<i class="iconfont icon-lishijilu"></i>
					<span>历史</span>
				</li>
				<li class="todo">
					<i class="iconfont icon-chexiao"></i>
					<span>重做</span>
				</li>
			</ul>
		</ul>
	</div>
</template>

<script setup>
	import {
		ref,
		watch,
	} from 'vue'
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {useGenedrawerStore} from '../store/genedrawer.js'
	import FileBlock from './FileBlock.vue'

	const store=useGenedrawerStore()
	const router = useRouter()
	const Contents = ['未命名*']
	var content = ref(Contents[0])
	const route = useRoute()

	var drawpatternActive = ref(false)
	var drawprofileActive = ref(false)
	var drawgeneActive = ref(false)
	var glibActive = ref(false)
	var plibActive = ref(false)
	var segmentActive=ref(false)



	function cancelAll() {
		drawgeneActive.value = false
		drawpatternActive.value = false
		drawprofileActive.value = false
		glibActive.value = false
		plibActive.value = false
		segmentActive.value=false
	}

	watch(() => route.path, (newVal, oldVal) => {
		switch (route.path) {
			case '/':
				cancelAll()
				drawgeneActive.value = true
				break;
			case '/DrawGene':
				cancelAll()
				drawgeneActive.value = true
				break;
			case '/DrawPattern':
				cancelAll()
				drawpatternActive.value = true
				break;
			case '/DrawPatternProfile':
				cancelAll()
				drawprofileActive.value = true
				break;
			case '/Glib':
				cancelAll()
				glibActive.value = true
				break;
			case '/Plib':
				cancelAll()
				plibActive.value = true
				break;
			case '/Segment/demo':
				cancelAll()
				segmentActive.value=true

		}
	})

	var fBVisible = ref(false)

	function routeTo(path) {
		switch (path) {
			case 'pattern':
				router.push('/DrawBoard/DrawPattern')
				break;
			case 'profile':
				router.push('/DrawBoard/DrawPatternProfile')
				break;
			case 'gene':
				router.push('/DrawBoard/DrawGene')
				break;
			case 'plib':
				router.push('/DrawBoard/Plib')
				break;
			case 'glib':
				router.push('/DrawBoard/Glib')
				break;
			case 'segment':
				router.push('/DrawBoard/segment')
				break;
		}
	}
</script>

<style scoped="scoped">
	.menu-warpper {
		height: 1rem;
		color: white;
		font-weight: lighter;
		line-height: 0.24rem;
	}

	.menu-title {
		font-size: 0.18rem;
		text-align: left;
		height: 0.4rem;
		line-height: 0.4rem;
		padding-left: 0.15rem;
	}

	.menu-items,
	.menu-items ul {
		display: flex;
		height: 0.6rem;
		overflow: hidden;
		font-size: 0.12rem;
		list-style-type: none;
	}

	.menu-items {
		justify-content: space-between;
	}

	.menu-second-items {}

	.menu-items>li,
	.menu-second-items>li {
		width: 0.8rem;
		height: inherit;
		transition: background-color 1s;
		text-align: center;
		vertical-align: bottom;
		line-height: 0.24rem;
		padding-top: 0.1rem;
	}

	.menu-items li:hover {
		background-color: rgb(111, 111, 111);
		cursor: pointer;
	}
	
	.active-li {
		background-color: rgb(111, 111, 111);
	}

	.menu-items>li>span,
	.menu-second-items>li>span {
		display: inline-block;
		line-height: 0.24rem;
	}
	
	.todo{
		cursor: not-allowed !important;
	}
	
	.todo:hover{
		background-color: inherit !important;
	}
	
	i {
		display: inline-block;
		font-size: 0.24rem;
		width: 100%;
		line-height: 0.24rem;
		vertical-align: bottom;
	}
</style>
