<template>
	<div v-if="menuChoiced == 'showDataset'" class="container">
		<PhotoAlbum v-if="showImgs" :photos="showImgs" :page-num="pageNum" :page-size="pageSize" :img-height="imgHeight"
			:img-width="imgWidth"></PhotoAlbum>
		<div class="pagination">
			<el-pagination background layout="prev, pager, next" :total="showImgs.length" :pageSize="pageSize"
				v-model:current-page="pageNum" />
		</div>
	</div>
	<div v-else>
	</div>
</template>

<script setup>
import {
	ref,
	reactive,
	onMounted,
	watch,
	onUnmounted
} from 'vue'
import {
	emitter
} from '~bus/emitter.js'
import PhotoAlbum from '~components/basic-component/PhotoAlbum.vue'
import { getDatasetByLabel } from '~api/DatasetApi.js'
const baseurl = 'http://124.221.110.63:81/resnet-classify'
var menuChoiced = ref('showDataset')

//用map创建数据集响应式对象,用于缓存
var dataset = reactive(new Map())

//显示在相册中的图片
var showImgs = ref([])

//当前页码和页容量,传给子组件的数据
var pageNum = ref(1)
var pageSize = ref(50)
var imgWidth = ref('128px')
var imgHeight = ref('128px')

async function handleLabelSelected(label) {
	if (!dataset.get(label)) {
		await getDatasetByLabel(label).then(res => {
			var images = res.data.data.map((item) => {
				return `${baseurl}/${label}/${item}`
			})
			console.log(images)
			console.log(1)
			dataset.set(label, images)
		})
	}
	showImgs.value = dataset.get(label)
	console.log(2)
}

emitter.on('labelSelected', handleLabelSelected)

onUnmounted(() => {
	emitter.off('labelSelected', handleLabelSelected)
})



</script>


<style scoped>
.container {
	height: 100%;
}

:deep(.img-class) {
	padding: 10px !important;
	box-sizing: content-box;
	box-shadow: var(--el-box-shadow-lighter) !important;
}

:deep(.container-class) {
	padding-top: 30px;
	padding-left: 30px;
	background-color: white !important;
	width: calc(100% - 3.3rem) !important;
}

.pagination {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	text-align: center;
}
</style>
