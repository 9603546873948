export default class $Event {
	constructor(x, y, target, ctxStatus,_useOffsetXY) {
		this.clientX = x
		this.clientY = y
		this._useOffsetXY=_useOffsetXY
		// ctxStatus.vector.e = x
		// ctxStatus.vector.f = y
		// ctxStatus.vector.preMultiplySelf(ctxStatus.invertMatrix)
		// this.offsetX = ctxStatus.vector.e
		// this.offsetY = ctxStatus.vector.f
		if(this._useOffsetXY)
		{
			this.vector=new DOMPoint(x,y)
			this.offsetVector=this.vector.matrixTransform(ctxStatus.invertMatrix)
			this.offsetX=this.offsetVector.x
			this.offsetY=this.offsetVector.y
		}
		this.target = target
		this.Propagation = true
	}
	stopPropagation() {
		this.Propagation = false
	}

	update(target, ctxStatus) {
		if(this._useOffsetXY){
			this.offsetVector=this.vector.matrixTransform(ctxStatus.invertMatrix)
			this.offsetX=this.offsetVector.x
			this.offsetY=this.offsetVector.y
		}
		this.target = target
	}

	/**
	 * 为鼠标坐标自动计算所在坐标系的相对值，
	 * 启用后会消耗一定性能
	 **/
	useOffsetXY()
	{
		this._useOffsetXY=true
	}
	
	/** 
	 * 在事件传播的过程中锁定事件的更新
	 **/
	lock() {
		//更新方法赋值为空方法
		this.update = function() {}
	}

	/**
	 * 解锁事件
	 **/
	unlock() {
		this.update = function(target, ctxStatus) {
			this.offsetVector=this.vector.matrixTransform(ctxStatus.invertMatrix)
			this.offsetX=this.offsetVector.x
			this.offsetY=this.offsetVector.y
			this.target = target
		}
	}
}
