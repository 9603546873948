import CShape from "../YeahDrawer/CShape.js";

/**
 * 此对象内部的子图形只有一个,用来表示画板内部的元素
 * 画板内部元素继承自它
 **/
export default class PainterElement extends CShape{
	constructor(...args){
		super(...arguments)
		this.bindShape=this.childShapes[0]
	}
	//getter setter操控线条样式
	get outterLineWidth(){
		return this.bindShape.lineWidth.outter
	}
	set outterLineWidth(val){
		return this.bindShape.lineWidth.outter=val
	}
	get innerLineWidth(){
		return this.bindShape.lineWidth.inner
	}
	set innerLineWidth(val){
		return this.bindShape.lineWidth.inner=val
	}
	get outterStrokeColor(){
		return this.bindShape.strokeColor.outter
	}
	set outterStrokeColor(val){
		return this.bindShape.strokeColor.outter=val
	}
	get innerStrokeColor(){
		return this.bindShape.strokeColor.inner
	}
	set innerStrokeColor(val){
		return this.bindShape.strokeColor.inner=val
	}
}