   /**
     * canvas 提取图片 image
     * @param canvas 原对象
     * @param sx     裁剪矩形起点x轴坐标
     * @param sy     裁剪矩形起点y轴坐标
     * @param sw     裁剪矩形宽度
     * @param sh     裁剪矩形长度
     * @returns {HTMLImageElement}
     */
    export default function canvasToImage(canvas, sx, sy, sw, sh) {
        let image = new Image();
        image.src = canvas.toDataURL("image/png");
        // 创建复制裁剪的canvas;
        let canvasClip = document.createElement('canvas')
        //设置大小（伸展或缩小尺寸）
        canvasClip.width = sw
        canvasClip.height = sh
        //设置画笔
        let ctxNew = canvasClip.getContext('2d');
        let temptImg = new Image();
        return new Promise(function (resolve, reject) {
            image.onload = function () {
                //第三种绘制图片的方式，img参数后面的四个参数分别为源图片上面你要裁剪的起点位置和矩形的宽高，后面四个参数分别为画布的位置和要渲染的矩形宽高
                ctxNew.drawImage(image, sx, sy, sw, sh, 0, 0, canvasClip.width, canvasClip.height)
                temptImg.src = canvasClip.toDataURL();
                resolve(temptImg);
            }
            image.onerror = function () {//加载失败
                reject("加载错误");
            }
        })
    }
    //使用时注意 await关键字
    //let p = await canvasToImage(cont, 300, 300, 200, 200);
    //console.log("p", p.src)