import SShape from "./SShape.js";

export default class Point extends SShape{
	constructor(x,y,size,ctx){
		super(ctx)
		this.x = x
		this.y = y
		this.size = size
	}
	
	$setStrokePath(){
		this.strokePath=new Path2D()
		this.strokePath.rect(this.x - this.size / 2, this.y - this.size / 2, this.size, this.size)
	}
	
	draw() {
		this.ctx.save()
		// this.ctx.beginPath()
		// this.ctx.rect(this.x - this.size / 2, this.y - this.size / 2, this.size, this.size)
		this.$setStrokePath()
		this.ctx.fillStyle = 'blue'
		this.ctx.fill(this.strokePath)
		this.ctx.restore()
	}
	
	$isPointInPath(x, y) {
		var res=false
		res=this.ctx.isPointInPath(this.strokePath,x,y)
		return res
	}
	
}