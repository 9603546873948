import CGeneCase from '../CompositionShape/CGeneCase.js';
import GeneCase from '../SingleShape/GeneCase.js';
import CShape from '../YeahDrawer/CShape.js';
import Painter from './Painter.js';
import Polygon from '../SingleShape/Polygon.js';
import CCurveL from '../CompositionShape/CCurveL.js';
import Curve from '../SingleShape/Curve.js';
import Curve3 from '../SingleShape/Curve3.js'
import Circle from '../SingleShape/Circle.js';
import CircleOld from '../SingleShape/CircleOld.js';
import CurveL from '../SingleShape/CurveL.js'
import SShape from '../YeahDrawer/SShape.js';
import $Event from "../YeahDrawer/$Event.js";
import CPolygon from '../CompositionShape/CPolygon.js'
import CCurve from '../CompositionShape/CCurve.js'
import CCircle from '../CompositionShape/CCircle.js'
import CCurve3 from '../CompositionShape/CCurve3.js';
import CImg from '../CompositionShape/CImg.js';
import PainterElement from '../CompositionShape/PainterElement.js';
import LineShape from '../YeahDrawer/LineShape.js';
export default class GenePainter extends Painter {
	//尺寸分为画板大小与基因大小，缩放倍数等于画板大小/基因大小
	//该对象用来修改、创作一个gene对象
	//该对象将一个基因对象拆分，保证元素嵌套只有三层，将不同线条转化为一个基因对象
	//painter是所有shape的一个载体，所有shape都应该在painter上操作
	constructor(geneCas, ctx) {
		if (arguments.length < 2) {
			throw new Error('参数数量不对，如果不传入gene，必须填null')
		}
		super(...arguments)
		//边界线颜色
		this.errorColor = '#358bf3'
		//正在编辑状态，图形在双击之后或者创建之后进入该状态，由蚂蚁线包裹
		//该状态不同于lastOperationShape，lastOperationShape记录上一次
		//操作的图形，在鼠标或键盘操作后就将lastOperationShape指向该图形
		this.onEditShape = null
		//准备编辑的图形，状态在preparedToEdit后改变，取值为构造函数名称字符串
		this.preparedShape = 'none'

		this.onDbclick__ = function($event) {
			for (let i = 0; i < this.childShapes.length; i++) {
				let item = this.childShapes[i]
				if (item.$isPointInPath($event.clientX, $event.clientY)) {
					this.changeOnEditShape(i)
					break
				}
			}
		}
		//初始不设置制造边界
		this.useBoundry()

		//保存默认方法的副本
		this._defaultMousedownFn = this.onMousedown__
	}
	

	
	/**
	 * 在修改画布的preparedShape和oneditShape时向外暴露一个回调函数
	 **/
	preparedShapeChange(oldVal, newVal) {}
	onEditShapeChange(oldVal, newVal) {}

	
	/**
	 * 如果要修改一个基因，则是在原来的基础上创建一个副本，而不是直接修改
	 **/
	$getChildShape(geneCas, ctx) {
		//判断geneCas数据类型
		var painter = this
		var temp = null
		if (geneCas instanceof CGeneCase) {
			temp = geneCas._geneCase.caseJsonObject
		} else if (geneCas instanceof GeneCase) {
			temp = geneCas.caseJsonObject
		} else if (geneCas instanceof Object) {
			temp = geneCas
		} else if (typeof geneCas == 'string') {
			temp = JSON.parse(geneCas)
		} else {
			if (geneCas == null) {
				this.contentSize = {
					x: 100,
					y: 100
				}
			} else {
				throw new Error('第一个参数geneCas为一个gene的json对象或json字符串,如果没有请至少传入null')
			}
		}
		if (temp) {
			if (!temp.cid) {
				this.painterId = Date.now()
			} else {
				this.painterId = temp.cid
			}
			this.contentSize = {
				x: Number(temp.size.x),
				y: Number(temp.size.y)
			}

			temp.parts.forEach((item, index) => {
				switch (item.ptype) {
					case 'polygon': {
						let arr = item.points
						for (let i = 0; i < arr.length; i++) {
							arr[i].x = Number(arr[i].x)
							arr[i].y = Number(arr[i].y)
						}
						let p = new Polygon(arr, this.ctx)
						p.lineWidth = item.lineWidth
						p.strokeColor = item.strokeColor
						painter.childShapes.push(p)
						break
					}
					case 'curveL': {
						let arr = []
						arr[0] = {
							x: Number(item.startPoint.x),
							y: Number(item.startPoint.y)
						};
						let p = item.points
						p.forEach((e) => {
							arr.push({
								x: Number(e.x),
								y: Number(e.y)
							})
						});
						arr.push({
							x: Number(item.endPoint.x),
							y: Number(item.endPoint.y)
						})
						let rad = Number(item.deg) * Math.PI / 180
						var pp = new CurveL(arr, rad, ctx)
						pp.lineWidth = item.lineWidth
						pp.strokeColor = item.strokeColor
						painter.childShapes.push(pp)
						break
					}
					case 'circle': {
						let r = Number(item.radius)
						let cX = Number(item.centre.x)
						let cY = Number(item.centre.y)
						let sR = Number(item.startDeg) / 180 * Math.PI
						let eR = Number(item.endDeg) / 180 * Math.PI
						let p = new Circle(r, cX, cY, sR, eR, this.ctx)
						p.lineWidth = item.lineWidth
						p.strokeColor = item.strokeColor
						painter.childShapes.push(p)
						break
					}
					case 'curve': {
						let startX = Number(item.startPoint.x)
						let startY = Number(item.startPoint.y)
						let p1X = Number(item.points[0].x)
						let p1Y = Number(item.points[0].y)
						let p2X = Number(item.points[1].x)
						let p2Y = Number(item.points[1].y)
						let p = null
						if (item.points[2]) {
							let p3X = Number(item.points[2].x)
							let p3Y = Number(item.points[2].y)
							p = new Curve(startX, startY, p1X, p1Y, p2X, p2Y, p3X, p3Y, this.ctx)
						} else {
							p = new Curve3(startX, startY, p1X, p1Y, p2X, p2Y, this.ctx)
						}
						p.lineWidth = item.lineWidth
						p.strokeColor = item.strokeColor
						painter.childShapes.push(p)
						break
					}
					case 'curve5': {
						break
					}
				}
			})
		} else {
			this.painterId = Date.now()
		}
	}

	$dbclick(event) {
		this.$eventTarget = this
		var $event = new $Event(event.offsetX, event.offsetY, this.$eventTarget, this.ctxStatus)
		this.ctx.save()
		this.__transform__()
		//不传播
		this.onDbclick__($event)
		this.ctx.restore()
	}

	/**
	 * 复制一个元素并将其变为编辑状态
	 **/
	copyAndEdit(index) {
		this.copyAndAdd(index)
		this.changeOnEditShape(this.childShapes.length - 1)
	}

	/**
	 * 将某个线条变为编辑状态
	 **/
	changeOnEditShape(index) {
		this.cancelPrepare()
		var item = this.childShapes[index]
		if (item instanceof SShape) {
			var changedItem = this.toCShape(item)
			this.replaceChildShape(index, changedItem)
			this._lastOperateShape = changedItem
			if (this.onEditShape) {
				this.confirm(this.onEditShape)
			}
			this.onEditShape = changedItem
		} else {
			console.warn('此线条已经是编辑状态')
		}
	}

	/**
	 * 取消准备
	 **/
	cancelPrepare() {
		this.preparedShape = 'none'
		this.onMousemove__ = null
		this.onMouseup__ = null
		this.onMousedown__ = this._defaultMousedownFn
	}

	/**
	 * 准备编辑某个图形，该函数切换鼠标事件
	 * 触发事件后该图形变化onEdit状态
	 **/
	prepareToEdit(CShapeName) {
		var painter = this
		switch (CShapeName) {
			case 'Polygon':
				this.preparedShape = 'Polygon'
				painter.onMousedown__ = function($event) {
					var downPoint = this.toOffsetXY($event.clientX, $event.clientY)
					var ploygon = new CPolygon([{
						x: downPoint.x,
						y: downPoint.y
					}, {
						x: downPoint.x,
						y: downPoint.y
					}], this.ctx)
					this.addChildShape(ploygon)
					this.preparedShape = 'none'
					this.onEditShape = ploygon
					painter.onMousemove__ = function($event) {
						var movePoint = this.toOffsetXY($event.clientX, $event.clientY)
						ploygon.controlPoints[1].x = movePoint.x
						ploygon.controlPoints[1].y = movePoint.y
					}
					painter.onMouseup__ = function($event) {
						this.onMousemove__ = null
						this.onMouseup__ = null
						painter.onMousedown__ = this._defaultMousedownFn
					}
				}
				break;
			case 'Circle':
				this.preparedShape = 'Circle'
				painter.onMousedown__ = function($event) {
					var downPoint = this.toOffsetXY($event.clientX, $event.clientY)
					let mousedownR = 2
					var centre1 = {
						x: downPoint.x - 12,
						y: downPoint.y - 12
					}
					var centre2 = {
						x: downPoint.x + 12,
						y: downPoint.y + 12
					}
					var centre3 = {
						x: downPoint.x + 12,
						y: downPoint.y - 12
					}
					var centre4 = {
						x: downPoint.x - 12,
						y: downPoint.y + 12
					}
					var circle = null
					var d = 12 * Math.SQRT2 - 2
					painter.onMousemove__ = function($event) {
						var movePoint = this.toOffsetXY($event.clientX, $event.clientY)
						if (circle == null) {
							if (movePoint.x >= downPoint.x && movePoint.y >= downPoint.y) {
								circle = new CCircle(2, centre1.x, centre1.y, 0, 2 * Math.PI, this.ctx)
							} else if (movePoint.x <= downPoint.x && movePoint.y <= downPoint.y) {
								circle = new CCircle(2, centre2.x, centre2.y, 0, 2 * Math.PI, this.ctx)
							} else if (movePoint.x < downPoint.x && movePoint.y > downPoint.y) {
								circle = new CCircle(2, centre3.x, centre3.y, 0, 2 * Math.PI, this.ctx)
							} else if (movePoint.x > downPoint.x && movePoint.y > downPoint.y) {
								circle = new CCircle(2, centre4.x, centre4.y, 0, 2 * Math.PI, this.ctx)
							}
							painter.addChildShape(circle)
							this.preparedShape = 'none'
							this.onEditShape = circle
						} else {
							let newDx = movePoint.x - circle._circle.centreX
							let newDy = movePoint.y - circle._circle.centreY
							let newD = Math.sqrt(newDx ** 2 + newDy ** 2)
							if (mousedownR + (newD - d) / Math.sqrt(2) > 0) {
								circle.radius = mousedownR + (newD - d) / Math.sqrt(2)
							}
						}
					}
					painter.onMouseup__ = function($event) {
						this.onMousemove__ = null
						this.onMouseup__ = null
						painter.onMousedown__ = this._defaultMousedownFn
					}
				}
				break;
			case 'Curve3':
				this.preparedShape = 'Curve3'
				painter.onMousedown__ = function($event) {
					let downPoint = this.toOffsetXY($event.clientX, $event.clientY)
					var {
						x,
						y
					} = downPoint
					let curve = new CCurve3(x, y, x, y, x, y, this.ctx)
					painter.addChildShape(curve)
					this.preparedShape = 'none'
					this.onEditShape = curve
					painter.onMousemove__ = function($event) {
						let movePoint = this.toOffsetXY($event.clientX, $event.clientY)
						curve._p2.x = movePoint.x
						curve._p2.y = movePoint.y
						curve._p1.x = x + (movePoint.x - x) * 1 / 4
						curve._p1.y = y - (movePoint.x - x) * 1 / 2
					}
					painter.onMouseup__ = function($event) {
						this.onMousemove__ = null
						this.onMouseup__ = null
						painter.onMousedown__ = this._defaultMousedownFn
					}
				}
				break;
			case 'Curve':
				this.preparedShape = 'Curve'
				painter.onMousedown__ = function($event) {
					let downPoint = this.toOffsetXY($event.clientX, $event.clientY)
					var {
						x,
						y
					} = downPoint
					let curve = new CCurve(x, y, x, y, x, y, x, y, this.ctx)
					painter.addChildShape(curve)
					this.preparedShape = 'none'
					this.onEditShape = curve
					painter.onMousemove__ = function($event) {
						let movePoint = this.toOffsetXY($event.clientX, $event.clientY)
						curve._p3.x = movePoint.x
						curve._p3.y = movePoint.y
						curve._p2.x = x + (movePoint.x - x) * 3 / 4
						curve._p2.y = y + (movePoint.x - x) * 1 / 2
						curve._p1.x = x + (movePoint.x - x) * 1 / 4
						curve._p1.y = y - (movePoint.x - x) * 1 / 2
					}
					painter.onMouseup__ = function($event) {
						this.onMousemove__ = null
						this.onMouseup__ = null
						painter.onMousedown__ = this._defaultMousedownFn
					}
				}
				break;
			case 'Curve5':
				this.preparedShape = 'Curve5'
				break;
		}
	}


	/**
	 * 重写父类的confirm方法，将一个在编辑的图形确认编辑
	 * @param {CShape} 可选参数 要确认编辑的图形，如果不填则是上一个操作的图形
	 **/
	confirm(cshape) {
		if (cshape) {
			var items = this.toSShape(cshape)
			if (items) {
				for (let i = 0; i < this.childShapes.length; i++) {
					if (this.childShapes[i] == cshape) {
						this.replaceChildShape(i, items[0])
					}
				}
			}
			if (this._lastOperateShape == cshape) {
				if (this._lastOperateShape == this.onEditShape) {

					this.onEditShape = null
					this._lastOperateShape = null
				} else {
					throw new Error('存在一个以上正在编辑的图形')
				}
			}
		} else {
			if (this._lastOperateShape != null) {
				var items = this.toSShape(this._lastOperateShape)
				if (items) {
					for (let i = 0; i < this.childShapes.length; i++) {
						if (this.childShapes[i] == this._lastOperateShape) {
							this.replaceChildShape(i, items[0])
						}
					}
				}
				if (this._lastOperateShape == this.onEditShape) {
					this.onEditShape = null
					this._lastOperateShape = null
				} else {
					this._lastOperateShape = null
					throw new Error('存在一个以上正在编辑的图形')
				}
			}
		}
	}
	
	/**
	 * 取消建造边界
	 **/
	cancelBoundry() {
		this._useBoundry = false
	}

	draw() {
		//var fps=this.getFps()
		this.__lineDashOffset__ += 0.1
		if (this.__lineDashOffset__ > 32) {
			this.__lineDashOffset__ = 0
		}
		this.ctx.save()
		this.ctx.clearRect(-10000, -10000, 20000, 20000)
		this.__transform__()
		this.drawBackgroundGrid()
		this.$drawControlPoints()
		this.ctx.lineDashOffset = -this.__lineDashOffset__
		this.$drawChildShape()
		this.$drawBoundry()
		if (this._useBoundry) {
			this.ctx.beginPath()
			this.ctx.strokeStyle = this.errorColor
			this.ctx.lineWidth = 0.5
			this.ctx.rect(0, 0, this.contentSize.x, this.contentSize.y)
			this.ctx.stroke()
		}
		//this.drawFps(fps.toFixed(0))
		this.ctx.restore()
		this.timer = requestAnimationFrame(() => {
			this.draw()
		})
	}

	/**
	 * 镜像翻转正在编辑的图形的控制点
	 * @param mode 1代表左右镜像 2代表上下镜像
	 **/
	MirrorReverse(mode) {
		if (this.onEditShape) {
			if (mode == 1) {
				if (!(this.onEditShape instanceof CCircle)) {
					this.onEditShape.controlPoints.forEach((item) => {
						item.x = this.contentSize.x - item.x
					})
				} else {
					this.onEditShape.posX = this.contentSize.x - this.onEditShape.posX
					var start = this.onEditShape.startRad
					var end = this.onEditShape.endRad
					this.onEditShape.startRad = Math.PI - end
					this.onEditShape.endRad = Math.PI - start
				}
			} else if (mode == 2) {
				if (!(this.onEditShape instanceof CCircle)) {
					this.onEditShape.controlPoints.forEach((item) => {
						item.y = this.contentSize.y - item.y
					})
				} else {
					this.onEditShape.posY = this.contentSize.y - this.onEditShape.posY
					var start = this.onEditShape.startRad
					var end = this.onEditShape.endRad
					this.onEditShape.startRad = 2 * Math.PI - end
					this.onEditShape.endRad = 2 * Math.PI - start
				}
			}
		}
	}


	/**
	 * 判断对象类型，返回对应类型的对象
	 *
	 * @param obj
	 * @returns {string}
	 */
	judeObj(obj) {
		let res = {};
		if (obj instanceof Circle) {
			res = {
				"ptype": "circle",
				"radius": obj.radius,
				"centre": {
					"x": obj.centreX,
					"y": obj.centreY
				},
				"startDeg": obj.startRad * 180 / Math.PI,
				"endDeg": obj.endRad * 180 / Math.PI,
				"lineWidth": obj.lineWidth,
				"strokeColor": obj.strokeColor,
			};
			return res;
		} else if (obj instanceof Polygon) {
			let points = obj.pointArr;
			res = {
				"ptype": "polygon",
				"points": points,
				"lineWidth": obj.lineWidth,
				"strokeColor": obj.strokeColor,
			};
			return res;
		} else if (obj instanceof Curve) {
			res = {
				"ptype": "curve",
				"startPoint": {
					x: obj.startX,
					y: obj.startY
				},
				"points": [{
					x: obj.p1X,
					y: obj.p1Y
				}, {
					x: obj.p2X,
					y: obj.p2Y
				}, {
					x: obj.p3X,
					y: obj.p3Y
				}],
				"lineWidth": obj.lineWidth,
				"strokeColor": obj.strokeColor,
			};
			return res;
		} else if (obj instanceof Curve3) {
			res = {
				"ptype": "curve",
				"startPoint": {
					x: obj.startX,
					y: obj.startY
				},
				"points": [{
					x: obj.p1X,
					y: obj.p1Y
				}, {
					x: obj.p2X,
					y: obj.p2Y
				}],
				"lineWidth": obj.lineWidth,
				"strokeColor": obj.strokeColor,
			};
			return res;
		} else if (obj instanceof CurveL) {
			var tempArr = []
			for (let i = 1; i < obj.pointArr.length - 1; i++) {
				tempArr.push({
					x: obj.pointArr[i].x,
					y: obj.pointArr[i].y
				})
			}
			//兼容老对象
			res = {
				"ptype": "curveL",
				"startPoint": {
					x: obj.pointArr[0].x,
					y: obj.pointArr[0].y,
				},
				"points": tempArr,
				"endPoint": {
					x: obj.pointArr[2].x,
					y: obj.pointArr[2].y
				},
				"deg": obj.rad / Math.PI * 180,
				"lineWidth": obj.lineWidth,
				"strokeColor": obj.strokeColor,
			};
			return res;
		} else {
			console.log("无法查询的类型，请联系管理员")
		}
	}


	onMousedown__($event) {
		if (this.$eventTarget != this) {
			let moveShape = this.$eventTarget
			let mousedownPoint = this.toOffsetXY($event.clientX, $event.clientY)
			let temp = []
			if (moveShape instanceof CShape) {
				//console.log(4,moveShape)代理对象
				if (moveShape instanceof CCircle) {
					temp[0] = {
						x: moveShape.posX,
						y: moveShape.posY
					}
					this.onMousemove__ = function($event) {
						let mousePoint = this.toOffsetXY($event.clientX, $event.clientY)
						moveShape.posX = Number(temp[0].x) + (mousePoint.x - mousedownPoint.x)
						moveShape.posY = Number(temp[0].y) + (mousePoint.y - mousedownPoint.y)
					}
				} else {
					moveShape.controlPoints.forEach((item) => {
						temp.push({
							x: item.x,
							y: item.y
						})
					})
					this.onMousemove__ = function($event) {
						let mousePoint = this.toOffsetXY($event.clientX, $event.clientY)
						moveShape.controlPoints.forEach((item, index) => {
							item.x = Number(temp[index].x) + (mousePoint.x - mousedownPoint.x)
							item.y = Number(temp[index].y) + (mousePoint.y - mousedownPoint.y)
						})

					}
				}
				this.onMouseup__ = function($event) {
					this.onMousemove__ = null
				}
			}
		} else {
			let downPoint = {
				x: $event.clientX,
				y: $event.clientY
			}
			let downStatus = this.ctxStatus.copy()
			let startPointCopy = {
				x: this.ctxStatus.translateX,
				y: this.ctxStatus.translateY
			}
			//转化painter.width、height至真实坐标,例如在放大了两倍的情况下，画布逻辑宽2000，实际宽4000
			let boundryTop = -this.height / 2 * this.ctxStatus.scaleY - 100 + this.ctx.canvas.height
			let boundtyBottom = this.height / 2 * this.ctxStatus.scaleY + 100
			let boundrtLeft = -this.width / 2 * this.ctxStatus.scaleX - 100 + this.ctx.canvas.width
			let boundryRight = this.width / 2 * this.ctxStatus.scaleX + 100

			this.onMousemove__ = function($event) {
				let mousePoint = {
					x: $event.clientX,
					y: $event.clientY
				}
				let tempx = startPointCopy.x + mousePoint.x - downPoint.x
				let tempy = startPointCopy.y + mousePoint.y - downPoint.y
				if (tempx <= boundryRight && tempx >= boundrtLeft) {
					this.ctxStatus.translateX = tempx
				}
				if (tempy <= boundtyBottom && tempy >= boundryTop) {
					this.ctxStatus.translateY = tempy
				}
			}
			this.onMouseup__ = function($event) {
				this.onMousemove__ = null
			}
		}
	}
	
	/**
	 * 设置基因size大小
	 **/
	setContentSize(size) {
		this.contentSize = size
	}
	
	/**
	 * 转化完之后是一个gene的json对象
	 */
	save() {
		let cas = {};
		let childShapes = this.childShapes;
		let size = {
			x: this.contentSize.x,
			y: this.contentSize.y
		};
		let cid = this.painterId
		cas = {
			"size": size,
			"parts": [],
			"cid": cid
		};
		let parts = [];
		for (let i = 0; i < childShapes.length; i++) {
			if(childShapes[i] instanceof LineShape){
				let partsObj = this.judeObj(childShapes[i]);
				parts.push(partsObj);
			}
		}
		cas.parts = parts;
		return cas;
	}
	
	switchUseBoundry(){
		if(this._useBoundry){
			this.cancelBoundry()
		}else{
			this.useBoundry()
		}
	}
	/**
	 * 启用建造边界
	 **/
	useBoundry() {
		this._useBoundry = true
	}
	
	get onEditShape() {
		return this._onEditShape
	}
	
	set onEditShape(val) {
		var temp = this._onEditShape
		this._onEditShape = val
		//回调
		this.onEditShapeChange(temp, val)
	}
	
	get preparedShape() {
		return this._preparedShape
	}
	
	set preparedShape(val) {
		var temp = this._preparedShape
		this._preparedShape = val
		this.preparedShapeChange(temp, val)
	}
	
}
