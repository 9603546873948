import CShape from "../YeahDrawer/CShape.js"
import SShape from "../YeahDrawer/SShape.js"
import GeneCase from "../SingleShape/GeneCase.js"
import Observer from '../YeahDrawer/Observer.js'
import $Math from '../YeahDrawer/$Math.js'
import PainterElement from "./PainterElement.js"


export default class CGeneCase extends PainterElement {
	constructor(gid, caseJsonObject, ctx) {

		super(...arguments)
		//默认事件赋值
		this.gid=gid
		this.onMousedown__ = function($event) {

			$event.stopPropagation()
			var son = this
			var father = son.fatherShape
			var initX = son.ctxStatus.translateX
			var initY = son.ctxStatus.translateY
			var mousedownX = $event.clientX
			var mousedownY = $event.clientY

			var downPoint = father.toOffsetXY(mousedownX, mousedownY)
			this.useChildShapesMouseMove = true
			this.coordinateSystemColor='black'
			//默认绘制颜色和选中绘制颜色
			father.onMousemove__ = function($event) {
				var movePoint = father.toOffsetXY($event.clientX, $event.clientY)
				son.ctxStatus.translateX = initX + (movePoint.x - downPoint.x)
				son.ctxStatus.translateY = initY + (movePoint.y - downPoint.y)
				//son.notify(son.ctxStatus.translateX, son.ctxStatus.translateY)
			}
			father.onMouseup__ = function($event) {
				this.onMousemove__ = null
			}
		}

		this.boundryPoints[0].onMousedown__ = null
		//旋转至45°时缩放存在问题
		this.boundryPoints[2].onMousedown__ = function($event) {
			$event.stopPropagation()
			//console.log(222)
			var son = this.fatherShape
			var father = son.fatherShape
			var initX = son.ctxStatus.translateX
			var initY = son.ctxStatus.translateY
			var mousedownX = $event.clientX
			var mousedownY = $event.clientY
			var initScaleX = son.ctxStatus.scaleX
			var initScaleY = son.ctxStatus.scaleY
			father.onMousemove__ = function($e) {
				var w = mousedownX - $e.clientX
				var h = mousedownY - $e.clientY
				var scaleX = w / 100
				var scaleY = h / 100
				son.ctxStatus.scaleX = initScaleX - scaleX
				son.ctxStatus.scaleY = initScaleY - scaleY
			}
			father.onMouseup__ = function($e) {
				this.onMousemove__ = null
			}
		}
		//存储要变化的默认方法
		this._defaultIsPointInPath = this.$isPointInPath
		this.tempBoundryPoints = this.boundryPoints
		this.isEdit = true
	}
	
	addObserver(observer) {
		this.observer = observer
	}
	
	/**
	 * 确认编辑，按下回车后触发
	 **/
	confirm() {
		this.isEdit = false
		//this._defaultIsPointInPath=this.$isPointInPath
		this.$isPointInPath = this.$isPointInPathConfirm
		// this._defaultActualBoundryPath = this.actualBoundryPath
		// this._defaultDrawBoundry = this.$drawBoundry
		//隐藏了边界点，但点实际上还是存在，得删除
		// this.$drawBoundry = this.$drawBoundryConfirm
		this.hiddeBoundryPoints()
		// this.actualBoundryPath = this.actualBoundryPathConfirm
	}
	
	draw() {
		this.ctx.save()
		this.__transform__()
		this.$drawChildShape()
		this.$drawBoundry()
		if (this.isEdit) {
			this.showCoordinateSystem()
		}
		this.ctx.restore()
	}
	
	/**
	 * 进行编辑，双击后触发
	 * TO DO 2023/2/22 修改Bug
	 **/
	edit() {
		this.isEdit = true
		// this.$drawBoundry = this._defaultDrawBoundry
		this.showBoundryPoints()
		this.$isPointInPath = this._defaultIsPointInPath
		// this.actualBoundryPath = this._defaultActualBoundryPath
	}
	
	$drawChildShape() {
		for (let i = 0; i < this.childShapes.length; i++) {
			this.outterLineWidth = 1 / Math.min(this.ctxStatus.scaleX, this.ctxStatus.scaleY)
			this.innerLineWidth = 1 / Math.min(this.ctxStatus.scaleX, this.ctxStatus.scaleY)
			this.childShapes[i].draw()
			//this.ctx.strokeStyle = this.returnColor
			//this.ctx.lineWidth = 1
		}
	}


	$getChildShape(gid, caseJsonObject, ctx) {
		var _geneCase = new GeneCase(gid, caseJsonObject, ctx)
		this._geneCase = _geneCase
		this.childShapes.push(_geneCase)
	}
	
	/**
	 * 生产svg的字符串路径
	 * @returns {String}
	 **/
	getSvgPath(){
		var genecas=this.bindShape
		return genecas.svgPathBody
	}
	
	hiddeBoundryPoints() {
		this.boundryPoints = []
	}
	
	$isPointInPathConfirm(x, y) {
		var flag = false
		this.ctx.save()
		this.__transform__()
		this.ctx.lineWidth = this._geneCase.lineWidth.outter + 4
		flag = this.ctx.isPointInStroke(this._geneCase.strokePath, x, y)
		this.ctx.restore()
		return flag
	}
	
	/**
	 * 提高效率，重写方法，因为图元基因不存在控制点，且子图形的ispointinpath方法不需要被重复调用
	 **/
	$mousemove($event) {
		this.$eventTarget = this
		this.ctx.save()
		this.__transform__()
		for (let i = 0; i < this.boundryPoints.length; i++) {
			let item = this.boundryPoints[i]
			if (item.$isPointInPath($event.clientX, $event.clientY)) {
				if (!item.mouseIn) {
					item.mouseIn = true
					item.onMouseIn__($event)
				}
				this.$eventTarget = item
				this.$eventTarget.$mousemove($event)
				if ($event.Propagation && this.onMousemove__)
					this.onMousemove__($event)
				this.$afterBoundryPointMousemove($event)
				this.ctx.restore()
				this.$eventTarget = this
				return
			} else {
				if (item.mouseIn) {
					item.mouseIn = false
					item.onMouseOut__($event)
				}
			}
		}
		if (this.$eventTarget == this) {
			// if (!$Math.matrixEqual(this.ctxStatus.matrix, this.fatherShape.ctxStatus.matrix)) {
			// 	$event.update(this, this.ctxStatus)
			// }
			if (this.onMousemove__) {
				this.onMousemove__($event)
			}
		}
		this.ctx.restore()
	}
	
	notify(x, y) {
		if (this.observer)
			this.observer.update(x, y)
	}
	
	onMouseOut__() {
		this._geneCase.strokeColor.outter = this.fatherShape.strokeColorDefault
	}
	
	onMouseIn__() {
		this._geneCase.strokeColor.outter = this.fatherShape.strokeColorChoose
	}
	
	$rules() {
		this.boundryPoints[0].x = 0
		this.boundryPoints[0].y = 0
		this.boundryPoints[1].x = this._geneCase.size.x
		this.boundryPoints[1].y = 0
		this.boundryPoints[2].x = this._geneCase.size.x
		this.boundryPoints[2].y = this._geneCase.size.y
		this.boundryPoints[3].x = 0
		this.boundryPoints[3].y = this._geneCase.size.y
	}

	removeObserver() {
		this.observer = null
	}

	showBoundryPoints() {
		this.boundryPoints = this.tempBoundryPoints
	}

}
