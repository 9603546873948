/**
 * 该对象用来保存和读取本地Json文件
 **/
export default {
	//保存获取的文件句柄
	fh: null,
	clearFh: function() {
		this.fh = null
	},
	readLocalJson: async function() {
		try {
			// 通过 filePicker 选择本地文件
			const [fileHandle] = await window.showOpenFilePicker();

			this.fh = fileHandle

			// 通过 fileHandle 获取 File 对象
			const file = await fileHandle.getFile();

			// 通过 File 对象创建一个 FileReader
			const reader = new FileReader();

			// 通过 FileReader 读取文件内容
			const content = await new Promise((resolve, reject) => {
				reader.onload = () => resolve(reader.result);
				reader.onerror = () => reject(reader.error);
				reader.readAsText(file);
			});

			// 将 JSON 字符串转换为 JavaScript 对象
			const obj = JSON.parse(content);

			return obj;
		} catch (err) {
			console.error(err);
		}
	},
	writeLocalJson: async function(obj) {
		try {
			var fileHandle = null
			if (this.fh) {
				fileHandle = this.fh
			} else {
				// 通过 filePicker 选择要写入的文件
				fileHandle = await window.showSaveFilePicker();
				this.fh = fileHandle
			}
			// 获取 FileSystemWritableFileStream 对象
			const writable = await fileHandle.createWritable();

			// 将 JavaScript 对象转换为 JSON 字符串
			const jsonString = JSON.stringify(obj);

			//console.log(jsonString)
			// 将 JSON 字符串写入文件
			await writable.write(jsonString);

			// 关闭文件句柄
			await writable.close();

			return true
		} catch (err) {
			console.error(err);

			return false
		}
	},
	writeAsLocalJson: async function(obj) {
		try {
			// 通过 filePicker 选择要写入的文件
			const fileHandle = await window.showSaveFilePicker();

			this.fh = fileHandle
			// 获取 FileSystemWritableFileStream 对象
			const writable = await fileHandle.createWritable();

			// 将 JavaScript 对象转换为 JSON 字符串
			const jsonString = JSON.stringify(obj);

			console.log(jsonString)
			// 将 JSON 字符串写入文件
			await writable.write(jsonString);

			// 关闭文件句柄
			await writable.close();
			return true
		} catch (err) {
			console.error(err);
			return false
		}
	},
	readLocalImg: async function() {
		const options = {
			types: [{
				description: 'Image files',
				accept: {
					'image/*': ['.jpg', '.png', '.gif', '.svg'],
				},
			}],
		};

		try {
			const [fileHandle] = await window.showOpenFilePicker(options);
			const file = await fileHandle.getFile(); //返回File对象
			//判断是普通图片还是svg
			console.log(file.type)
			const reader = new FileReader();

			var url = URL.createObjectURL(file)
			//这里会内存泄露，没有释放图片的内存
			//对于 JS 和 react 一个 blob:// URL 只是一个字符串，所以你保存的只是一个字符串。
			//但是对于浏览器来说，这个字符串实际上是一个真正的指针，指向用来创建它的对象。
			//现在，为了使这种关系起作用，浏览器必须将对象保存在内存中，以便在尝试获取资源时可以找到它。
			return url
		} catch (error) {
			console.error(error);
		}
	},
	saveLocalSvg: async function() {
		
	},
	/**
	 * 可以直接使用a标签download属性下载文件
	 **/
	downloadFile: function() {
		
	}
}
