<template>
	<article v-loading='loading'>
		<h3><span>&lt;</span>图案库<span>&gt;</span></h3>
		<template v-if="records">
			<div class="container">
				<div v-for="item in records" class="genes-item">
					<span>{{item.profileId}}</span>
					<div class="genes-item-imgs">
						<img :src="item.litimgPath" @click="chooseProfile(item.jsonContext)" />
					</div>
				</div>
			</div>
			<el-pagination small background layout="prev, pager, next" :total="total" class='my-pager'
				v-model:current-page='page' />
		</template>
	</article>
	<article v-if="store.painter&&store.painter.onEditShape" class="edit-layer">
		<h3>图案定位信息</h3>
		<h4>坐标</h4>
		<label>X:<input v-model.number="store.painter.onEditShape.ctxStatus.translateX" /></label>
		<div></div>
		<label>Y:<input v-model.number="store.painter.onEditShape.ctxStatus.translateY" /></label>
		<h4>缩放比</h4>
		<label>x-scale:<input v-model.number="store.painter.onEditShape.ctxStatus.scaleX" type="number" min="0"
				step="0.1" /></label>
		<div></div>
		<label>y-scale:<input v-model.number="store.painter.onEditShape.ctxStatus.scaleY" type="number" min="0"
				step="0.1" /></label>
		<h4>旋转</h4>
		<label>旋转角:<input v-model.number="angle" @change="setAngle" /></label>
	</article>
</template>

<script setup>
	import {
		ref,
		reactive,
		watch,
		toRef,
		onMounted,
		computed,
	} from 'vue'
	import {
		usePatterndrawerStore
	} from '../store/patterndrawer.js'
	import {
		getPorfileById,
		getPImgByPage
	} from '../api/PatternProfileApi.js'
	import {
		emitter
	} from '../bus/emitter.js'
	var store = usePatterndrawerStore()
	//当前页码的基因
	var records = ref([])
	//TODO 2023/2/26做一个缓存
	var total = ref(0)
	//总的基因
	var profilesAll = []
	
	var loading=ref(true)

	var page = ref(1);

	watch(page, (newVal, v2) => {
		//TO DO 2023/2/25 发送分页请求
		getPImgByPage(page.value, 10).then((data) => {
			var obj = data.data
			total.value = obj.total
			records.value = obj.records
			loading.value=false
		});
	}, {
		immediate: true
	});


	function chooseProfile(item) {
		var profileJsonObj = item
		//var profileId=profileJsonObj.profileId
		emitter.emit('prepared', {
			profileJsonObj
		})
	};


	var angle = computed({
		get() {
			if (store.painter && store.painter.onEditShape){
				return (store.painter.onEditShape.ctxStatus.rotate * 180 / Math.PI)
			}
			else
				return 0
		},
		set(val) {
			if(store.painter && store.painter.onEditShape){
				store.painter.onEditShape.ctxStatus.rotate = val / 180 * Math.PI
			}
		}
	});
</script>

<style scoped src='../assets/css/profileToolBar.less' lang="less">
</style>
