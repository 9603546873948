import LineShape from "../YeahDrawer/LineShape.js";
export default class Circle extends LineShape {
	constructor(radius, centreX, centreY, startRad, endRad, ctx) {
		super(ctx)
		this.radius = radius
		this.centreX = centreX
		this.centreY = centreY
		this.startRad = startRad
		this.endRad = endRad
	}

	$setStrokePath(){
		this.strokePath=new Path2D()
		this.strokePath.arc(this.centreX, this.centreY, this.radius, this.startRad, this.endRad, false)
	}
	
	// draw() {
	// 	this.ctx.beginPath()
	// 	this.ctx.arc(this.centreX, this.centreY, this.radius, this.startRad, this.endRad, false)
	// 	this.ctx.stroke()
	// }

	// $isPointInPath(x, y) {
	// 	this.ctx.beginPath()
	// 	this.ctx.lineWidth=3
	// 	this.ctx.arc(this.centreX, this.centreY, this.radius, this.startRad, this.endRad, false)
	// 	return this.ctx.isPointInStroke(x, y)
	// }
}
