import Boundry from "./Boundry.js"

export default class Shape {
	constructor(ctx) {
		this.ctx = ctx
		this.fatherShape = null
		this.$eventTarget = this
		//存储鼠标是否在内部，用来进行鼠标移入移出的事件判断
		this.mouseIn=false
		//事件会被重写，但是当重写后的事件被注销后会自动变回默认事件
		this.clickEventStack=[]
		this.mousedownEventStack=[]
		this.mouseupEventStack=[]
		this.mousemoveEventStack=[]
		this.mouseInEventStack=[]
		this.mouseOutEventStack=[]
		this.strokeLineWidth=1
		
	}
	

	draw() {

	}
	
	__proxyEvent__(){
		
	}
	
	$mousedown(event) {

	}
	//on开头的方法暴露给外界使用
	onMousedown__($event) {
		//console.log('mousedown:shape')
	}

	$mouseup(event) {

	}

	onMouseup__($event) {

	}

	$mousemove(event) {

	}

	onMousemove__($event) {

	}

	//点击到一个物体的时候遍历场景中物体的这个方法
	//返回一个布尔值
	$isPointInPath(x, y) {

	}
	
	onMouseIn__($event){
		//console.log(this.constructor.name,'mIn')
	}
	
	onMouseOut__($event){
		//console.log(this.constructor.name,'mOut')
	}
	
	addEvent(type,fn){
		switch(type){
			case 'mousedown':
			this.mousedownEventStack.push(fn)
			break;
			case 'mousemove':
			this.mousemoveEventStack.push(fn)
			break;
			case 'mouseup':
			this.mouseupEventStack.push(fn)
			break;
			case 'mouseIn':
			this.mouseInEventStack.push(fn)
			break;
			case 'mouseOut':
			this.mouseOutEventStack.push(fn)
			break;
			default:
			throw new Error('事件类型错误')
		}
	}
	removeEvent(type,fn){
		switch(type){
			case 'mousedown':
			var index=this.mousedownEventStack.findIndex(e=>e==fn)
			this.mousedownEventStack.splice(index,1)
			break;
			case 'mousemove':
			var index=this.mousedownEventStack.findIndex(e=>e==fn)
			this.mousemoveEventStack.splice(index,1)
			break;
			case 'mouseup':
			var index=this.mousedownEventStack.findIndex(e=>e==fn)
			this.mouseupEventStack.splice(index,1)
			break;
			case 'mouseIn':
			var index=this.mousedownEventStack.findIndex(e=>e==fn)
			this.mouseInEventStack.splice(index,1)
			break;
			case 'mouseOut':
			var index=this.mousedownEventStack.findIndex(e=>e==fn)
			this.mouseOutEventStack.splice(index,1)
			break;
			default:
			throw new Error('事件类型错误')
		}
	}
}
