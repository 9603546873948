<template>
	<div class="container">
		<!-- 不同界面显示不同的菜单 -->
		<ul style="padding-left: 10px; flex: 1;" v-if="route.path == '/DrawBoard/DrawPatternProfile'">
			<li @click="emitter.emit('newProfile')">新建项目</li>
			<li @click="emitter.emit('loadProfile')">读取项目(在线)</li>
			<li @click="handleEmit('loadLocal')">读取项目(本地)</li>
			<li @click="handleEmit('saveLocal')">保存到本地</li>
			<li @click="handleEmit('saveAsLocal')">另存为本地</li>
			<li @click="handleEmit('downloadFile')">成果下载</li>
			<li @click="emitter.emit('uploadProfile')">上传项目</li>
		</ul>
		<ul style="padding-left: 10px; flex: 1;" v-else-if="route.path == '/DrawBoard/DrawGene' || route.path == '/'">
			<li @click="emitter.emit('newGene')">新建项目</li>
			<li @click="emitter.emit('loadGene')">读取项目(在线)</li>
			<li @click="handleEmit('loadLocal')">读取项目(本地)</li>
			<li @click="handleEmit('saveLocal')">保存到本地</li>
			<li @click="handleEmit('saveAsLocal')">另存为本地</li>
			<li @click="emitter.emit('uploadGene')">上传项目</li>
		</ul>
		<ul style="padding-left: 10px; flex: 1;" v-else-if="route.path == '/DrawBoard/DrawPattern'">
			<li @click="emitter.emit('newPattern')">新建项目</li>
			<li @click="emitter.emit('loadPattern')">读取项目(在线)</li>
			<li @click="handleEmit('loadLocal')">读取项目(本地)</li>
			<li @click="handleEmit('saveLocal')">保存到本地</li>
			<li @click="handleEmit('saveAsLocal')">另存为本地</li>
			<li @click="emitter.emit('uploadPattern')">上传项目</li>
		</ul>
		<i @click="$emit('close')" class="close">收起</i>
	</div>
</template>

<script setup>
import {
	emitter
} from '../bus/emitter.js'
import {
	useRoute
} from 'vue-router'
const route = useRoute()

const emits = defineEmits(['close'])

/**
 * 此方法判断当前浏览器环境
 * **/
function judgeEnv() {
	if (window.location.protocol == 'http:') {
		if (window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') {
			return true
		} else {
			alert('非本地用户无法访问资源管理器，请选择"读取在线"、"成果下载"')
			return false
		}
	} else if (window.location.protocol == 'https:') {
		return true
	}
}

function handleEmit(event) {
	if (judgeEnv()) {
		emitter.emit(event)
	}
	emits('close')
}
</script>

<style scoped>
.container {
	position: absolute;
	z-index: 100;
	width: 2rem;
	height: 2rem;
	top: 1rem;
	background-color: white;
	color: #213547;
	text-align: left;
	display: flex;
	flex-direction: column;
	cursor: default;
}

li {
	cursor: pointer;
	user-select: none;
}

li:hover {
	background-color: aliceblue;
}

.close {
	display: block;
	margin-bottom: 0.1rem;
	/* margin-top: auto; */
	text-align: center;
	padding-left: 0;
	cursor: pointer;
}
</style>
