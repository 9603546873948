import CShape from "../YeahDrawer/CShape.js";
import Profile from '../SingleShape/Profile.js'

export default class CProfile extends CShape {
	/**
	 * 图案的一个Profile
	 * @param profileJsonObject 一个Profile的Json对象
	 * @param geneCaseMap 一个Map里面存着gene的case，键：gid+'-'+cid ；值：gcas的json对象
	 */
	constructor(profileJsonObject, geneCaseMap, ctx) {
		super(...arguments)
		this.isEdit = true
		this.profileJsonObject = profileJsonObject
	}

	draw() {
		this.ctx.save()
		this.__transform__()
		this.drawMouseoverEffect()
		this.$drawChildShape()
		this.$drawBoundry()
		this.ctx.restore()
	}
	/**
	 * 此方法用来绘制鼠标悬浮时的背景特效
	 **/
	drawMouseoverEffect() {
		if (this.mouseIn) {
			this.ctx.strokeStyle = 'rgba(96,175,255,0.7)'
			this.ctx.beginPath()
			this.ctx.rect(0, 0, this.size.x, this.size.y)
			this.ctx.stroke()
		}
	}

	// onMouseIn__() {

	// }


	$drawChildShape() {
		this._profile.draw(this.ctxStatus.matrix)
	}

	$getChildShape(profileJsonObject, geneCaseMap, ctx) {
		//子图形是一整个Profile而不是一个个Gene
		//直接动态生成一个draw函数绘制Profile里面的子图形，提高效率
		//直接new一个对象
		var _profile = new Profile(profileJsonObject, geneCaseMap, ctx)
		this._profile = _profile
		this.profileId = this._profile.profileId
		this.childShapes.push(_profile)
		this.size = _profile.size
		//该图形的size是不会变的，scale会变
	}
	$rules() {

		this.boundryPoints[0].x = 0
		this.boundryPoints[0].y = 0
		this.boundryPoints[1].x = this.size.x
		this.boundryPoints[1].y = 0
		this.boundryPoints[2].x = this.size.x
		this.boundryPoints[2].y = this.size.y
		this.boundryPoints[3].x = 0
		this.boundryPoints[3].y = this.size.y
	}

	actualBoundryPath() {
		this.ctx.rect(0, 0, this._profile.size.x, this._profile.size.y)
	}

	hiddeBoundryPoints() {
		this.tempBoundryPoints = this.boundryPoints
		this.boundryPoints = []
	}

	showBoundryPoints() {
		this.boundryPoints = this.tempBoundryPoints
	}

	/**
	 * 确认编辑，按下回车后触发
	 **/
	confirm() {
		this.isEdit = false
		this.hiddeBoundryPoints()
	}
	/**
	 * 进行编辑，双击后触发
	 * TO DO 2023/2/22 修改Bug
	 **/
	edit() {
		this.isEdit = true
		this.showBoundryPoints()
	}

	onMousedown__($event) {
		$event.stopPropagation()
		var son = this
		var father = son.fatherShape
		var initX = son.ctxStatus.translateX
		var initY = son.ctxStatus.translateY
		var mousedownX = $event.clientX
		var mousedownY = $event.clientY

		var downPoint = father.toOffsetXY(mousedownX, mousedownY)
		this.useChildShapesMouseMove = false
		//默认绘制颜色和选中绘制颜色
		father.onMousemove__ = function($event) {
			var movePoint = father.toOffsetXY($event.clientX, $event.clientY)
			son.ctxStatus.translateX = initX + (movePoint.x - downPoint.x)
			son.ctxStatus.translateY = initY + (movePoint.y - downPoint.y)
			//son.notify(son.ctxStatus.translateX, son.ctxStatus.translateY)
		}
		father.onMouseup__ = function($event) {
			this.onMousemove__ = null
		}
	}
}
