<template>
    <div class="info-bar">
        <li class="btn" @click="showTips"><i class="iconfont icon-pointer"></i>操作说明</li>
        <Silder></Silder>
        <li @click.capture="openImg" class="btn">添加临摹底图
        </li>
        <input type="file" style="display: none;" ref="imgRef" @change="handleImgUpload" accept="image/*">

        <li for="eee">对称模式</li>
        <select id="eee" v-model="symMode">
            <option disabled value="">请选择</option>
            <option>Rectangle</option>
            <option>Triangle</option>
            <option>LeftRight</option>
            <option>UpDown</option>
            <option>None</option>
        </select>

        <li v-if="store2.painter">图案尺寸(影响对称轴)：
            <div style="display: inline;">
                <input maxlength="3" size="4" class="size" max="999" min="0" type="number"
                    v-model.number="store2.painter.contentSizeX" />
                <span class="sign">&nbsp;x&nbsp;</span>
                <input maxlength="3" size="4" class="size" max="999" min='0' type="number"
                    v-model.number="store2.painter.contentSizeY" />
            </div>
        </li>
        <li class="btn" @click="emitter.emit('save')">保存(缓存)</li>
        <li>
            <el-dropdown>
                <div>
                    <span>显示设置</span>
                    <span style="font-size: 0.2rem;">▽</span>
                </div>
                <template #dropdown v-if="store2.painter">
                    <el-dropdown-menu>
                        <el-dropdown-item @click="changeBackgroundMode(1)">正常背景</el-dropdown-item>
                        <el-dropdown-item @click="changeBackgroundMode(2)">不显示原点</el-dropdown-item>
                        <el-dropdown-item @click="changeBackgroundMode(3)">不显示坐标轴</el-dropdown-item>
                        <el-dropdown-item @click="changeBackgroundMode(4)">不显示背景</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

        </li>
    </div>
</template>

<script setup>
import { ElNotification } from 'element-plus'
import Silder from '~components/Silder.vue'
import {
    emitter
} from '~bus/emitter.js'
import {
    ref,
    computed,
    h,
} from 'vue'
import {
    useProfiledrawerStore
} from '~store/profiledrawer.js'

var store2 = useProfiledrawerStore()
var imgRef = ref(null)

var symMode = computed({
    get() {
        if (store2.painter) {
            switch (store2.painter.transformSym) {
                case store2.painter.TransformTriangle:
                    return 'Triangle'
                    break;
                case store2.painter.TransformNone:
                    return 'None'
                    break;
                case store2.painter.TransformRectangle:
                    return 'Rectangle'
                    break;
                case store2.painter.TransformLR:
                    return 'LeftRight'
                    break;
                case store2.painter.TransformUB:
                    return 'UpDown'
                    break;
                default:
                    return 'None'
                    break;
            }
        } else {
            return ''
        }
    },
    set(val) {
        if (store2.painter) {
            switch (val) {
                case 'Triangle':
                    store2.painter.transformSym = store2.painter.TransformTriangle
                    break;
                case 'None':
                    store2.painter.transformSym = store2.painter.TransformNone
                    break;
                case 'Rectangle':
                    store2.painter.transformSym = store2.painter.TransformRectangle
                    break;
                case 'LeftRight':
                    store2.painter.transformSym = store2.painter.TransformLR
                    break;
                case 'UpDown':
                    store2.painter.transformSym = store2.painter.TransformUB
                    break;
                default:
                    store2.painter.transformSym = store2.painter.TransformNone
                    break;
            }
        }
    }
})
function openImg() {
    imgRef.value.click()
    console.log(123)
}

const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
]

function validFileType(file) {
    return fileTypes.includes(file.type);
}

function handleImgUpload(event) {
    const file = event.target.files[0]
    if (file) {
        ElNotification({
            title: '生成图',
            dangerouslyUseHTMLString: true,
            message: `<img src="${URL.createObjectURL(file)}" width="300" height="300">`,
            duration: 0,
        })
    }
}

function changeBackgroundMode(mode) {
    if (store2.painter && store2.painter.backgroundMode) {
        store2.painter.backgroundMode = mode
        //将背景模式记录下来，切换图案时能够保持
        store2.backgroundMode = mode
    }
}

function showTips() {
    ElMessageBox({
        title: 'Tips:',
        message: h('ul', {
            style: 'padding-left:0.1rem'
        }, [
            h('li', {
                style: 'color: teal'
            }, "请将浏览器缩放比例调整至80%获得最佳体验"),
            h('li', {
                style: 'color: teal'
            }, "点击文件-新建项目"),
            h('li', {
                style: 'color: teal'
            }, "双击将确认态元素转为编辑态"),
            h('li', {
                style: 'color: teal'
            }, "回车将编辑态元素转为确认态"),
            h('li', {
                style: 'color: teal'
            }, "元素处于编辑态时按Del键删除元素"),
            h('li', {
                style: 'color: teal'
            }, "亦可以在右侧面板操作"),
        ]),
    })
}
</script>

<style scoped src='~assets/css/drawingBoard.css'></style>