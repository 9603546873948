<script>
	import {
		ref,
		reactive,
		getCurrentInstance,
		provide,
		h
	} from 'vue'
	import Pixel from './components/basic-component/Pixel.vue'
	import {useRoute} from 'vue-router'

	export default {
		data() {
			return {
				msg: '',
				msg2: '',
				logicalWidth: 0,
				logicalHeight: 0
			}
		},

		props: {
			mytitle: {
				type: String,
				default: ''
			}
		},

		provide() {
			return {
				hd1Height: this.hd1Height
			}
		},

		methods: {

		},

		mounted() {
			console.log('选项式', this)
			console.log(window)
			if (window.innerWidth < 1800) {
				ElMessageBox({
					title: '警告',
					message: h('p', null, [
						h('span', null, '检测到您当前的屏幕逻辑分辨率过低，请按住CTRL下滑滚轮调整缩放比 '),
						h('br', null, ''),
						h(Pixel)
					]),
					showCancelButton: false,
					// type:'warning',
					confirmButtonText: 'OK',
					// confirmButtonDisabled:true,
					cancelButtonText: 'Cancel',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm' && window.innerWidth > 1800) {
							// instance.confirmButtonLoading = true
							// instance.confirmButtonText = 'Loading...'
							console.log(instance)
							// setTimeout(() => {
							done()
							// 	setTimeout(() => {
							// 		instance.confirmButtonLoading = false
							// 	}, 300)
							// }, 3000)
						} else {
							// done()
							ElMessage({
								type: 'warning',
								message: `请调整分辨率`,
							})
							console.log(instance)
						}
					},
				})
			}
		},

		created() {
			// console.log('App created,Data中的数据可以操作了，虽然在这之前就可以操作')
			console.log('查看了全局对象:' + this.globalMsg)
			console.log('查看data中的数据:' + this.msg, this.msg2)
			console.log(this.$router)
		},

		setup(props) {
			// var hd1Height=ref('0px')
			console.log('App setup,查看了props:' + props.mytitle)
			// onMounted(()=>{
			//  console.log('组合式api的this:'+this)
			// 	hd1Height.value=document.getElementById('hd1').offsetHeight/parseInt(document.documentElement.style.fontSize)+'rem';
			// })
			//这是一个内部api,使用该方法访问到组件实例从而访问挂载到全局的属性，但是不建议使用该方法，应该使用全局注入
			console.log("setup:", getCurrentInstance())
			const route=useRoute()
			return {
				route
			}
		},

		components: {
		}
	}
</script>

<template>
	<RouterView name="main"></RouterView>
	<!-- <router-view name="login"></router-view>
	<header id="hd1" :title="globalMsg" v-if="route.path!='/Login'">
		<Menu></Menu>
	</header> -->
	<!-- <main :style="{marginTop:hd1Height}">
		<DrawingBoard v-if="route.path!='/Login'&&!route.path.startsWith('/Segment')"></DrawingBoard>
		<RouterView name="segmentAnything"></RouterView>
	</main> -->
</template>


<style scoped src="./assets/css/app.css">
</style>
