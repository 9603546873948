<template>
	<div :class="{'canvas-warpper':false,'canvas-warpper-center':true}">
		<div class="canvas-warpper-sub">
			<canvas id="cont" :height="cHeight" :width="cWidth" tabindex="0" :style="PointerStyle" w></canvas>
			<ol style="font-size: 24px;" class="case-list" v-if="geneJsonObj">
				<li v-for="item in geneJsonObj.profiles" @click="toAnotherCase(item)">
					<img :src="item.cover" />
					<button class="case-list-copy" @click.stop="copy(item)"><i
							class="iconfont icon-copy"></i>复制到画板</button>
				</li>
			</ol>
		</div>
		<Dialog :title='myDialog.loadGene.title' :visible="eventHappening!=''" @close="eventHappening=''"
			@cancel='handleCancel' @ok='handleOk' :width="1200" :height="900">
			<div v-if="eventHappening=='load'" class="dialog-content">
				<div v-for="(item,index) in myDialog.loadGene.genes" class="c-item"
					:class="{'c-selected':(item.gid==myDialog.loadGene.geneSelected.gid)}" @click="selectGene(item)">
					<span class="c-item-gid">{{item.gid}}</span>
					<img v-for="subItem in item.img" :src="subItem" class="c-item-img" />
				</div>
				<el-pagination small background layout="prev, pager, next" :total="myDialog.loadGene.total"
					v-model:current-page='myDialog.loadGene.page' class="my-pagination" />
				<div>
				</div>
			</div>
			<div v-else-if="eventHappening=='new'">
				<label>基因编号Gid:<input /></label>
			</div>
		</Dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onUpdated,
		watch,
		computed,
		onUnmounted,
	} from 'vue';
	import Dialog from '../components/Dialog.vue';
	import clip from '../tools/clip.js'
	import GenePainter from '../assets/js/PainterDemo-v1.3/Painter/GenePainter.js';
	import {
		onBeforeRouteLeave
	} from 'vue-router'
	import {
		uploadLimiting
	} from '../api/CvsGeneDrawerApi.js'
	import img1 from '../assets/img/city.png'
	import {
		useGenedrawerStore
	} from '../store/genedrawer.js'
	import {
setMapStoreSuffix,
		storeToRefs
	} from 'pinia'
	import {
		emitter
	} from '../bus/emitter.js'
	import GeneRaw from '../entity/GeneRaw.js'
	import Circle from "../assets/js/PainterDemo-v1.3/SingleShape/Circle.js";
	import Polygon from "../assets/js/PainterDemo-v1.3/SingleShape/Polygon.js";
	import Curve from "../assets/js/PainterDemo-v1.3/SingleShape/Curve.js";
	import CurveL from "../assets/js/PainterDemo-v1.3/SingleShape/CurveL.js";
	import LocalManger from '../store/LocalManger.js'
	import FileManager from '../store/FileManager.js'
	import {
		getLimitingByPage,
		getById
	} from '../api/PatternProfileApi.js'
	import {
		ElMessage
	} from 'element-plus'
	import CImg from '../assets/js/PainterDemo-v1.3/CompositionShape/CImg.js';
	var cHeight = ref(800)
	var cWidth = ref(1200)
	var props = defineProps(['drawType'])
	var geneJsonObj = ref(null)
	var store = useGenedrawerStore()
	var eventHappening = ref('')
	var PointerStyle = computed(() => {
		if (store.painter&&store.painter.preparedShape != 'none')
			return {
				cursor: 'crosshair'
			}
		else {
			return {
				cursor: 'default'
			}
		}
	})

	var ctx = null;
	var cont = null;

	/**
	 * 此方法初始化右侧的gene缩略图
	 * @param {GeneRaw} 一个gene对象 
	 **/
	function initOtherCase(geneRaw) {
		geneJsonObj.value = geneRaw
	}
	
	function initInfo(geneRaw){
		geneJsonObj.value.info=geneRaw.info
		if(geneRaw.info){
			store.info=geneRaw.info
			if(!store.info.useage){
				store.info.useage=[]
			}
			if(!store.info.date){
				store.info.date=1800
			}
			if(!store.info.nation){
				store.info.nation='未定义'
			}
			if(!store.info.sign){
				store.info.sign='未定义'
			}
		}	
	}

	/**
	 * 此方法初始化整个绘制工具,在更换了整个基因对象后触发
	 * @param {GeneRaw} 一个gene对象  
	 * @param {cid} 第几个图像
	 **/
	function initDrawer(geneRaw, cid) {
		cont = document.getElementById('cont')
		ctx = cont.getContext('2d')
		if (geneRaw.profiles.length > 0 && !cid) {
			store.createPainter(geneRaw.profiles[0], ctx)
		} else if (geneRaw.profiles.length > 0 && cid) {
			store.createPainter(geneRaw.profiles[cid - 1], ctx)
		} else {
			store.createPainter(null, ctx)
		}
		store.gid=geneRaw.gid
		//新建后清除句柄
		FileManager.clearFh()
		initOtherCase(geneRaw)
		initInfo(geneRaw)
		cont.onmousedown = function(event) {
			store.painter.$mousedown(event)
		}
		cont.onmousemove = function(event) {
			store.painter.$mousemove(event)
		}
		cont.onmouseup = function(event) {
			store.painter.$mouseup(event)
		}
		cont.onkeydown = function(event) {
			if (event.keyCode == 13) {
				store.painter.$keyEnter(event)
			}
			if (event.keyCode == 46) {
				store.painter.$keyDel(event)
			}
		}
		cont.ondblclick = function(event) {
			store.painter.$dbclick(event)
		}
	}


	function toAnotherCase(caseJsonObj) {
		var flag=window.confirm('当前case确定已经保存？')
		if(flag){
			store.createPainter(caseJsonObj, ctx)
		}	
	}

	function newCase() {
		save().then(() => {
			store.createPainter(null, ctx)
		}).catch(() => {
			alert('请新建项目')
		})
	}

	function save() {
		if (store.painter&&store.painter.save) {
			var startX = store.painter.ctxStatus.translateX
			var startY = store.painter.ctxStatus.translateY
			var sizeX = store.painter.contentSize.x * store.painter.ctxStatus.scaleX
			var sizeY = store.painter.contentSize.y * store.painter.ctxStatus.scaleY
			return clip(cont, startX, startY, sizeX, sizeY).then((img) => {
				geneJsonObj.value.addProfile(store.painter.save(), img.src)
				geneJsonObj.value.info=store.info
				geneJsonObj.value.gid=store.gid
				LocalManger.storeOneditGene(geneJsonObj.value)
				ElMessage('保存成功！')
			})
		} else {
			alert('请新建项目111')
			return new Promise((resolve, reject) => {
				reject()
			})
		}

	}
	/**将一个case的jsonObject复制到当前正在编辑的图像中**/
	function copy(cas) {
		var onEditJsonObj = store.painter.save()
		var cas = geneJsonObj.value.profiles.findIndex((item) => {
			return item.cid == cas.cid
		})
		if (cas != -1) {
			for (let i = 0; i < geneJsonObj.value.profiles[cas].parts.length; i++) {
				var item = geneJsonObj.value.profiles[cas].parts[i]
				onEditJsonObj.parts.push(item)
			}
			store.createPainter(onEditJsonObj, ctx)
		} else {
			alert("error")
		}

	}

	var myDialog = reactive({
		loadGene: {
			title: '加载图元基因',
			genes: [],
			page: 1,
			geneSelected: {
				gid: '',
				img: [],
			},
			confirmSelectGene() {
				var loadGene = this
				getById(this.geneSelected.gid).then((data) => {
					var obj = data.data
					var gid = obj.gid
					var info =obj.info
					var gene = new GeneRaw(gid, info, obj.profiles)
					obj.profiles.forEach((item, index) => {
						item.cover = loadGene.geneSelected.img[index]
					})
					initDrawer(gene)
				}).catch(console)
			},
			cancelSelectGene() {
				this.geneSelected.gid = ''
			}
		},
		newGene: {

		}
	})


	function selectGene(item) {
		var gid = item.gid
		myDialog.loadGene.geneSelected.gid = gid
		myDialog.loadGene.geneSelected.img = item.img
	}


	watch(() => myDialog.loadGene.page, (newVal, v2) => {
		var params = {
			size: 10,
			page: newVal
		};
		//TO DO 2023/2/25 发送分页请求
		getLimitingByPage(null, params).then((data) => {
			var obj = data.data
			myDialog.loadGene.total = obj.total
			myDialog.loadGene.genes = obj.records
		});
	}, {
		immediate: true
	});


	function load() {
		eventHappening.value = 'load'
	}


	function handleCancel() {
		eventHappening.value = ''
		myDialog.loadGene.cancelSelectGene()
	}

	function handleOk() {
		eventHappening.value = ''
		myDialog.loadGene.confirmSelectGene()
	}

	function upload() {
		let gid = geneJsonObj.value.gid;
		let info=geneJsonObj.value.info
		let profiles = [];
		let reqs = [];
		for (let i = 0; i < geneJsonObj.value.profiles.length; i++) {
			let cid = i + 1;
			let parts = geneJsonObj.value.profiles[i].parts;
			let size = geneJsonObj.value.profiles[i].size;
			profiles.push({
				"size": size,
				"parts": parts,
				"cid": cid
			})
			//处理图片
			let req = {
				"gId": gid,
				"cId": cid,
				"imgBase64": geneJsonObj.value.profiles[i].cover
			};
			reqs.push(req);
		}

		let res = {
			"gid": gid,
			"profiles": profiles,
			"info": info
		}
		let jsonStr = JSON.stringify(res);
		let data = {
			"reqs": reqs,
			"jsonStr": jsonStr
		}
		//调用上传接口
		uploadLimiting(data, {}).then(ok => {
			ElMessage('上传成功！')
		});
	}

	function newGene() {
		var item = new GeneRaw('未命名' + Date.now(), null)
		initDrawer(item)
	}

	async function loadLocal() {
		var obj = await FileManager.readLocalJson()
		if (jsonIsGene(obj)) {
			var gid = obj.gid
			var gene = new GeneRaw(gid, null, obj.profiles)
			initDrawer(gene)
			ElMessage('读取本地文件成功！')
		}
	}

	async function loadLocalImg() {
		var src = await FileManager.readLocalImg()
		var img = new Image()
		img.src = src
		img.onload = function() {
			var cimg = new CImg(img, 0, 0, this.width, this.height, ctx)
			store.painter.addImg(cimg)

		}
	}
	/**
	 * 将当前画板已保存的部分写入到本地的json文件，分为以下两种情况
	 * 1.已经打开了一个文件，有文件句柄，则直接保存到那个文件中
	 * 2.没有文件句柄，会另存为
	 * **/
	async function saveLocal() {
		var obj = LocalManger.getOneditGene()
		if (obj) {
			var res = await FileManager.writeLocalJson(obj)
			if (res) {
				ElMessage('保存本地文件成功！')
			} else {
				ElMessage('保存本地文件失败！')
			}
		} else {
			ElMessage('当前没有任何已保存的图案！')
		}
	}

	/**
	 * 在本地另存为
	 * **/
	async function saveAsLocal() {
		var obj = LocalManger.getOneditGene()
		if (obj) {
			var res = await FileManager.writeAsLocalJson(obj)
			if (res) {
				ElMessage('保存本地文件成功！')
			} else {
				ElMessage('保存本地文件失败！')
			}
		} else {
			ElMessage('当前没有任何已保存的图案！')
		}
	}


	/**
	 * 简单判断文件格式是不是一个Gene
	 * @param {Object} obj 要判断的对象
	 * @return {boolean}
	 * **/
	function jsonIsGene(obj) {
		if (obj.gid && (obj.profiles instanceof Array)) {
			return true
		} else {
			return false
		}
	}

	emitter.on('loadGene', load)
	emitter.on('newCase', newCase)
	emitter.on('save', save)
	emitter.on('uploadGene', upload)
	emitter.on('newGene', newGene)
	emitter.on('loadLocal', loadLocal)
	emitter.on('saveLocal', saveLocal)
	emitter.on('saveAsLocal', saveAsLocal)
	emitter.on('loadLocalImg', loadLocalImg)
	onMounted(() => {
		var item = LocalManger.getOneditGene()
		cont = document.getElementById('cont')
		ctx = cont.getContext('2d')
		if (item != null) {
			initDrawer(item)
		}
		window.onbeforeunload = function(event) {
			//event.preventDefault();
			return "onbeforeunload is work";
		}
	})

	onUnmounted(() => {
		store.destory()
		emitter.off('loadGene', load)
		emitter.off('newCase', newCase)
		emitter.off('save', save)
		emitter.off('uploadGene', upload)
		emitter.off('newGene', newGene)
		emitter.off('loadLocal', loadLocal)
		emitter.off('loadLocalImg', loadLocalImg)
		emitter.off('saveLocal', saveLocal)
		emitter.off('saveAsLocal', saveAsLocal)
	})

	onBeforeRouteLeave(() => {
		var flag = window.confirm('正在离开当前页面，离开后所有为保存的内容将会消失')
		return flag
	})
</script>

<style scoped lang="less" src='../assets/css/cvsGeneDrawer.less'>
</style>
