import SShape from "./SShape.js";

export default class SolidShape extends SShape{
	constructor(ctx){
		super(ctx)
	}
	
	$isPointInPath(x,y){
		this.ctx.lineWidth=this.lineWidth.outter+2
		var flag=this.ctx.isPointInPath(this.strokePath,x,y)
		this.ctx.lineWidth=1
		return flag
	}
}