<template>
	<div class='canvas-warpper-center'>
		<el-button :icon="ArrowLeft" text @click="preProfile" disabled>返回绘制</el-button>
		<div class="canvas-warpper-sub" v-loading="store.loading">
			<canvas id="profileCont" :height="cHeight" :width="cWidth" tabindex="0" :style="PointerStyle"></canvas>
		</div>
		<el-button text @click="toFill">去上色<el-icon class="el-icon--right">
				<ArrowRight />
			</el-icon></el-button>
		<!-- 对话框 -->
		<Dialog :title='myDialog.loadProfile.title' :visible="eventHappening != ''" @close="eventHappening = ''"
			@cancel='handleCancel' @ok='handleOk' :width="1200" :height="900">
			<div v-if="eventHappening == 'load'" class="dialog-content">
				<div v-for="(item, index) in myDialog.loadProfile.profiles" class="c-item"
					:class="{ 'c-selected': (item.profileId == myDialog.loadProfile.profileSelected.profileId) }"
					@click="selectProfile(item, index)" :key="index">
					<span class="c-item-gid">{{ item.profileId }}</span>
					<img :src="item.litimgPath" class="c-item-img" />
				</div>
				<el-pagination small background layout="prev, pager, next" :total="myDialog.loadProfile.total"
					v-model:current-page='myDialog.loadProfile.page' class="my-pagination" />
				<div>
				</div>
			</div>
			<div v-else-if="eventHappening == 'new'">
				<label>基因编号Gid:<input /></label>
			</div>
		</Dialog>
	</div>
</template>

<script setup>
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import {
	ref,
	reactive,
	onMounted,
	onUpdated,
	watch,
	computed,
	onUnmounted,
	nextTick,
} from 'vue';
import clip from '../tools/clip.js'
import Dialog from '../components/Dialog.vue'
import {
	useProfiledrawerStore
} from '../store/profiledrawer.js'
import { useGlobalParmasStore } from "../store/globalParamsStore";
import {
	storeToRefs
} from 'pinia'
import {
	emitter
} from '../bus/emitter.js'
import {
	onBeforeRouteLeave
} from 'vue-router'
import CGeneCase from '../assets/js/PainterDemo-v1.3/CompositionShape/CGeneCase';
import {
	getPImgByPage,
	getPorfileById
} from '../api/PatternProfileApi.js'
import {
	h
} from 'vue'
import {
	ElMessage
} from 'element-plus'
import FileManager from '../store/FileManager.js'
import CImg from '../assets/js/PainterDemo-v1.3/CompositionShape/CImg'
import { useRouter } from 'vue-router';
var router=useRouter()
var cHeight = ref(800)
var cWidth = ref(1200)
var store = useProfiledrawerStore()
var paramsStore = useGlobalParmasStore()
var eventHappening = ref('')
var PointerStyle = computed(() => {
	if (store.preparedShape)
		return {
			cursor: 'crosshair'
		}
	else {
		return {
			cursor: 'default'
		}
	}
})
var ctx = null
var cont = null

var myDialog = reactive({
	loadProfile: {
		title: '加载单个图案',
		profiles: [],
		page: 1,
		pageSize: 10,
		index: null,//当前是第几张
		total: 0,
		profileSelected: {
			profileId: '',
			litimgPath: '',
		},
		confirmSelectProfile() {
			var loadProfile = this
			var profile = loadProfile.profileSelected.jsonContext
			initDrawer(profile)
		},
		cancelSelectProfile() {
			this.profileSelected.profileId = ''
		}
	},
	newProfile: {

	}
})

/**
 * 跳转到上色页面
 */
function toFill(){
	store.painter.backgroundMode=4
	var img=store.painter.ctx.getImageData(0,0,store.painter.contentSizeX,store.painter.contentSizeY)
	paramsStore.fill.img=img
	router.push('/DrawBoard/fill')
}



/**
 * 切换到下一张
 */
function nextProfile() {
	var pageSize = myDialog.loadProfile.pageSize
	var total = myDialog.loadProfile.total
	if (myDialog.loadProfile.page * pageSize + index + 1 <= total) {
		if (myDialog.loadProfile.index + 1 <= myDialog.loadProfile.pageSize - 1) {
			myDialog.loadProfile.index++
			initDrawer(myDialog.loadProfile.profiles[myDialog.loadProfile.index])
		} else {
			myDialog.loadProfile.page++
			myDialog.loadProfile.index=0
			initDrawer(myDialog.loadProfile.profiles[myDialog.loadProfile.index])
		}
	}
}

function preProfile() {
	if (myDialog.loadProfile.index - 1 >= 0) {
		myDialog.loadProfile.index--
	} else {
		myDialog.loadProfile.page++
	}
}

function selectProfile(item, index) {
	myDialog.loadProfile.profileSelected = item
	myDialog.loadProfile.index = index
}


watch(() => myDialog.loadProfile.page, (newVal, v2) => {
	console.log(newVal, v2);
	var params = {
		size: myDialog.pageSize,
		page: newVal
	};

	getPImgByPage(params.page, params.size).then((data) => {
		var obj = data.data
		myDialog.loadProfile.total = obj.total
		myDialog.loadProfile.profiles = obj.records
	});
}, {
	immediate: true
});

watch(() => store.message, (val) => {
	if (val) {
		ElMessage(val)
	}
})


function initDrawer(profile) {
	cont = document.getElementById('profileCont')
	store.createPainter(cont, profile) //这是异步的，在这里设置加载动画
}

function load() {
	eventHappening.value = 'load'
}

function select() {
	eventHappening.value = ''
}

function handleCancel() {
	eventHappening.value = ''
	myDialog.loadProfile.cancelSelectProfile()
}

function handleOk() {
	eventHappening.value = ''
	myDialog.loadProfile.confirmSelectProfile()
}


function upload() {
	//获取canvas截图
	//上传截图与profile
	store.upload()
}

function prepared(data) {
	var {
		gid,
		caseJsonObj
	} = data
	var cgenecas = new CGeneCase(gid, caseJsonObj, ctx)
	if (store.painter) {
		store.painter.preparedToEdit(cgenecas)
		store.preparedShape = cgenecas
	} else {
		alert('请新建项目')
	}
}

function newPatternProfile() {
	store.createPainter(cont, null)
}

async function loadLocalImg() {
	if (store.painter) {
		var src = await FileManager.readLocalImg()
		var img = new Image()
		img.src = src
		img.onload = function () {
			console.log(this.width)
			console.log(this.height)
			var cimg = new CImg(img, 0, 0, this.width, this.height, ctx)
			store.painter.addImg(cimg)
		}
	}
}

async function loadLocal() {
	cont = document.getElementById('profileCont')
	store.loadLocal(cont)
}

async function saveLocal() {
	store.saveLocal()
}

async function saveAsLocal() {
	store.saveAsLocal()
}

emitter.on('prepared', prepared)
emitter.on('loadProfile', load)
emitter.on('save', store.save)
emitter.on('uploadProfile', upload)
emitter.on('newProfile', newPatternProfile)
emitter.on('loadLocalImg', loadLocalImg)
emitter.on('loadLocal', loadLocal)
emitter.on('saveLocal', saveLocal)
emitter.on('saveAsLocal', saveAsLocal)
emitter.on('downloadFile', store.downloadFile)


onMounted(() => {
	cont = document.getElementById('profileCont')
	ctx = cont.getContext('2d')
	store.loadOneditProfile(cont)
	window.onbeforeunload = function (event) {
		return "onbeforeunload is work";
	}
})


onUnmounted(() => {
	store.destory()
	emitter.off('prepared', prepared)
	emitter.off('loadProfile', load)
	emitter.off('save', store.save)
	emitter.off('uploadProfile', upload)
	emitter.off('newProfile', newPatternProfile)
	emitter.off('loadLocalImg', loadLocalImg)
	emitter.off('downloadFile', store.downloadFile)
	emitter.off('loadLocal', loadLocal)
	emitter.off('saveLocal', saveLocal)
	emitter.off('saveAsLocal', saveAsLocal)
})

onBeforeRouteLeave(() => {
	var flag = window.confirm('正在离开当前页面，离开后所有为保存的内容将会消失')
	return flag
})
</script>

<style scoped src='../assets/css/cvsPatternProfileDrawer.less'></style>
