import CurveL from "../SingleShape/CurveL.js"
import CShape from "../YeahDrawer/CShape.js"
import ControlPoint from '../YeahDrawer/ControlPoint.js'
import PainterElement from "./PainterElement.js"

/*
可操作的拉格朗日曲线
*/
export default class CCurveL extends PainterElement{
	/**
	@param {PointArray} pointArr 插值点数组
	@param {Number} rad 顺时针旋转的弧度
	@param {ctx} ctx canvas上下文对象
	 **/
	constructor(pointArr,rad,ctx){
		super(...arguments)
	}
	
	$getControlPoints(pointArr,rad,ctx){
		pointArr.forEach((item)=>{
			this.controlPoints.push(new ControlPoint(item.x,item.y,6))
		})
	}
	
	$getChildShape(pointArr,rad,ctx){
		this._curveL=new CurveL(pointArr,rad,ctx)
		this.controlPoints.forEach((item,index)=>{
			item.bind(this._curveL.pointArr[index],'x','y')
		})
		this.childShapes.push(this._curveL)
	}
	
	addPoints(pointArr){
		pointArr.forEach((item)=>{
			let temp=new ControlPoint(item.x,item.y)
			this.controlPoints.push(temp)
			this._curveL.pointArr.push(item)
			temp.bind(item,'x','y')
		})
	}
}