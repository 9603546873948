import ControlPoint from "../YeahDrawer/ControlPoint.js"
import CShape from "../YeahDrawer/CShape.js"
import CtxStatus from "../YeahDrawer/CtxStatus.js"
import Polygon from '../SingleShape/Polygon.js'
import PainterElement from "./PainterElement.js"

export default class CPolygon extends PainterElement{
	constructor(pointArr,ctx){
		super(...arguments)
		this.ctxStatus=new CtxStatus(1,1,0,0,0)
	}

	$getControlPoints(pointArr,ctx){
		pointArr.forEach((item)=>{
			this.controlPoints.push(new ControlPoint(item.x,item.y,6))
		})
	}
	
	$getChildShape(pointArr,ctx){
		this._polygon=new Polygon(pointArr,ctx)
		this.controlPoints.map((item,index)=>{
			item.bind(this._polygon.pointArr[index],'x','y')
		})
		this.childShapes.push(this._polygon)
	}
	
	/**
	 * 添加结点，尾部
	 * @param {Array} 元素为{x:num,y:num}的数组 
	 **/
	addPoints(pointArr){
		var father=this
		pointArr.forEach((item)=>{
			let temp=new ControlPoint(item.x,item.y,6,this.ctx)
			temp.fatherShape=this
			this.controlPoints.push(temp)
			this._polygon.pointArr.push(item)
			temp.bind(item,'x','y')
			father.$rules()
			//还要添加鼠标事件
			temp.onMousedown__ = function($event) {
				var son = this
				var defaultMoveFn=father.onMousemove__
				var defaultUpFn=father.onMouseup__
				this.fatherShape.fatherShape.onMousemove__ = function($event) {
					var point = son.fatherShape.toOffsetXY($event.clientX, $event.clientY)
					son.x = point.x
					son.y = point.y
				}
				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = defaultMoveFn
					this.onMouseup__=defaultUpFn
				}
				this.onMouseup__ = function($event) {
					this.fatherShape.fatherShape.onMousemove__ = defaultMoveFn
					this.fatherShape.fatherShape.onMouseup__=defaultUpFn
				}
				$event.stopPropagation()
			}
		})
	}
	
	//添加节点，头部
	addPointsFront(pointArr){
		var father=this
		pointArr.forEach((item)=>{
			let temp=new ControlPoint(item.x,item.y,6,this.ctx)
			temp.fatherShape=this
			this.controlPoints.unshift(temp)
			this._polygon.pointArr.unshift(item)
			temp.bind(item,'x','y')
			father.$rules()
			temp.onMousedown__ = function($event) {
				var son = this
				var defaultMoveFn=father.onMousemove__
				var defaultUpFn=father.onMouseup__
				this.fatherShape.fatherShape.onMousemove__ = function($event) {
					var point = son.fatherShape.toOffsetXY($event.clientX, $event.clientY)
					son.x = point.x
					son.y = point.y
				}
				this.fatherShape.fatherShape.onMouseup__ = function($event) {
					this.onMousemove__ = defaultMoveFn
					this.onMouseup__=defaultUpFn
				}
				this.onMouseup__ = function($event) {
					this.fatherShape.fatherShape.onMousemove__ = defaultMoveFn
					this.fatherShape.fatherShape.onMouseup__=defaultUpFn
				}
				$event.stopPropagation()
			}
		})
	}
}