<template>
	<header id="hd1">
		<Menu></Menu>
	</header>
	<main :style="{ marginTop: height }">
		<section class="drawingboard">
			<div class="tool-bar-warpper" :style="{width:toolbarWidth,transition:'width 2s'}">
				<div class="tool-bar">
					<CollapseButton size="100" color="#f0f2f3" @click="hidden"></CollapseButton>
					<router-view name="toolbar"></router-view>
				</div>
			</div>

			<router-view name='infobar'></router-view>
			<router-view name="drawingboard"></router-view>
		</section>
	</main>
</template>

<script setup>
import { ElNotification } from 'element-plus'
import Cvs from '../components/Cvs.vue'
import Silder from '../components/Silder.vue'
import Menu from './Menu.vue'
import CollapseButton from '../components/basic-component/CollapseButton.vue'
import {
	useRoute
} from 'vue-router'
import {
	emitter
} from '../bus/emitter.js'
import {
	ref,
	reactive,
	watch,
	computed,
	h,
	inject,
} from 'vue'
import {
	useProfiledrawerStore
} from '../store/profiledrawer.js'
import {
	useGenedrawerStore
} from '../store/genedrawer.js'
import {
	usePatterndrawerStore
} from '../store/patterndrawer.js'
import { judgeEnv } from '~tools/judgeEnv'
// import FileManager from '../store/FileManager.js'
//import {ElMessageBox} from 'element-plus'
import { onMounted,provide } from 'vue'

var route = useRoute()
var store1 = useGenedrawerStore()
var store2 = useProfiledrawerStore()
var store3 = usePatterndrawerStore()
var height = ref('0px')
var imgRef = ref(null)
var hiddenToolbar=ref(false)
var toolbarWidth=ref("3.3rem")


function hidden(){
	toolbarWidth.value=toolbarWidth.value=="0"?"3.3rem":"0"
}


provide('hd1Height',height)

onMounted(() => {
	height.value = document.getElementById('hd1').offsetHeight / parseInt(document.documentElement.style
		.fontSize) + 'rem';
})


var symMode = computed({
	get() {
		if (store2.painter) {
			switch (store2.painter.transformSym) {
				case store2.painter.TransformTriangle:
					return 'Triangle'
					break;
				case store2.painter.TransformNone:
					return 'None'
					break;
				case store2.painter.TransformRectangle:
					return 'Rectangle'
					break;
				case store2.painter.TransformLR:
					return 'LeftRight'
					break;
				case store2.painter.TransformUB:
					return 'UpDown'
					break;
				default:
					return 'None'
					break;
			}
		} else {
			return ''
		}
	},
	set(val) {
		if (store2.painter) {
			switch (val) {
				case 'Triangle':
					store2.painter.transformSym = store2.painter.TransformTriangle
					break;
				case 'None':
					store2.painter.transformSym = store2.painter.TransformNone
					break;
				case 'Rectangle':
					store2.painter.transformSym = store2.painter.TransformRectangle
					break;
				case 'LeftRight':
					store2.painter.transformSym = store2.painter.TransformLR
					break;
				case 'UpDown':
					store2.painter.transformSym = store2.painter.TransformUB
					break;
				default:
					store2.painter.transformSym = store2.painter.TransformNone
					break;
			}
		}
	}
})
function openImg() {
	// if(judgeEnv)
	// 	emitter.emit('loadLocalImg')
	//console.log(imgRef)
	imgRef.value.click()
	console.log(123)
}

const fileTypes = [
	"image/apng",
	"image/bmp",
	"image/gif",
	"image/jpeg",
	"image/pjpeg",
	"image/png",
	"image/svg+xml",
	"image/tiff",
	"image/webp",
	"image/x-icon",
]

function validFileType(file) {
	return fileTypes.includes(file.type);
}

function handleImgUpload(event) {
	const file = event.target.files[0]
	if (file) {
		ElNotification({
			title: '生成图',
			dangerouslyUseHTMLString: true,
			message: `<img src="${URL.createObjectURL(file)}" width="300" height="300">`,
			duration: 0,
		})
	}
}



function changeBackgroundMode(mode) {
	if (route.path == '/' || route.path == '/DrawGene') {
		if (store1.painter && store1.painter.backgroundMode) {
			store1.painter.backgroundMode = mode
		}
	} else if (route.path == '/DrawPatternProfile') {
		if (store2.painter && store2.painter.backgroundMode) {
			store2.painter.backgroundMode = mode
			//将背景模式记录下来，切换图案时能够保持
			store2.backgroundMode = mode
		}
	} else if (route.path == '/DrawPattern') {
		if (store3.painter && store3.painter.backgroundMode) {
			store3.painter.backgroundMode = mode
		}
	}

}

function useOrCancelBoundry() {
	store1.painter.switchUseBoundry()
}

function showTips() {
	ElMessageBox({
		title: 'Tips:',
		message: h('ul', {
			style: 'padding-left:0.1rem'
		}, [
			h('li', {
				style: 'color: teal'
			}, "请将浏览器缩放比例调整至80%获得最佳体验"),
			h('li', {
				style: 'color: teal'
			}, "点击文件-新建项目"),
			h('li', {
				style: 'color: teal'
			}, "双击将确认态元素转为编辑态"),
			h('li', {
				style: 'color: teal'
			}, "回车将编辑态元素转为确认态"),
			h('li', {
				style: 'color: teal'
			}, "元素处于编辑态时按Del键删除元素"),
			h('li', {
				style: 'color: teal'
			}, "亦可以在右侧面板操作"),
		]),
	})
	// ElMessageBox.alert('This is a message', 'Title', {
	//    // if you want to disable its autofocus
	//    // autofocus: false,
	//    confirmButtonText: 'OK',
	//  })
}
</script>

<style scoped="scoped" src='../assets/css/drawingBoard.css'></style>
