import link from "../tools/api.js"

//此方法获取一页gene的缩略图
export function getLimitingByPage(data, params) {
	return link("/litimg/getLimitingByPage", "get", data, params).then((ok) => {
		console.log(ok)
		return ok.data
	}).catch(e=>{console.log(e)})
}
//此方法按id获取一个gene
export function getById(gid) {
	return link(`/gene/getGeneByGid`, "get", {}, {
		gid
	}).then((ok) => {
		console.log(ok)
		return ok.data
	}).catch(e=>{console.log(e)})
}

//此方法获取一页profile的缩略图
export function getPImgByPage(page = 1, size = 10) {
	return link('/profile/page', "get",null, {
		page,
		size
	}).then((ok) => {
		console.log(ok)
		return ok.data
	}).catch(e=>{console.log(e)})
}
//此方法根据id获取profile详情
export function getPorfileById(pid) {
	return link(`/profile/${pid}`, "get", null, null).then((ok) => {
		console.log(ok)
		return ok.data
	}).catch(e=>{console.log(e)})
}

//此方法一次性获取多个gene的json
export function getGenes(geneList) {
	return link('/gene/getGeneByGidArray', "post",{
		gids: geneList
	}, null).then(((ok) => {
		return ok.data
	})).catch(e=>{console.log(e)})
}

//此方法一次性获取多个profile的json
// export function getProfiles(profileIds){
// 	return link('','post',{profileIds},null).then((ok)=>{
// 		return ok.data
// 	})
// }


//此方法上传一个profile和缩略图
export function uploadProfile(base64Img, jsonStr, profileId = '未命名' + Date.now()) {
	return link('/profile/saveLimiting','post', {
		base64Img,
		jsonStr,
		profileId
	},null).then((ok) => {
		console.log(ok)
		return ok.data
	}).catch(e=>{console.log(e)})
}

//此方法获取一个pattern的json
export function getPatternById(pid){
	return link(`/pattern/${pid}`, "get", null, null).then((ok) => {
		return ok.data
	}).catch(e=>{console.log(e)})
}

//此方法获取一页pattern和缩略图
export function getPatternImgByPage(page=1,size=10){
	return link("/pattern/getPatternLimitingByPage", "get", null, {page,size}).then((ok) => {
		return ok.data
	}).catch(e=>{console.log(e)})
}

//此方法上穿一个pattern和缩略图
export function uploadPattern(base64Img,pattern){
	return link('/pattern/saveLimiting','post',{base64Img,pattern},null).then((ok)=>{
		return ok.data
	}).catch(e=>{console.log(e)})
}
