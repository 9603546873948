import Point from "./Point.js";

export default class BoundryPoint extends Point{
	constructor(x,y,size,ctx){
		super(x,y,size,ctx)
	}
	
	draw() {
		this.ctx.save()
		this.$setStrokePath()
		this.ctx.fillStyle = '#F0F2F3'
		this.ctx.strokeStyle='black'
		this.ctx.lineWidth=1
		this.ctx.fill(this.strokePath)
		this.ctx.stroke(this.strokePath)
		this.ctx.restore()
	}
}