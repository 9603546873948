import $Math from "../YeahDrawer/$Math.js";
import ControlPoint from "../YeahDrawer/ControlPoint.js";
import CShape from "../YeahDrawer/CShape.js";
import Curve from "../SingleShape/Curve.js";
import CtxStatus from '../YeahDrawer/CtxStatus.js';
import PainterElement from "./PainterElement.js";

export default class CCurve extends PainterElement {
	constructor(startX, startY, p1X, p1Y, p2X, p2Y, p3X, p3Y, ctx) {
		super(...arguments)
	}

	$getControlPoints(startX, startY, p1X, p1Y, p2X, p2Y, p3X, p3Y, ctx) {
		this._start = new ControlPoint(startX, startY, 6)
		this.controlPoints.push(this._start)
		this._p1 = new ControlPoint(p1X, p1Y, 6)
		this.controlPoints.push(this._p1)
		this._p2 = new ControlPoint(p2X, p2Y, 6)
		this.controlPoints.push(this._p2)
		this._p3 = new ControlPoint(p3X, p3Y, 6)
		this.controlPoints.push(this._p3)
	}
	$getChildShape(startX, startY, p1X, p1Y, p2X, p2Y, p3X, p3Y, ctx) {
		this._curve = new Curve(startX, startY, p1X, p1Y, p2X, p2Y, p3X, p3Y, ctx)
		this._start.bind(this._curve, 'startX', 'startY')
		this._p1.bind(this._curve, 'p1X', 'p1Y')
		this._p2.bind(this._curve, 'p2X', 'p2Y')
		this._p3.bind(this._curve, 'p3X', 'p3Y')
		this.childShapes.push(this._curve)
	}

	// $afterChildShapesMousedown = function($event) {
	// 	console.log('CCurve\'s childshape mousedown')
	// }

	// $afterControlPointMousemove = function($event) {

	// }

	// //控制点x坐标变化后的回调
	// $afterCenterPointChangeX(oldVal,newVal){

	// }
	// //控制点y坐标变化后的回调
	// $afterCenterPointChangeY(oldVal,newVal){

	// }
}
