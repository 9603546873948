import link from "../tools/api.js"


export function login(data){
    return link("/user/Login","post",data,null).then((res)=>{
		if(res.data.code==200){
			var token=res.headers.get("Authorization")
			var token2=res.headers["Authorization"]
			sessionStorage.setItem("Authorization",token)
		}
		
        return res.data;
    }).catch((err)=>{
		console.log(err)
	})
}