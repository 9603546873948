<template>
    <div class='canvas-warpper-center'>
        <el-button :icon="ArrowLeft" text @click="back">{{ paramsStore.test }}</el-button>
        <div class="canvas-warpper-sub">
            <canvas id="fillCont" height="500" width="800" tabindex="0" @click="operation"></canvas>
        </div>
    </div>
</template>

<script setup>
import { ArrowLeft } from '@element-plus/icons-vue'
import { ref, onMounted,reactive } from 'vue'
import { useGlobalParmasStore } from '~store/globalParamsStore.js'
import { useFillDrawerStore } from '~store/filldrawer.js'

var paramsStore = useGlobalParmasStore()
var fillStore = useFillDrawerStore()
var brush = 1 // 笔刷 1-油漆桶 2-画笔
onMounted(() => {
    var ctx = document.getElementById('fillCont')
    var img = paramsStore.fill.img //这样子赋值会失去响应式
    fillStore.createPainter(ctx,img)
})
function back(){

}

function operation(event){
    if(brush==1)
    {
        fill(event)
    }
    else {
        draw(event)
    }
}

function fill(event){
    fillStore.fill(event.offsetX,event.offsetY)
}

function draw(event){

}
</script>

<style scoped src='../assets/css/cvsPatternProfileDrawer.less'></style>