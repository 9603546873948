import axios from "axios"
// import {ElMessageBox } from 'element-plus'
import {
	useRouter
} from "vue-router";
// 创建axios 赋值给常量service

//配置全局默认值
axios.defaults.headers.common['Authorization'] = null;
axios.defaults.baseURL =
	import.meta.env.VITE_BASE_URL;

//const router = useRouter()
import router from '../router/index.js'

const service = axios.create({
	// baseURL: 'http://124.221.110.63:8888/lyl_api',
	timeout: 3000
});
//已有一个弹出框
var showingMsgBox = false

// 添加请求拦截器（Interceptors）
service.interceptors.request.use(function(config) {
	// 发送请求之前做写什么
	//console.log(import.meta.env.VITE_BASE_URL)
	//console.log(axios.defaults)
	//console.log(config.headers)
	if (sessionStorage.getItem('Authorization')) {
		config.headers.Authorization = sessionStorage.getItem('Authorization')
	}

	return config;
}, function(error) {
	// 请求错误的时候做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function(response) {
	//没有授权则跳转至登录
	if (response.data.code == "401" || response.data.code == "403" || response.data.code == "608") {
		var msg = ""
		if (response.data.code == "401" || response.data.code == "403")
			msg = "尚未登录"
		if (response.data.code == "608")
			msg = "登录过期"	
		if (!showingMsgBox) {
			showingMsgBox = true
			ElMessageBox.alert(msg, '提示', {
				confirmButtonText: '知道了',
				callback: (action) => {
					showingMsgBox=false
					router.push("/Login")
				},
			})
		}

	}
	// 对响应数据做点什么
	return response;
}, function(error) {
	// 超出 2xx 范围的状态码都会触发该函数
	// 对响应错误做点什么
	if (error.response) {
		console.log(error.response.data);
		console.log(error.response.status);
		console.log(error.response.headers);
	} else {
		console.log('无响应')
	}
	return Promise.reject(error);
});
export default service
