<template>
    <h1>标签分类</h1>
    <div class="label-container" v-if="labels">
        <li class="label" v-for="i in labels" :key="i" @click="selectLabel(i)" :class="{'label-active':i==labelSelected}">
            <span>{{ i }}</span>
        </li>
    </div>

    <el-button text type="primary" size="large" style="font-size: 16px;" :icon="Download" @click="downloadDataset">
        数据集下载
    </el-button>
</template>
<style scoped>
h1 {
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    text-align: left;
    padding-left: 0.2rem;
}

.label-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.label {
    border-right: 2px solid #e0e8e9;
    font-size: 14px;
    list-style-type: none;
    width: 1rem;
    margin-bottom: 10px;
    cursor: pointer;
    height: 48px;
}

.label:hover{
    border-bottom: 1px solid #409EFF;
}

.label-active{
    border-bottom: 1px solid #409EFF;
}

</style>
<script setup>
import { Download } from '@element-plus/icons-vue';
import { getDatasetByLabel, getLabels } from '~api/DatasetApi.js'
import { emitter } from '~bus/emitter.js'
import { watch, ref } from "vue";
var labels = ref(null)
var labelSelected = ref(null)
const resourceUrl = 'http://124.221.110.63:81/resnet-classify.rar'
getLabels().then(res => {
    labels.value=res.data.data.sort()
    labelSelected.value=labels.value[0]
    emitter.emit('labelSelected',labelSelected.value)
})

//点击标签切换图片
function selectLabel(label){
    emitter.emit('labelSelected',label)
    console.log(label)
    labelSelected.value=label
}

//点击按钮下载数据集
function downloadDataset() {
	var a = document.createElement('a')
	a.setAttribute('href', resourceUrl)
	a.setAttribute('download', '')
	a.click()
}
</script>