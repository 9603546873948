import {
	defineStore
} from 'pinia';
import {
	getPatternImgByPage,
	getPatternById,
	getGenes,
} from '../api/PatternProfileApi.js';
import LocalManager from './LocalManger.js';
import clip from '../tools/clip.js'
import PatternPainter from '../assets/js/PainterDemo-v1.3/Painter/PatternPainter.js'
import CProfile from '../assets/js/PainterDemo-v1.3/CompositionShape/CProfile.js';

export const usePatterndrawerStore = defineStore('Patterndrawer', {
	state: () => ({
		painter: null,
		preparedShape: null,
		isSaved: true,
		message: '',
		loading: false,
	}),
	actions: {
		loadOneditPattern(cont) {
			var item = LocalManager.getOneditPattern()
			if (item) {
				this.createPainter(cont, item)
			}
		},
		async createPainter(cont, pattern) {
			this.loading = true
			this.destory()
			var ctx = cont.getContext('2d', {willReadFrequently: true})
			if (pattern) {
				let geneSet = new Set()
				var geneCasMap = new Map()
				var that = this
				for (let i = 0; i < pattern.profiles.length; i++) {
					let profile = pattern.profiles[i]
					for (let j = 0; j < profile.genes.length; j++) {
						geneSet.add(profile.genes[j].gid)
					}
				}
				let genes=Array.from(geneSet)
				if (genes.length > 0) {
						await getGenes(genes).then((data) => {
						//TODO 将数据放入map中，然后初始化
						//将data中所有gene的case全部放入map中
						var genesDetail = data.data
						genesDetail.forEach((item) => {
							var gid = item.gid
							item.profiles.forEach((sub) => {
								var cid = sub.cid
								geneCasMap.set(`${gid}-${parseInt(cid)}`, sub)
							})
						})
						this.painter = new PatternPainter(ctx)
						this.painter.ctxStatus.translateX = 300
						this.painter.ctxStatus.translateY = 300
						this.painter.ctxStatus.scaleX = 1
						this.painter.ctxStatus.scaleY = 1

						this.painter.buildPattern(pattern, geneCasMap, ctx)
						//this.save()
						this.painter.draw()
						cont.onmousedown = function(event) {
							that.painter.$mousedown(event)
						}
						cont.onmousemove = function(event) {
							that.painter.$mousemove(event)
						}
						cont.onmouseup = function(event) {
							that.painter.$mouseup(event)
						}
						cont.onkeydown = function(event) {
							if (event.keyCode == 13) {
								that.painter.$keyEnter(event)
							}
							if (event.keyCode == 46) {
								that.painter.$keyDel(event)
							}
						}
						cont.ondblclick = function(event) {
							that.painter.$dbclick(event)
						}
						//promise正常
						return true
					})
					this.loading = await this.painter.loading
				} else {
					this.createPainter(cont, null)
				}
			} else {
				this.painter = new PatternPainter(ctx)
				this.painter.loading.then((flag) => {
					this.loading = flag
				})
				this.painter.ctxStatus.translateX = 300
				this.painter.ctxStatus.translateY = 300
				this.painter.ctxStatus.scaleX = 1
				this.painter.ctxStatus.scaleY = 1
				this.painter.buildPattern(null, null, ctx)
				//this.save()
				this.painter.draw()
				var that = this
				cont.onmousedown = function(event) {

					that.painter.$mousedown(event)
				}
				cont.onmousemove = function(event) {

					that.painter.$mousemove(event)
				}
				cont.onmouseup = function(event) {
					that.painter.$mouseup(event)
				}
				cont.onkeydown = function(event) {
					if (event.keyCode == 13) {
						that.painter.$keyEnter(event)
					}
					if (event.keyCode == 46) {
						that.painter.$keyDel(event)
					}
				}
				cont.ondblclick = function(event) {
					that.painter.$dbclick(event)
				}
			}
		},

		async prepareToEdit(JsonObj) {
			if (this.painter) {
				this.loading = true
				var profile = JsonObj
				var gids = []
				profile.genes.forEach((item) => {
					gids.push(item.gid)
				})
				var genes = (await getGenes(gids)).data
				var geneCasMap = new Map()
				genes.forEach((item) => {
					//todo 2023.3.28遍历list 把它存为map
					var gid = item.gid
					item.profiles.forEach((sub) => {
						var cid = sub.cid
						geneCasMap.set(`${gid}-${parseInt(cid)}`, sub)
					})

				})
				var cprofile = new CProfile(profile, geneCasMap, this.painter.ctx)
				this.painter.preparedToEdit(cprofile)
				this.loading = false
			} else {
				this.message = '请新建画布' + Date.now()
			}
		},

		destory() {
			if (this.painter) {
				this.painter.destory()
				this.painter = null
			}
		},
		save() {
			if (this.painter) {
				var item = this.painter.save()
				if (item) {
					LocalManager.storeOneditPattern(item)
					this.isSaved = true
					this.message = '保存成功' + Date.now()
				} else {
					this.message = '保存失败' + Date.now()
				}
			} else {
				this.message = '当前无画板' + Date.now()
			}

		},
		upload() {
			if (this.painter) {
				if (this.isSaved) {
					var uploadJson = LocalManager.getOneditPattern()
					//绘制时用不到的缩略图不存在loacl了
					var startX = this.painter.ctxStatus.translateX
					var startY = this.painter.ctxStatus.translateY
					var sizeX = this.painter.contentSizeX * this.painter.ctxStatus.scaleX
					var sizeY = this.painter.contentSizeY * this.painter.ctxStatus.scaleY
					clip(this.painter.ctx.canvas, startX, startY, sizeX, sizeY)
						.then((img) => {
							uploadPattern(img.src, uploadJson, uploadJson.PatternId).then(() => {
								this.message = '上传成功' + Date.now()
							}).catch(() => {
								this.message = '上传失败' + Date.now()
							})
						})
				} else {
					var flag = window.confirm('当前状态没有保存，是否上传？')
					if (flag) {
						var uploadJson = LocalManager.getOneditPattern()
						var startX = this.painter.ctxStatus.translateX
						var startY = this.painter.ctxStatus.translateY
						var sizeX = this.painter.contentSizeX * this.painter.ctxStatus.scaleX
						var sizeY = this.painter.contentSizeY * this.painter.ctxStatus.scaleY
						clip(this.painter.ctx.canvas, startX, startY, sizeX, sizeY).then((img) => {
							uploadPattern(img.src, uploadJson, uploadJson.PatternId).then(() => {
								this.message = '上传成功' + Date.now()
							}).catch(() => {
								this.message = '上传失败' + Date.now()
							})
						})
					}
				}
			} else {
				this.message = '没有正在编辑的图形，上传无效' + Date.now()
			}
		}
	}
})
