import LineShape from "../YeahDrawer/LineShape.js";

/**
 * 二次贝塞尔曲线
 **/
export default class Curve3 extends LineShape {
	constructor(startX, startY, p1X, p1Y, p2X, p2Y, ctx) {
		super(ctx)
		this.startX = startX
		this.startY = startY
		this.p1X = p1X
		this.p1Y = p1Y
		this.p2X = p2X
		this.p2Y = p2Y
	}
	
	$setStrokePath(){
		this.strokePath=new Path2D()
		this.strokePath.moveTo(this.startX, this.startY)
		this.strokePath.quadraticCurveTo(this.p1X, this.p1Y, this.p2X, this.p2Y)
	}
	
	// draw() {
	// 	this.ctx.beginPath()
	// 	this.ctx.moveTo(this.startX, this.startY)
	// 	this.ctx.quadraticCurveTo(this.p1X, this.p1Y, this.p2X, this.p2Y)
	// 	this.ctx.stroke()
	// }

	// $isPointInPath(x, y) {
	// 	// this.ctx.beginPath()
	// 	// this.ctx.moveTo(this.startX, this.startY)
	// 	// this.ctx.quadraticCurveTo(this.p1X, this.p1Y, this.p2X, this.p2Y)
	// 	return this.ctx.isPointInStroke(this.strokePath,x, y)
	// }
	

}
